import { useState, createContext, useEffect } from "react";
import axiosAdmin from "../../../api/apiAdmin";

export const AdminPrimaryContext = createContext(null);

export const AdminContext = ({ children }) => {
  //current user data
  const [user, setUser] = useState({});
  //token for remembering user in browser
  const [token, _setToken] = useState(localStorage.getItem("MGL_TOKEN_ADM"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //countries
  const [countries, setCountries] = useState([]);
  //abroad offices
  const [abOffices, setAbOffices] = useState([]);
  //geo offices
  const [geBranches, setGeBranches] = useState([]);
  //address
  const [foreignAdresses, setForeignAdresses] = useState([]);
  //flight container
  const [flights, setFlights] = useState([]);

  //handler function for token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("MGL_TOKEN_ADM", token);
    } else {
      localStorage.removeItem("MGL_TOKEN_ADM");
    }
  };

  //get user data if user is authorized
  useEffect(() => {
    axiosAdmin.get("/admin").then(({ data }) => {
      setUser(data);
      setIsAuthorized(true);
    });
  }, []);

  //get foreign adreses gllobally
  useEffect(() => {
    axiosAdmin.get("/abroadOffice").then(({ data }) => {
      setForeignAdresses(data);
      setAbOffices(data);
    });
  }, [isAuthorized]);

  //countries
  useEffect(() => {
    axiosAdmin.get("/countries").then(({ data }) => {
      setCountries(data);
    });
    axiosAdmin.get(`/shipments`).then(({ data }) => {
      setFlights(
        data.data.map((item) => {
          return item.code;
        })
      );
    });
    axiosAdmin
      .get(`/offices`)
      .then(({ data }) => {
        const tmp = data.map((item) => {
          return {
            name: item.city,
            value: item.id,
          };
        });
        setGeBranches(tmp);
      })
      .catch(() => {});
  }, [user]);

  return (
    <AdminPrimaryContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthorized,
        setIsAuthorized,
        countries,
        foreignAdresses,
        abOffices,
        flights,
        setFlights,
        geBranches,
      }}
    >
      {children}
    </AdminPrimaryContext.Provider>
  );
};
