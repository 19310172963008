import { useState } from "react";
// import PanelHead from "../../components/PanelHead/PanelHead";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axiosClient from "../../api/api";
import Line from "../../components/InputComponents/Line";

const TrendyolCodes = () => {
  //translations
  const { t } = useTranslation();
  //data
  const [data, setData] = useState([]);

  //category list
  const categoryList = [
    {
      name: "Trendyol",
      value: 0,
    },
    {
      name: t("else"),
      value: 1,
    },
  ];
  //faqs based on cateory
  const [faqCategory, setFaqCategory] = useState(0);

  //get data from api
  useEffect(() => {
    if (faqCategory === 0) {
      axiosClient
        .get("/msg/Trendyol")
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {});
    } else if (faqCategory === 1) {
      axiosClient
        .get("/msg")
        .then((res) => {
          setData(res.data);
        })
        .catch(() => {});
    }
  }, [faqCategory]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("codes")}</h2>
      </div>

      <div className="mt-5">
        <div
          className="flex items-center gap-2"
        >
          {categoryList.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setFaqCategory(item.value);
                }}
                className={`cursor-pointer w-[176px] rounded-lg p-[11px] sm:w-full 
                ${
                  faqCategory === item.value
                    ? "bg-teal_600"
                    : "bg-white shadow-bs1"
                }`}
              >
                <p
                  className={`text-center sm:w-full sm:text-sm text-sm ${
                    faqCategory === item.value
                      ? "text-white"
                      : "font-normal text-black_900_b7 "
                  }`}
                >
                  {item.name}
                </p>
              </button>
            );
          })}
        </div>

        {data.map((item) => {
          return (
            <div
              className="bg-white flex flex-col gap-2 shadow-bs1
          p-[24px] sm:px-5 sm:py-2 rounded-lg"
            >
              <h2 className="text-base relative z-20">{item.sender}</h2>
              <div className={`bg-gray-200 h-[1px] w-full `}></div>
              <p className="w-full not-italic text-black_900_b7 text-left sm:text-xs text-sm">
                {item.txt}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TrendyolCodes;
