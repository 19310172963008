import { useState, useRef } from "react";
import { BsCheck } from "react-icons/bs";

const FileInput = ({ name, inputName, setIsFileSelected }) => {
  //working with single file input
  const [fileState, setFileState] = useState(null);
  //file input element
  const inputElement = useRef(null);
  //file name
  const [fileName, setFileName] = useState("");

  const handleChange = (event) => {
    setFileState(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
    setIsFileSelected(event.target.files[0].name);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  return (
    <>
      <div
        onClick={handleOpenInput}
        className="w-full flex flex-1 flex-row items-center justify-between self-start"
      >
        <div className="flex items-center gap-[12px] w-full">
          <div
            className="bg-teal_600_b2 border-2 border-solid border-teal_600 flex items-center justify-center px-[7px] w-full
                            rounded-[12px] h-12 cursor-pointer"
          >
            <p className="text-center text-white w-auto text-sm">{name}</p>
          </div>
        </div>
        {fileName && <BsCheck className="w-8 h-8 text-green-500" />}
        <input
          name={inputName}
          ref={inputElement}
          className="hidden"
          type="file"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default FileInput;
