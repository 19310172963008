import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import AbOfficeCard from "./AbOfficeCard";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import { FileInput } from "../../inputComponents/inputcomp";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { SelectInputValueId } from "../../inputComponents/inputcomp";

const AbOfficesLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //ab office data from api
    const [abOfficeData, setAbOfficeData] = useState([]);
    //error
    const [error, setError] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new ab offices  
    const [newAbOffice, setNewAbOffice] = useState({})
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);
    //data from context
    const { countries } = useContext(AdminPrimaryContext);
    //country options
    const [countryOptions, setCountryOptions] = useState([]);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle new admin
    const handleNewAbOfficeData = (value, inputName) => {
        setNewAbOffice(prev => ({ ...prev, [inputName]: value }))
    }

    //add new users handlers 
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //delete ab office
    const handleDeleteAbOffice = async (id) => {
        try {
            const res = await axiosAdmin.delete(`/abroadOffice/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ოფისი წარმატებით წაიშალა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //ab office data
    useEffect(() => {
        try {
            axiosAdmin.get(`/abroadOffice`)
                .then(({ data }) => {
                    setAbOfficeData(data);
                });
        } catch (error) {
            setError(error);
        }
    }, [difResponses]);

    //add country data
    function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        formData.append("country_id", newAbOffice.country_id)
        formData.append("type", newAbOffice.type)
        formData.append("currency", newAbOffice.currency)

        axiosAdmin.post(`/abroadOffice`, formData)
            .then((res) => {
                setDifResponses(res);
                successToast("ოფისი წარმატებით დაემატა!");
                handleMakeEmpty(true);
            })
            .catch((err) => {
                setError(err);
                errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
            });
    }

    //country
    useEffect(() => {
        const tmp = countries.map((item) => {
            return { name: item.name, value: item.id }
        })
        setCountryOptions(tmp);
    }, [countries]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-end ">
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0">
                        ახალი საწყობის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
                        <div className="sm:px-0">
                            <form
                                onSubmit={(e) => { handleSubmit(e) }}
                                encType="multipart/form-data"
                            >
                                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                    <div className="flex items-center justify-between flex-wrap gap-5">
                                        <div className="flex items-center gap-5 sm:gap-3 ">
                                            <div>
                                                <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი საწყობის დამატება</h2>
                                            </div>
                                        </div>
                                        <div className="flex gap-2 sm:w-full">
                                            <button
                                                type="submit"
                                                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                                შენახვა
                                            </button>
                                            <button
                                                onClick={() => { handleAddNewToggle(false) }}
                                                className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                                X
                                            </button>
                                        </div>
                                    </div>
                                    <FileInput
                                        name={"ფოტოს ატვირთვა"}
                                        inputName={"img"}
                                    />
                                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                                        <div className="w-6/12 sm:w-full md:w-full">
                                            <TextInput
                                                name={"საწყობი"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"country"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"მისამართი1"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"adress1"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"ტელეფონი"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"phone"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"ქალაქი"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"town"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <SelectInputValueId
                                                name={"ქვეყანა"}
                                                inputName={"country_id"}
                                                inputSetter={handleNewAbOfficeData}
                                                options={countryOptions}
                                            />
                                            <TextInput
                                                name={"რეგიონი / მხარე"}
                                                inputName={"street"}
                                                inputSetter={handleNewAbOfficeData}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"მინიმალური წონა"}
                                                inputName={"min_weight"}
                                                inputSetter={handleNewAbOfficeData}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                        </div>
                                        <div className="w-6/12 sm:w-full md:w-full">
                                            <SelectInputValueId
                                                name={"ტიპი"}
                                                inputName={"type"}
                                                inputSetter={handleNewAbOfficeData}
                                                options={[
                                                    {
                                                        name: "სატვირთო",
                                                        value: 1
                                                    },
                                                    {
                                                        name: "თვითმფრინავი",
                                                        value: 2
                                                    },
                                                    {
                                                        name: "გემი",
                                                        value: 3
                                                    }
                                                ]}
                                            />
                                            <TextInput
                                                name={"Adress 1 English"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"adress2"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"ZIP"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"zip_code"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"შტატი / პროვინცია"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"state"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                            <TextInput
                                                name={"1 კგ. ფასი"}
                                                inputSetter={handleNewAbOfficeData}
                                                inputName={"price"}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                                type="number"
                                            />
                                            <SelectInputValueId
                                                name={"ვალუტა"}
                                                inputName={"currency"}
                                                inputSetter={handleNewAbOfficeData}
                                                options={[
                                                    {
                                                        name: "GEL",
                                                        value: "GEL"
                                                    },
                                                    {
                                                        name: "USD",
                                                        value: "USD"
                                                    },
                                                    {
                                                        name: "EUR",
                                                        value: "EUR"
                                                    },
                                                ]}
                                            />
                                            <TextInput
                                                name={"ტრანსპორტირების ფასი"}
                                                inputName={"delivery_price"}
                                                inputSetter={handleNewAbOfficeData}
                                                makeEmpty={makeEmpty}
                                                handleMakeEmpty={handleMakeEmpty}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="grid grid-cols-2 sm:grid-cols-1  p-10 pt-0 sm:p-5 gap-2 sm:pt-0">
                    {abOfficeData.map((item) => {
                        return (
                            < AbOfficeCard
                                item={item}
                                key={item.id}
                                id={item.id}
                                handleDeleteAbOffice={handleDeleteAbOffice}
                            />
                        )
                    })}
                </div>
            </div >
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default AbOfficesLayout;
