import React from 'react'
import { useState } from 'react'
import axiosAdmin from '../../../../api/apiAdmin'
import { successToast, errorToast } from '../../toastAlerts/ToastAlerts'
import { ToastContainer } from 'react-toastify'
import { TextInput } from "../../inputComponents/inputcomp";
import { TextAreaInput } from '../../inputComponents/inputcomp'
import { useParams } from 'react-router-dom'
import FileInput from '../../../../components/InputComponents/FileInput'

const SendMessagePanel = () => {
    const { userId } = useParams();

    //new message
    const [newMessage, setNewMessage] = useState({
        type: 2,
        user_id: userId
    })

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const form = event.target;
            const formData = new FormData(form);

            formData.append("type", 2);
            formData.append("user_id", userId);
            const res = await axiosAdmin.post(`/message`, formData);

            if (res) {
                successToast("შეტყობინება გაიგზავნა!")
            }

        } catch (err) {
            errorToast("დაფიქსირდა შეცდომა!")
        }
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
        min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <form onSubmit={handleSubmit} encType="multipart/form-data" className="w-full">
                <div className='w-full flex flex-col gap-2 bg-white rounded-lg p-5 dark:bg-slate-700'>
                    <TextInput
                        name={"საგანი"}
                        placeholder={"საგანი"}
                        inputName={"subject"}
                    />
                    <TextAreaInput
                        name={"მესიჯი"}
                        inputName={"message"}
                    />
                    <div className="grid grid-cols-2 sm:grid-cols-1">
                        <FileInput
                            name={"ფაილის მიმაგრება"}
                            inputName={"img"}
                        />
                    </div>
                    <div className='w-full'>
                        <button
                            type='submit'
                            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 sm:mx-0">
                            გაგზავნა
                        </button>
                    </div>
                </div>
            </form>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default SendMessagePanel