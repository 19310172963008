import AdminRouter from "./AdminRouter";
import { AdminContext } from "../adminContext/AdminContext";

const AdminRouterLayout = () => {
    return (
        <AdminContext>
            <AdminRouter />
        </AdminContext>
    )
}

export default AdminRouterLayout;