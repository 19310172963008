import { useTranslation } from "react-i18next";
import { FaPlane } from "react-icons/fa";

const FlightsCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div className="border-2 border-[#F0F0F0] p-5 rounded-[20px] flex items-center gap-5 md:p-3">
      <div
        className="w-12 h-12 shrink-0 bg-red-500 text-white p-2 rounded-full
            flex items-center justify-center"
      >
        <FaPlane className="text-xl" />
      </div>
      <div>
        <div className="font-bold text-sm">
          <p>
            {t("estimated_arrival_date")}: {item.estemated_date}
          </p>
        </div>
        <div className="font-bold text-sm mt-2">
          <p>
            {t("flight")}: {item.code}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlightsCard;
