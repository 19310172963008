import { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import FlightsItem from "./FlightsItem";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  TextInput,
  SelectInputValueId,
  DateInput,
} from "../../inputComponents/inputcomp";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { BsSearch } from "react-icons/bs";
import PaginatedItems from "../../Pagination/Pagination";

const Flights = () => {
  //add new user variable
  const [addNewToggler, setAddNewToggler] = useState(false);
  //get flights data from api
  const [flightsData, setFlightsData] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //new flights data
  const [newflights, setNewFlights] = useState({});
  //clear fields after submit
  const [makeEmpty, setMakeEmpty] = useState(false);
  //data from context
  const { abOffices } = useContext(AdminPrimaryContext);
  //office options
  const [officeOptions, setOfficeOptions] = useState([]);
  //search value
  const [searchValue, setSearchValue] = useState("");
  const [total, setTotal] = useState(0);
  const [filterShipments, setFilterShipments] = useState({
    page: 0,
    perPage: 30,
  });

  //handle search value change
  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  //handle filed clear after submission
  const handleMakeEmpty = (value) => {
    setMakeEmpty(value);
  };

  //handle new flights
  const handleNewFlightsData = (value, inputName) => {
    setNewFlights((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new users handlers
  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  //add new flights
  const handleAddNewFlightsToDb = async () => {
    try {
      const res = await axiosAdmin.post(`/shipments`, newflights);
      setDifResponses(res);
      if (res) {
        successToast("მონაცემი წარმატებით დაემატა!");
        handleMakeEmpty(true);
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //delete flights
  const handleDeleteFlights = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/shipments/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("მონაცემი წარმატებით წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  // searching user
  const handleSearch = async () => {
    setFilterShipments((prev) => ({ ...prev, page: 0 }));
    setTotal(0);
    try {
      const res = await axiosAdmin.get(`/shipments/search?code=${searchValue}`);
      if (res) {
        setFlightsData([res.data]);
      }
    } catch (error) {}
  };

  //flights data from api
  useEffect(() => {
    if (searchValue === "") {
      try {
        axiosAdmin
          .get(
            `/shipments?page=${filterShipments.page +1}&per_page=${filterShipments.perPage}`
          )
          .then(({ data }) => {
            setFlightsData(data.data);
            setTotal(data.total);
          });
      } catch (error) {}
    }
  }, [difResponses, searchValue, filterShipments]);

  //country
  useEffect(() => {
    const tmp = abOffices.map((item) => {
      return { name: item.country, value: item.id };
    });
    setOfficeOptions(tmp);
  }, [abOffices]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="w-full flex flex-col gap-5 pb-10 sm:pb-5">
        <div className="w-full flex items-center justify-between md:flex-col md:gap-2">
          <div className="w-9/12 flex justify-between gap-1 md:w-full">
            <div
              className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full bg-white"
            >
              <input
                type="search"
                placeholder="თრექინგ ნომერი"
                className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
                onChange={(e) => {
                  handleSearchValue(e);
                }}
                value={searchValue}
              />
            </div>
            <div
              onClick={handleSearch}
              className="w-20 rounded-lg bg-violet-400
                 flex items-center justify-center p-1 py-3 cursor-pointer"
            >
              <BsSearch className="text-white font-bold" />
            </div>
          </div>
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="w-3/12 md:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0"
          >
            ახალი რეისის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900 ">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი რეისის დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewFlightsToDb}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                  <div className="w-6/12 sm:w-full md:w-full">
                    <SelectInputValueId
                      name={"ოფისი"}
                      inputName={"abroad_office_id"}
                      inputSetter={handleNewFlightsData}
                      options={officeOptions}
                    />
                    <SelectInputValueId
                      name={"სტატუსი"}
                      inputName={"status"}
                      inputSetter={handleNewFlightsData}
                      options={[
                        {
                          name: "არ დაწყებულა",
                          value: 1,
                        },
                        {
                          name: "გზაშია",
                          value: 2,
                        },
                        {
                          name: "ჩამოვიდა",
                          value: 3,
                        },
                      ]}
                    />
                  </div>
                  <div className="w-6/12 sm:w-full md:w-full">
                    <TextInput
                      name={"აღწერა"}
                      inputName={"description"}
                      inputSetter={handleNewFlightsData}
                      makeEmpty={makeEmpty}
                      handleMakeEmpty={handleMakeEmpty}
                    />
                    <DateInput
                      name={"ჩამოსვლის მიახლოებითი დრო"}
                      inputSetter={handleNewFlightsData}
                      inputName={"estemated_date"}
                      makeEmpty={makeEmpty}
                      handleMakeEmpty={handleMakeEmpty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <PaginatedItems
        pageNumberSetter={(page) => {
          setFilterShipments((prev) => ({ ...prev, page }));
        }}
        dataLength={total}
      >
        <div className="grid grid-cols-3 md:grid-cols-2  sm:grid-cols-1 sm:pt-5 gap-2 my-5">
          {flightsData.map((item) => {
            return (
              <FlightsItem
                key={item.id}
                item={item}
                id={item.id}
                handleDeleteFlights={handleDeleteFlights}
              />
            );
          })}
        </div>
      </PaginatedItems>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Flights;
