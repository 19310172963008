import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import { PrimaryContext } from "../../../../components/MainContext/MainContext";

//images
import logo from "../../../img/phoneLogo.png";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";

const InnerUsPage = () => {
  //get current user id
  const { siteUsersId } = useParams();
  //new Users data
  const [usersData, setUsersData] = useState({});
  //error
  const [error, setError] = useState(null);
  //data from context
  const { setIsAuthorized, setUser, setToken } = useContext(PrimaryContext);
  //admin context data
  const { user } = useContext(AdminPrimaryContext);

  //handle new Users
  const handleNewUsersData = (value, inputName) => {
    setUsersData((prev) => ({ ...prev, [inputName]: value }));
  };

  // access user
  const handleAccessUser = async (id) => {
    try {
      const res = await axiosAdmin.get(`/user/${id}`);
      if (res) {
        setUser(res.data.user);
        setToken(res.data.token);
        window.open("https://mglparcel.com", "_blank");
      }
    } catch (error) {}
  };

  //add new Users
  const handleEditUsers = async () => {
    try {
      const res = await axiosAdmin.put(`/users/${siteUsersId}`, {
        ...usersData,
        balance: parseFloat(usersData.balance),
      });
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
      }
    } catch (error) {
      setError(error);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //Users data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/users/${siteUsersId}`).then(({ data }) => {
        setUsersData(data);
      });
    } catch (error) {
      setError(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-[100px] h-10">
                <img src={logo} className="w-full h-full" />
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  მომხმარებლის ინფორმაციის რედაქტირება
                </h2>
              </div>
            </div>
            <div className="flex gap-1 items-center">
              <button
                onClick={handleEditUsers}
                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
              >
                შენახვა
              </button>
              <div
                onClick={() => {
                  handleAccessUser(usersData.id);
                }}
                className="w-32 rounded-lg bg-green-400 cursor-pointer
                 flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-[49%]"
              >
                <p className="text-white text-sm ">შესვლა</p>
              </div>
            </div>
          </div>
          <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
            {usersData.company_id === null ? (
              <>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"სახელი"}
                    inputName={"fname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.fname}
                  />
                  <TextInput
                    name={"გვარი"}
                    inputName={"lname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.lname}
                  />
                  <TextInput
                    name={"ელ.ფოსტა"}
                    inputName={"email"}
                    inputSetter={handleNewUsersData}
                    value={usersData.email}
                  />
                  <TextInput
                    name={"პირდი ნომერი"}
                    inputName={"person_id"}
                    inputSetter={handleNewUsersData}
                    value={usersData.person_id}
                  />
                </div>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"სახელი ლათინურად"}
                    inputName={"fname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.fname_eng}
                  />
                  <TextInput
                    name={"გვარი ლათინურად"}
                    inputName={"lname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.lname_eng}
                  />
                  <TextInput
                    name={"ტელეფონი"}
                    inputName={"phone"}
                    inputSetter={handleNewUsersData}
                    value={usersData.phone}
                  />
                  <TextInput
                    name={"პაროლი"}
                    inputName={"password"}
                    inputSetter={handleNewUsersData}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"სახელი"}
                    inputName={"cname"}
                    inputSetter={handleNewUsersData}
                    value={usersData.cname}
                  />
                  <TextInput
                    name={"სახელი ლათინურად"}
                    inputName={"cname_eng"}
                    inputSetter={handleNewUsersData}
                    value={usersData.cname_eng}
                  />
                  <TextInput
                    name={"ელ.ფოსტა"}
                    inputName={"email"}
                    inputSetter={handleNewUsersData}
                    value={usersData.email}
                  />
                </div>
                <div className="w-6/12 sm:w-full md:w-full">
                  <TextInput
                    name={"საიდენთიფიკაციო კოდი"}
                    inputName={"person_id"}
                    inputSetter={handleNewUsersData}
                    value={usersData.company_id}
                  />
                  <TextInput
                    name={"ტელეფონი"}
                    inputName={"phone"}
                    inputSetter={handleNewUsersData}
                    value={usersData.phone}
                  />
                  <TextInput
                    name={"პაროლი"}
                    inputName={"password"}
                    inputSetter={handleNewUsersData}
                  />
                </div>
              </>
            )}
          </div>
          <div className="w-full grid grid-cols-2 md:grid-cols-1 gap-5 md:gap-0">
            {user.role === "100" && (
              <TextInput
                name={"ბალანსი"}
                inputName={"balance"}
                inputSetter={handleNewUsersData}
                value={usersData.balance}
              />
            )}
            <TextInput
              name={"ფასდაკლების პროცენტი"}
              inputName={"discount"}
              inputSetter={handleNewUsersData}
              value={usersData.discount}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default InnerUsPage;
