import { useState } from "react";
import { Link } from "react-router-dom";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const BlogCardAdmin = ({ item, handleDelete }) => {
  const [showContinue, setShowContinue] = useState(false);
  return (
    <div
      className="bg-white rounded-xl p-5 w-full relative flex
    items-center justify-between gap-3 dark:bg-slate-700"
    >
      <div className="w-full flex gap-2 items-center">
        <div
          onClick={() => {
            setShowContinue(true);
          }}
          className="w-10 h-10 rounded-lg bg-gray-100"
        >
          <img src={`${process.env.REACT_APP_API_URL}/storage/${item?.img}`} alt="#" className="w-full h-full object-cover" />
        </div>
        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200 ">
          {item?.titleGe}
        </p>
      </div>
      <div className="flex gap-2 items-center">
        <Link
          to={`../admin-blogs/${item?.id}`}
          className="w-10 h-10 rounded-lg bg-violet-400 cursor-pointer p-2"
        >
          <PencilSquare className="text-white w-full h-full" />
        </Link>
        <div
          onClick={() => {
            setShowContinue(true);
          }}
          className="w-10 h-10 rounded-lg bg-red-400 cursor-pointer p-2"
        >
          <BsTrash className="text-white w-full h-full" />
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item?.id}
      />
    </div>
  );
};

export default BlogCardAdmin;
