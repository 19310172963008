import Line from "../../components/InputComponents/Line";
//icons
import { BsExclamationCircle } from "react-icons/bs";
import { BsAirplane, BsTruck } from "react-icons/bs";
import { FaShip } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const PanelFlightsCard = ({ data }) => {
  const { t } = useTranslation();
  const [updateDate, setUpdateDate] = useState(() => {
    const date = new Date(data.updated_at).toLocaleDateString();
    return date;
  });
  return (
    <div
      className="w-full bg-white flex items-center shadow-bs1 p-4
        rounded-3xl md:rounded-lg md:gap-2 md:items-start"
    >
      <div className="w-full flex gap-2 md:flex-col">
        <div className="w-full flex flex-col gap-2">
          <p className="font-semibold text-xs text-gray-500">{t("status")}</p>
          <div className="flex gap-2 items-center">
            {data.type === 1 && <BsTruck className="w-6 h-6 text-teal_600" />}
            {data.type === 2 && (
              <BsAirplane className="w-6 h-6 text-teal_600" />
            )}
            {data.type === 3 && <FaShip className="w-6 h-6 text-teal_600" />}
            <p
              className={`text-sm md:text-xs ${
                data.status === 3 ? "text-teal_600" : "text-gray-500"
              }`}
            >
              {data.status === 1 && t("not_started")}
              {data.status === 2 && t("sent")}
              {data.status === 3 && t("arrived")}
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <p className="text-xs text-gray-500 font-semibold">
            {t("flight_number")}
          </p>
          <p className="font-semibold text-sm md:text-xs">{data.code}</p>
        </div>
      </div>

      <div className="w-full flex gap-2 md:flex-col">
        <div className="w-full flex flex-col gap-2">
          <p className="text-xs text-gray-500 font-semibold">
            {t("estimated_arrival_date")}
          </p>
          <p className="font-semibold text-sm md:text-xs">
            {data.estemated_date}
          </p>
        </div>
        <div className="w-full flex justify-between gap-2">
          <div className="w-full flex flex-col gap-2">
            <p className="text-xs text-gray-500 font-semibold">
              {t("arrival_date")}
            </p>
            <p className="font-semibold text-sm md:text-xs">
              {data.arrival_date === null ? "..." : data.arrival_date}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div className="group relative">
              <BsExclamationCircle className="text-gray_900 w-6 h-6 hover:text-teal-600 cursor-pointer" />
              <div className="hidden group-hover:block absolute bottom-[100%] right-0 w-[300px] z-[2]">
                <div
                  className="flex flex-col w-full bg-white border border-gray-200
                            rounded-[30px]"
                >
                  <div className="flex flex-col gap-1 p-4 border-b border-gray-200">
                    <p className="font-semibold text-xs">{data.code}</p>
                    <p className="font-semibold text-xs">
                      {t("last_updated")}:
                    </p>
                    <p className="font-semibold text-xs">{updateDate}</p>
                  </div>
                  <div className="flex flex-col gap-2 p-4">
                    {data.sent_at && data.sent_at !== "" && (
                      <div div className="flex flex-col gap-1">
                        <p className="font-semibold text-xs">{t("sent_at")}</p>
                        <p className="text-teal_600 text-xs font-semibold">
                          {data.sent_at}
                        </p>
                      </div>
                    )}
                    {data.terminals_at && data.terminals_at !== "" && (
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-xs">
                          {t("terminals_at")}
                        </p>
                        <p className="text-teal_600 text-xs font-semibold">
                          {data.terminals_at}
                        </p>
                      </div>
                    )}
                    {data.customs_at && data.customs_at !== "" && (
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-xs">
                          {t("customs_at")}
                        </p>
                        <p className="text-teal_600 text-xs font-semibold">
                          {data.customs_at}
                        </p>
                      </div>
                    )}
                    {data.redistribution_at &&
                      data.redistribution_at !== "" && (
                        <div className="flex flex-col gap-1">
                          <p className="font-semibold text-xs">
                            {t("redistribution_at")}
                          </p>
                          <p className="text-teal_600 text-xs font-semibold">
                            {data.redistribution_at}
                          </p>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelFlightsCard;
