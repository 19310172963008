import { useState } from "react";
import ContinueComponent from '../../toastAlerts/ContinueComponent'
import axiosClient from "../../../../api/api";
//icons
import { BsEye, BsFillEnvelopeFill, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";

const PanelMessageCard = ({ item, handleDelete, type, setDifResponses }) => {
    //show coninue
    const [showContinue, setShowContinue] = useState(false);
    //create date object from item property
    const dateObject = new Date(item.created_at)
    //exact time (YYYY-MM-DD)    
    const date = dateObject.toISOString().split('T')[0];
    //exact time (HH:MM:SS)
    const time = dateObject.toTimeString().split(' ')[0];

    //handle seen
    const handleSeen = (id) => {
        axiosClient.get(`/message/${id}`)
            .then(({ data }) => {
                setDifResponses(data)
            })
    }

    return (
        <div className="bg-white rounded-xl p-5 w-full flex flex-col items-center justify-between gap-3 dark:bg-slate-700 relative">
            <div className="w-full flex flex-col gap-2">
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">პირი:</p>
                    {type === 1 ?
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {item.user !== null && (item.user.cname !== null ? item.user.cname : `${item.user.lname_eng} ${item.user.fname_eng}`)} ID:{item.user_id}
                        </p> :
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{item.admin !== null && item.admin.name} ID:{item.admin_id}</p>
                    }
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">საგანი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{item.subject}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">მესიჯი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{item.message}</p>
                </div>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]">თარიღი:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{date} | {time}</p>
                </div>
                {item.img &&
                    <div className="flex gap-1 flex-wrap">
                        <Link to={`${process.env.REACT_APP_API_URL}/storage/${item.img}`} target="_blank">
                            <p className="font-bold text-[14px] text-teal_600 underline cursor-pointer">
                                მიმაგრებული ფაილი
                            </p>
                        </Link>
                    </div>
                }
            </div>
            <div className="absolute top-5 right-5 flex gap-2">
                {(item.seen !== 1 && type === 1) &&
                    <div
                        onClick={() => { handleSeen(item.id) }}
                        className="w-10 h-10 rounded-lg bg-green-400 flex items-center justify-center cursor-pointer">
                        <BsEye className="text-white" />
                    </div>
                }
                {type === 1 &&
                    <Link to={`../messages/${item.user_id}`}>
                        <div
                            className="w-10 h-10 rounded-lg bg-violet-400 flex items-center justify-center cursor-pointer">
                            <BsFillEnvelopeFill className="text-white" />
                        </div>
                    </Link>
                }
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-10 h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer">
                    <BsTrash className="text-white" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDelete}
                setShowContinue={setShowContinue}
                id={item.id}
            />
        </div>
    )
}

export default PanelMessageCard