import { BsAirplane, BsFillFileEarmarkPdfFill, BsTruck, } from "react-icons/bs";
import { FaShip } from "react-icons/fa"
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import Button from "../../components/InputComponents/Button";
import DeclarationInv from "../../components/Invoice/DeclarationInv";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../../components/MainContext/MainContext";

const PayFlightsCard = ({ data, update }) => {
    const { t } = useTranslation();
    const [toggleInvoice, setToggleInvoice] = useState(false)
    const { setUpdateUser } = useContext(PrimaryContext)

    const handlePay = () => {
        axiosClient.post(`/declarationPay/${data.id}`)
            .then((res) => {
                update(Date.now())
                setUpdateUser(Date.now())
            })
            .catch((err) => { })
    }

    return (
        <div className="w-full bg-white flex items-center shadow-bs1 p-4
        rounded-lg md:flex  sm:flex md:items-center  sm:items-center md:gap-2  sm:gap-2">
            <div className="w-full flex gap-2">
                <div className="flex gap-2 items-center">
                    {data.shipment.type === 1 &&
                        <BsTruck className="w-4 h-4 text-teal_600" />
                    }
                    {data.shipment.type === 2 &&
                        <BsAirplane className="w-4 h-4 text-teal_600" />
                    }
                    {data.shipment.type === 3 &&
                        <FaShip className="w-4 h-4 text-teal_600" />
                    }
                    <p className={`text-sm md:text-xs text-teal_600  font-semibold`}>
                        {data.shipment.code}
                    </p>
                </div>
                <p className={`${data.status === 0 ? "text-red-400" : "text-teal_600"} font-semibold`}>
                    {data.price} ₾
                </p>
            </div>
            <div className="w-full flex items-center justify-end gap-2">
                <div className="flex gap-2 items-center">
                    <BsFillFileEarmarkPdfFill className="text-red-400 w-6 h-6 cursor-pointer shrink-0"
                        onClick={() => { setToggleInvoice(true) }}
                    />
                    {data.status === 0 ?
                        <Button
                            className="h-full cursor-pointer font-normal
   text-sm text-center text-white_A700 w-full"
                            shape="RoundedBorder8"
                            size="unord"
                            variant="OutlineTeal600_4"
                            onClick={handlePay}
                        >
                            {t("pay")}
                        </Button> :
                        <Button
                            className="cursor-pointer font-normal
 text-sm text-center text-cyan_900 w-full"
                            shape="RoundedBorder8"
                            size="unord"
                            variant="OutlineCyan900_1"
                        >
                            {t("paid")}
                        </Button>
                    }
                </div>
            </div>
            {toggleInvoice &&
                <DeclarationInv
                    handleInvoiceToggle={setToggleInvoice}
                    data={data}
                />
            }
        </div >
    )
}

export default PayFlightsCard;