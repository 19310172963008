import { useState, useEffect } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import LogsItem from "./LogsItem";
import PackItem from "./PackItem";
import TransactionsItem from "./TransactionsItem";
import { BsSearch } from "react-icons/bs";

const Archive = () => {
    //data 
    const [data, setData] = useState([]);
    //err
    const [error, setError] = useState(null);
    //archive options
    const [archiveOptions, setArchiveOptions] = useState(["ტრანზაქციები", "ლოგები", "პაკეტების ისტორია"])
    //current option
    const [currentOption, setCurrentOption] = useState("ტრანზაქციები");
    //loaded ended
    const [isLoaded, setIsLoaded] = useState(false);
    //search value
    const [searchValue, setSearchValue] = useState("");

    //fetch transactions
    const handleTransactionsFetch = async () => {
        setIsLoaded(false);
        try {
            const res = await axiosAdmin.get(`/transactions`);
            setData(res.data.data);
            setIsLoaded(true);
        } catch (err) {
            setError(err);
        }
    }

    //fetch logs
    const handleLogsFetch = async () => {
        setIsLoaded(false);
        try {
            const res = await axiosAdmin.get(`/logs`);
            setData(res.data.data);
            setIsLoaded(true);
        } catch (err) {
            setError(err);
        }
    }

    //fetch packHistory
    const handlePackHistoryFetch = async () => {
        setIsLoaded(false);
        try {
            const res = await axiosAdmin.get(`/packHistory?search=${searchValue}`);
            setData(res.data.data);
            setIsLoaded(true);
        } catch (err) {
            setError(err);
        }
    }

    //logs data from api
    useEffect(() => {
        if (currentOption === "ტრანზაქციები") {
            handleTransactionsFetch();
        } else if (currentOption === "ლოგები") {
            handleLogsFetch();
        } else if (currentOption === "პაკეტების ისტორია") {
            handlePackHistoryFetch();
        }
    }, [currentOption, searchValue]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24 ">
            <div>
                <div className="w-full flex gap-1 items-center">
                    {archiveOptions.map((item, index) => {
                        return (
                            <div className={`px-2 py-3 rounded-lg shadow-md border border-gray-300
                            ${currentOption === item ? "bg-green-300" : "bg-white dark:bg-slate-800"} cursor-pointer`}
                                key={index}
                                onClick={() => { setCurrentOption(item); setData([]) }}
                            >
                                <p className={`text-sm font-bold text-slate-500 ${currentOption === item && "text-white"}`}>
                                    {item}
                                </p>
                            </div>
                        )
                    })}
                </div>
                {currentOption === "პაკეტების ისტორია" &&
                    <div className="w-full flex justify-between sm:flex-wrap sm:gap-3 mt-5">
                        <div className="w-full flex justify-between gap-1 sm:w-full">
                            <div className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
                hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
                border dark:border-slate-600 w-full bg-white">
                                <input
                                    type="search"
                                    placeholder="მომხმარებლის ID, ელ-ფოსტა, ტელეფონი"
                                    className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                    dark:bg-slate-800 border-0"
                                    onChange={(e) => { setSearchValue(e.target.value) }}
                                    value={searchValue}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="w-full mt-5 flex flex-col gap-2">
                    {isLoaded &&
                        <>
                            {currentOption === "ტრანზაქციები" &&
                                data.map((item, index) => {
                                    return (
                                        <TransactionsItem
                                            key={index}
                                            itemData={item}
                                        />
                                    )
                                })
                            }
                            {currentOption === "ლოგები" &&
                                data.map((item, index) => {
                                    return (
                                        <LogsItem
                                            key={index}
                                            itemData={item}
                                        />
                                    )
                                })
                            }
                            {currentOption === "პაკეტების ისტორია" &&
                                data.map((item, index) => {
                                    return (
                                        <PackItem
                                            key={index}
                                            itemData={item}
                                        />
                                    )
                                })
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Archive;