import { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import { BsDownload, BsX } from "react-icons/bs";

//images
import logo from "../../images/mglLogo.png";
import shtamp from "../../images/shtamp.png";
import { PrimaryContext } from "../MainContext/MainContext";

const DeclarationInv = ({ handleInvoiceToggle, data }) => {
  //reference to invoice
  const innerRef = useRef();
  //data from context
  const { user } = useContext(PrimaryContext);

  return (
    <div
      className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
    >
      <div
        className="bg-white flex flex-col items-center justify-center gap-[9px] w-[60%] md:w-[80%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative"
      >
        <div className="w-full flex items-center justify-end gap-1">
          <ReactToPrint
            trigger={() => (
              <BsDownload className="text-teal_600 w-4 h-4 cursor-pointer" />
            )}
            content={() => innerRef.current}
          />
          <BsX
            className="text-gray-400 w-6 h-6 cursor-pointer"
            onClick={() => {
              handleInvoiceToggle(false);
            }}
          />
        </div>
        <div className="bg-white w-full h-[60vh] md:h-[80vh] sm:h-[50vh] overflow-auto scrollbar">
          <div ref={innerRef} className="w-full p-5 md:w-[1000px]">
            <div className=" flex items-center justify-between gap-5">
              <img
                src={logo}
                alt="expressgroup"
                className="w-[150px] shrink-0"
              />
              <div className="w-full">
                <p className="text-[10px] font-bold">
                  ფრიდონ ხალვაშის ქ.N 20. 6010 , ბათუმი, საქართველო, ტელ N :
                  +995 322 493990 ელ- ფოსტა: mgl@mgl.ge
                </p>
                <p className="text-[10px] font-bold mt-2">
                  20 Fridon Khalvashi str. 6010 Batumi, Georgia Mob N: +995 322
                  493990 E-mail : mgl@mgl.ge
                </p>
              </div>
            </div>
            <div className="w-full h-[2px] bg-teal_600 mt-5"></div>
            <div className="mt-5 flex items-center justify-center">
              <p className="text-base text-teal_600">
                ი ნ ვ ო ი ს ი / I N V O I C E / ს ა ა მ ა ნ ა თ ო / P A R C E L{" "}
              </p>
            </div>
            <div className="mt-5">
              <div className="w-full border border-teal_600 mt-2">
                <div className="grid grid-cols-2">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    ინვოისის თარიღი / Invoice Date:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {data.shipment.arrival_date}
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    ინვოისის ნომერი / Invoice Number
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {data.shipment.code}-MGL{user.id}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="w-full border border-teal_600 mt-2">
                <div className="grid grid-cols-2">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    ტვირთ მიმღები / Consignee:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {user.company_id === null
                      ? `${user.fname} ${user.lname}`
                      : `${user.cname}`}
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    პირადი ნომერი / Personal Number:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {user.company_id === null
                      ? user.person_id
                      : user.company_id}
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    რეისი:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {data.shipment.code}
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    მომსახურების ტიპი:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    დეკლარანტის მომსახურება
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    მომსახურების თანხა:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {(data.price / (118 / 100)).toFixed(2)} ლარი
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    დღგ:
                  </p>
                  <p className="text-sm text-teal_600 p-1">
                    {(
                      data.price - (data.price / (118 / 100)).toFixed(2)
                    ).toFixed(2)}{" "}
                    ლარი
                  </p>
                </div>
                <div className="grid grid-cols-2  border-t border-teal_600">
                  <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                    სულ თანხა / Total supplements:
                  </p>
                  <p className="text-sm text-teal_600 p-1">{data.price} ლარი</p>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p className="font-bold text-xs">
                გთხოვთ გაითვალისწინეთ, თანხის ჩარიცხვისას აუცილებლად მიუთითეთ
                ინვოისის
              </p>
              <p className="font-bold text-xs">
                ნომერი და მიმღების პირადი / საიდენტიფიკაციო ნომერი.{" "}
              </p>
            </div>
            <div className="mt-5 flex gap-5 justify-end">
              <img src={shtamp} className="w-32 h-32" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclarationInv;
