import { useState } from "react";
import { Link } from "react-router-dom";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const FaqCard = ({
    id,
    quistion,
    answer,
    handleDeleteFaq
}) => {

    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 w-full relative flex flex-col items-center justify-between gap-3 dark:bg-slate-700">
            <div className="w-full flex flex-col gap-2">
                <div className="flex gap-1 flex-col">
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200 ">{quistion}</p>
                    <p className="text-[12px] text-[#989ba5]">{answer}</p>
                </div>
            </div>
            <div className="w-full flex gap-2">
                <Link to={`../faq/${id}`} className="w-9/12 rounded-lg bg-violet-400
                 flex gap-2 items-center justify-center p-1 py-3">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">რედაქტირება</p>
                </Link>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-3/12 rounded-lg bg-red-400 cursor-pointer
                 flex gap-2 items-center justify-center p-1 py-3">
                    <BsTrash className="text-white" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteFaq}
                setShowContinue={setShowContinue}
                id={id}
            />
        </div>
    )
}


export default FaqCard;