import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import FilialCard from "./FilialCard";
import { TextInput } from "../../inputComponents/inputcomp";

const FilialLayout = () => {
  //add new user variable
  const [addNewToggler, setAddNewToggler] = useState(false);
  //get filial data from api
  const [filialData, setFilialData] = useState([]);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //new filial data
  const [newFilial, setNewFilial] = useState({});

  //handle new filial
  const handleNewFilialData = (value, inputName) => {
    setNewFilial((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new filials handler
  const handleAddNewToggle = (value) => {
    setAddNewToggler(value);
  };

  //add new filial
  const handleAddNewFilialToDb = async () => {
    try {
      const res = await axiosAdmin.post(`/offices`, newFilial);
      setDifResponses(res);
      if (res) {
        successToast("ფილიალი წარმატებით დაემატა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //delete filial
  const handleDeleteFilial = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/offices/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("ფილიალი წარმატებით წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //filial data from api
  useEffect(() => {
    axiosAdmin
      .get(`/offices`)
      .then(({ data }) => {
        setFilialData(data);
      })
      .catch(() => {});
  }, [difResponses]);

  return (
    <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
      <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5">
        <div className="w-full flex items-center justify-end ">
          <button
            onClick={() => {
              handleAddNewToggle(true);
            }}
            className="sm:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0"
          >
            ახალი ფილიალის დამატება
          </button>
        </div>
        {addNewToggler && (
          <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0">
            <div className="sm:px-0">
              <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                <div className="flex items-center justify-between flex-wrap gap-5">
                  <div className="flex items-center gap-5 sm:gap-3 ">
                    <div>
                      <h2
                        className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]"
                      >
                        ახალი ფილიალის დამატება
                      </h2>
                    </div>
                  </div>
                  <div className="flex gap-2 sm:w-full">
                    <button
                      onClick={handleAddNewFilialToDb}
                      className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
                    >
                      შენახვა
                    </button>
                    <button
                      onClick={() => {
                        handleAddNewToggle(false);
                      }}
                      className="rounded-lg p-3 text-white text-sm bg-red-400 w-10"
                    >
                      X
                    </button>
                  </div>
                </div>
                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                  <div className="w-6/12 sm:w-full md:w-full">
                    <TextInput
                      name={"სახელი"}
                      inputName={"name"}
                      inputSetter={handleNewFilialData}
                    />
                    <TextInput
                      name={"ქალაქი"}
                      inputName={"city"}
                      inputSetter={handleNewFilialData}
                    />
                  </div>
                  <div className="w-6/12 sm:w-full md:w-full">
                    <TextInput
                      name={"მისამართი"}
                      inputName={"address"}
                      inputSetter={handleNewFilialData}
                    />
                    <TextInput
                      name={"ტელეფონი"}
                      inputName={"phone"}
                      inputSetter={handleNewFilialData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full min-h-screen">
        <div className="grid grid-cols-2 sm:grid-cols-1  p-10 pt-0 sm:p-5 gap-2 sm:pt-0">
          {filialData.map((obj) => {
            return (
              <FilialCard
                key={obj.id}
                id={obj.id}
                name={`${obj.address} - ${obj.city} `}
                phone={obj.phone}
                handleDeleteFilial={handleDeleteFilial}
              />
            );
          })}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default FilialLayout;
