import Line from "../../components/InputComponents/Line";
import SelecetCountry from "../../components/SelecetCountry/SelecetCountry";
import { useContext, useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";
import { BsAirplane, BsTruck } from "react-icons/bs";
import { FaShip } from "react-icons/fa";
import TurkeyContent from "./TurkeyContent";
import ChinaContent from "./ChinaContent";

const Addresses = () => {
  const { t } = useTranslation();
  //shipment data
  const [address, setAddress] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //current country id
  const [currentCountry, setCurrentCountry] = useState("");
  //current country name
  const [currentCountryName, setCurrentName] = useState("");
  //context data
  const { user } = useContext(PrimaryContext);
  //dummy text for loader
  const dummyArray = Array.from({ length: 2 });

  //fetch faqs data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/abroadOffice?country_id=${currentCountry}`)
      .then(({ data }) => {
        setAddress(data);
        setIsLoading(false);
      });
  }, [currentCountry]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("addresses")}</h2>
      </div>
      <div className="mt-5">
        <SelecetCountry
          retriveValue={setCurrentCountry}
          retriveName={setCurrentName}
        />
      </div>

      {isLoading ? (
        <div className="grid grid-cols-1 gap-2 mt-5 mb-5">
          {dummyArray.map((_, index) => {
            return (
              <div
                className={`animate-pulse bg-gray-200 w-full h-60 rounded-[30px]`}
                key={index}
              ></div>
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col gap-2 mt-5 mb-5">
          {address.map((item) => {
            return (
              <div
                className="shadow-bs1 rounded-[30px] sm:rounded-lg mt-5 bg-white"
                key={item.id}
              >
                {currentCountry === 4 && (
                  <TurkeyContent
                    item={item}
                    user={user}
                    currentCountryName={currentCountryName}
                    currentCountry={currentCountry}
                  />
                )}
                {currentCountry === 3 && (
                  <ChinaContent
                    item={item}
                    user={user}
                    currentCountryName={currentCountryName}
                    currentCountry={currentCountry}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Addresses;
