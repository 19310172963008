import { useState } from "react";

const LogsItem = ({
    itemData
}) => {
    const [date, setDate] = useState(() => {
        const formattedDateTime = new Date(itemData.created_at).toLocaleString();
        return formattedDateTime;
    })

    return (
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-2 bg-white rounded-lg p-2 dark:bg-slate-700">
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ადმინი:</p>
                    <p className="text-gray-500 text-[14px]">
                        {itemData.admin_id} - {(itemData.admin !== null && itemData !== null) && itemData.admin.name}
                    </p>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">თარიღი:</p>
                    <p className="text-gray-500 text-[14px]">{date}</p>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">მოქმედება</p>
                    <p className="text-gray-500 text-[14px]">{itemData.action}</p>
                </div>
            </div>
            <div className="w-full">

                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">აღწერა</p>
                    <p className="text-gray-500 text-[14px]">{itemData.description}</p>
                </div>
            </div>
        </div>
    )
}

export default LogsItem;
