import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import TextInput from "../../InputComponents/TextInput";
import axiosClient from "../../../api/api";
import SelectInput from "../../InputComponents/SelectInput";

import { BiSearch } from "react-icons/bi";

const CalculatorSection = () => {
  const { t } = useTranslation();
  const [calculatorType, setCalculatorType] = useState("volume");
  const [calculateVariables, setCalculatorVariables] = useState({
    x: 0,
    y: 0,
    z: 0,
    weight: 0,
    abroad_office_id: 0,
  });
  const [abOffice, setAbOffice] = useState([]);
  const [volumeResults, setVolumeResults] = useState({
    price: 0,
    volume_weight: 0,
  });
  //search value
  const [searchValue, setSearchValue] = useState({
    code: "",
  });
  //searched parcel
  const [parcel, setParcel] = useState("");

  const handleCalculatorVariables = (value, key) => {
    setCalculatorVariables((prev) => ({ ...prev, [key]: value }));
  };

  const calculateVolume = () => {
    const payload = {
      x: parseFloat(calculateVariables.x),
      y: parseFloat(calculateVariables.y),
      z: parseFloat(calculateVariables.z),
      abroad_office_id: parseInt(calculateVariables.abroad_office_id),
    };

    axiosClient
      .post("/calculate", payload)
      .then(({ data }) => {
        setVolumeResults({
          price: data.price,
          volume_weight: data.volume_weight,
        });
      })
      .catch((err) => {});
  };

  const handleSearchValue = (value, inputName) => {
    setSearchValue({
      [inputName]: value,
    });
  };

  useEffect(() => {
    axiosClient.get("/abroadOffice").then(({ data }) => {
      setAbOffice(
        data.map((abOffices) => {
          return {
            id: abOffices.id,
            title: abOffices.country.split(" ")[0],
          };
        })
      );
    });
  }, []);

  useEffect(() => {
    if (searchValue.code !== "") {
      axiosClient.post("/searchPack", searchValue).then((res) => {
        setParcel(res.data);
      });
    }
  }, [searchValue]);

  return (
    <div className="px-12 md:px-6 w-full flex items-center justify-center">
      <div className="w-full max-w-[80%] md:max-w-[100%] flex flex-col items-center justify-center">
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <h6 className="text-black_900 text-2xl md:text-xl font-bold">
            {t("calculator")}
          </h6>
        </div>
        <div className={"mt-12 md:mt-6 sm:w-full sm:flex sm:justify-center"}>
          <div className="flex items-center bg-[#D9D9D9] rounded-[30px] sm:w-8/12">
            <button
              className={`px-8 py-4 font-bold cursor-pointer rounded-[30px] transition-all sm:w-full 
              sm:px-4 sm:text-xs
                     ${
                       calculatorType === "parcel" && "bg-teal_600 text-white"
                     }`}
              onClick={() => {
                setCalculatorType("parcel");
              }}
            >
              <h2>{t("parcels")}</h2>
            </button>
            <button
              className={`px-8 py-4 font-bold cursor-pointer rounded-[30px] transition-all sm:w-full 
              sm:px-4 sm:text-xs
                     ${
                       calculatorType === "volume" && "bg-teal_600 text-white"
                     }`}
              onClick={() => {
                setCalculatorType("volume");
              }}
            >
              <h2>{t("volume")}</h2>
            </button>
          </div>
        </div>
        <div className={`w-full mt-12 md:mt-6 gap-5`}>
          <div className="flex md:flex-col md:gap-10">
            <div className="w-7/12 p-5 border border-[#F0F0F0] rounded-l-[20px] md:w-full md:rounded-[20px]">
              {calculatorType === "volume" && (
                <>
                  <div className="grid grid-cols-2 gap-5 sm:grid-cols-1">
                    <TextInput
                      name={t("width")}
                      inputSetter={handleCalculatorVariables}
                      inputName={"x"}
                      style={2}
                    />
                    <TextInput
                      name={t("height")}
                      inputSetter={handleCalculatorVariables}
                      inputName={"y"}
                      style={2}
                    />
                    <TextInput
                      name={t("length")}
                      inputSetter={handleCalculatorVariables}
                      inputName={"z"}
                      style={2}
                    />
                    <SelectInput
                      options={abOffice}
                      name={t("country")}
                      inputName={"abroad_office_id"}
                      inputSetter={handleCalculatorVariables}
                      style={2}
                    />
                  </div>
                  <div
                    className="mt-5 pt-5 border-t-2 border-[#F0F0F0] flex items-center gap-10 sm:flex-col
                  sm:gap-5 sm:items-start"
                  >
                    <button
                      onClick={calculateVolume}
                      className="bg-teal_600 p-3 text-white rounded-[30px] sm:order-2"
                    >
                      {t("calculate")}
                    </button>
                    <div className="flex items-center gap-5 sm:order-1">
                      <p className="font-semibold text-lg">
                        {volumeResults.volume_weight} {t("kg")}
                      </p>
                      <p className="font-semibold text-lg">
                        {volumeResults.price} ₾
                      </p>
                    </div>
                  </div>
                </>
              )}
              {calculatorType === "parcel" && (
                <div
                  className="bg-white self-start flex md:flex-1 flex-col 
                            h-full items-center justify-start rounded-[34px] w-full"
                >
                  <div className="flex flex-col items-start justify-start w-full gap-3">
                    <h2 className="text-base sm:text-sm font-bold">
                      {t("track_parcels")}
                    </h2>
                    <div className="w-full flex flex-col gap-5">
                      <p className="text-sm sm:text-xs">
                        {t("track_parcels_quote")}
                      </p>
                      <TextInput
                        PrefixIcon={BiSearch}
                        placeholder={"Tracking Number"}
                        inputSetter={handleSearchValue}
                        inputName={"code"}
                        style={2}
                      />
                      {parcel === "" ? (
                        searchValue.code !== "" && (
                          <p className="font-semibold text-red-500 text-sm">
                            {t("data_not_found")}
                          </p>
                        )
                      ) : (
                        <p className="font-semibold text-teal_600 text-sm">
                          {t("parcel_status")}
                          {parcel.status === 1 && t("received")}
                          {parcel.status === 2 && t("sent")}
                          {parcel.status === 3 && t("arrived")}
                          {parcel.status === 4 && t("takeout")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-5/12 bg-teal_600 p-5 text-white font-bold rounded-r-[20px] md:w-full md:rounded-[20px]">
              <p className="text-base">{t("calculatorsTitle")}</p>
              <p className="text-sm mt-5">{t("calculatorsTextOne")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorSection;
