import Line from "../../../components/InputComponents/Line";
import { BsX, BsLock } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const PasswordChanged = ({ closePopupSetter }) => {
    const { t } = useTranslation();
    return (
        <div className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50">
            <div className="bg-white flex flex-col items-center justify-center gap-[9px] w-[39%] md:w-[60%] sm:w-[80%] p-7 sm:px-5
            border border-gray-200 shadow-bs1 rounded-[30px] relative ">
                <BsX
                    className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
                    onClick={() => { closePopupSetter(false) }}
                />
                <div className="flex flex-col gap-3 items-center justify-center w-full">
                    <BsLock className="w-10 h-10 text-teal_600" />
                    <Line className="bg-teal_600 h-0.5 w-20" />
                    <h2 className="font-semibold text-xl md:text-base w-auto text-center">
                    {t("password")}  {t("changed")}
                    </h2>
                    <p
                        onClick={() => { closePopupSetter(false) }}
                        className="text-teal_600 cursor-pointer text-sm sm:text-xs w-auto text-center font-semibold">
                        {t("go_back")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PasswordChanged;