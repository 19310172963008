import { useTranslation } from "react-i18next";
import FaqSection from "../../components/landingSections/faqSection/FaqSection";
import ContactSection from "../../components/landingSections/contactSection/ContactSection";
import Banner from "../../components/Banner/Banner";
import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import BlogsCard from "./BlogsCard";

const Blogs = ({ menuRefs }) => {
  const { t } = useTranslation();
  const [instructions, setInstructions] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(false);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetch faqs data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get("/blog?type=blog")
      .then(({ data }) => {
        setInstructions(data);
        setIsLoading(false);
      })
      .then((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="flex items-center justify-center flex-col gap-10 pb-10">
      <Banner bannerText={t("blogs")} />
      <div className="px-12 md:px-6 w-full max-w-[80%] md:max-w-[100%] grid grid-cols-1 gap-5">
        <div
          className={`
        ${
          !isLoading && instructions.length === 0
            ? "flex items-center justify-center"
            : "grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1 items-stretch"
        }
        `}
        >
          {isLoading ? (
            dummyArray.map((_, index) => {
              return (
                <div
                  className="animate-pulse bg-slate-100 rounded-xl min-h-[300px] w-full"
                  key={index}
                ></div>
              );
            })
          ) : instructions.length === 0 ? (
            <p className="font-bold">{t("blogs_not_found")}</p>
          ) : (
            instructions.map((item) => {
              return <BlogsCard key={item.id} item={item} />;
            })
          )}
        </div>
      </div>
      <FaqSection />
      <div ref={menuRefs?.contact} className="w-full">
        <ContactSection />
      </div>
    </div>
  );
};

export default Blogs;
