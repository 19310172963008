import { useTranslation } from "react-i18next";

const ServicesSection = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="flex flex-col font-helvetica gap-4 items-center justify-start w-full">
        <h6 className="text-black_900 text-2xl md:text-xl font-bold">
          {t("our_services")}
        </h6>
        <h5 className="text-base max-w-[80vw] text-center text-red-500">
          {t("kg_constraint")}
        </h5>
      </div>
      <div className="mt-12 md:mt-6 sm:mt-3 w-full">
        <div className="flex flex-col w-full gap-5">
          <div className=" w-full flex h-[30vh] sm:h-[26vh]">
            <div
              className="p-5 rounded-r-[20px] bg-[#F0F0F0] w-6/12 md:pr-[20%]
            flex flex-col sm:items-center justify-center"
            >
              <h5 className="text-xl font-bold md:text-base sm:text-sm afMd:pl-[10%]">
                {t("land_transportation")}
              </h5>
              {/* <p className="text-sm sm:text-xs mt-5 md:mt-2 font-bold afMd:pl-[10%]">
                - {t("land_serv2")}
              </p> */}
              <p className="text-sm sm:text-xs mt-5 md:mt-2 font-bold afMd:pl-[10%]">
                - {t("land_serv3")}
              </p>
            </div>
            <div
              className=" w-80 h-[90%] sm:h-[80%] truck-bg bg-center bg-no-repeat bg-cover rounded-[20px]
            my-auto translate-x-[-20%] sm:w-[50vw]"
            ></div>
          </div>

          <div className=" w-full flex h-[30vh] justify-end sm:h-[26vh]">
            <div
              className=" w-80 h-[90%] sm:h-[80%] plane-bg bg-center bg-no-repeat bg-cover rounded-[20px]
            my-auto translate-x-[20%] sm:w-[50vw]"
            ></div>
            <div
              className="p-5 rounded-l-[20px] bg-teal_600 w-6/12 pl-[10%] md:pl-[16%]
            flex flex-col sm:items-center justify-center"
            >
              <h5 className="text-xl font-bold text-white md:text-base sm:text-sm">
                {t("air_transportation")}
              </h5>
              <p className="text-sm sm:text-xs mt-5 md:mt-2 font-bold text-white">
                - {t("air_serv1")}
              </p>
              <p className="text-sm sm:text-xs mt-5 md:mt-2 font-bold text-white">
                - {t("air_serv2")}
              </p>
            </div>
          </div>

          <div className=" w-full flex h-[30vh] sm:h-[26vh]">
            <div
              className="p-5 rounded-r-[20px] bg-[#F0F0F0] w-6/12 md:pr-[20%]
            flex flex-col sm:items-center justify-center"
            >
              <h5 className="text-xl font-bold md:text-base sm:text-sm afMd:pl-[10%]">
                {t("sea_transportation")}
              </h5>
              <p className="text-sm sm:text-xs mt-5 md:mt-2 font-bold afMd:pl-[10%]">
                - {t("sea_serv1")}
              </p>
            </div>
            <div
              className=" w-80 h-[90%] sm:h-[80%] ship-bg bg-center bg-no-repeat bg-cover rounded-[20px]
            my-auto translate-x-[-20%] sm:w-[50vw]"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
