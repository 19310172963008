import axiosClient from "../../../api/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FaqCard from "./FaqCard";

const FaqSection = () => {
  //translations
  const { t } = useTranslation();
  //faqs data
  const [faqs, setFaqs] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetch faqs data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get("/faq")
      .then(({ data }) => {
        setFaqs(data);
        setIsLoading(false);
      })
      .then((err) => {});
  }, []);

  return (
    <div className="px-12 md:px-6 w-full flex flex-col items-center justify-center">
      <div className="w-full max-w-[80%] md:max-w-[100%]">
        <div className="w-full flex flex-col items-center justify-center gap-4">
          <h6 className="text-black_900 text-2xl md:text-xl font-bold">
            {t("frequently_asked_questions")}
          </h6>
        </div>
        <div
          className={`w-full mt-12 md:mt-6 flex flex-col gap-5 min-h-[200px]
          ${isLoading && "items-center justify-center"}`}
        >
          {isLoading ? (
            <div className="grid grid-cols-2 md:grid-cols-1 gap-5">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className="animate-pulse bg-slate-100 w-full h-20 rounded-xl"
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-1 gap-5">
              {faqs.map((item) => {
                return <FaqCard key={item.id} item={item} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
