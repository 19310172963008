import logo from "../../images/mglLogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Img from "../InputComponents/Img";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import LanguageSwitcher from "../MainContext/LanguageSwitcher";
import { PrimaryContext } from "../MainContext/MainContext";
import axiosClient from "../../api/api";
import { BsDoorOpen } from "react-icons/bs";

const Header2 = ({ menuRefs }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [toggleInformation, setToggleInformation] = useState(false);
  const navigate = useNavigate();
  const { setUser, setToken, setIsAuthorized, isAuthorized } =
    useContext(PrimaryContext);

  const handleScrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("MGL_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div
      className="border-b border-[#FAFAFA] flex items-center justify-between gap-10 px-12 md:px-6 py-5
    md:hidden"
    >
      <Link to={"/"}>
        <Img
          src={logo}
          className="object-cover w-[120px] mr-5 sm:mr-0"
          alt="mgleng011"
        />
      </Link>
      <div className="flex items-center gap-4">
        <Link to={"/"}>
          <h6 className="text-sm sm:text-xs">{t("home")}</h6>
        </Link>
        {location.pathname === "/" && (
          <h6
            onClick={() => {
              handleScrollToSection(menuRefs.aboutUs);
            }}
            className="text-sm sm:text-xs cursor-pointer"
          >
            {t("about_us")}
          </h6>
        )}
        <div className="relative">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              setToggleInformation((prev) => !prev);
            }}
          >
            <h6 className="text-sm sm:text-xs ">{t("information")}</h6>
            <CaretDownFill
              className={`transition-all ${toggleInformation && "rotate-180"}`}
            />
          </div>

          {toggleInformation && (
            <div className="absolute top-[150%] right-0  rounded-lg bg-white border-[#e4e4e4] border z-50">
              <Link
                to={"/customs-information"}
                onClick={() => {
                  setToggleInformation((prev) => !prev);
                }}
                className="inline-block border-b border-[#e4e4e4] p-2"
              >
                <h6 className="text-sm sm:text-xs">
                  {t("customs_regulations")}
                </h6>
              </Link>

              <Link
                to={"/forbidden-parcels"}
                onClick={() => {
                  setToggleInformation((prev) => !prev);
                }}
                className="inline-block p-2"
              >
                <h6 className="text-sm sm:text-xs"> {t("denied_items")}</h6>
              </Link>
            </div>
          )}
        </div>
        {!location.pathname.split("/").includes("personal-cabinet") && (
          <h6
            onClick={() => {
              handleScrollToSection(menuRefs.contact);
            }}
            className="text-sm sm:text-xs cursor-pointer"
          >
            {t("contact")}
          </h6>
        )}
        <Link to={"/blogs"}>
          <h6 className="text-sm sm:text-xs">{t("blogs")}</h6>
        </Link>
      </div>
      <div className="flex items-center gap-2">
        {/* <Link to={"/instructions"} className="shrink-0">
          <div className="h-10 border-2 border-teal_600 rounded-[30px] flex items-center gap-3 pr-3 shrink-0">
            <div className="bg-teal_600 w-10 h-10 rounded-full flex items-center justify-center">
              <BsGear className="text-white text-xl" />
            </div>
            <h6 className="text-sm sm:text-xs">{t("instructions")}</h6>
          </div>
        </Link> */}
        <LanguageSwitcher />
        {isAuthorized && (
          <div
            className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
            onClick={handleLogout}
          >
            <BsDoorOpen className="w-full h-full text-white" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header2;
