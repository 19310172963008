import { useTranslation } from "react-i18next";
import FaqSection from "../../components/landingSections/faqSection/FaqSection";
import ContactSection from "../../components/landingSections/contactSection/ContactSection";
import Banner from "../../components/Banner/Banner";

const CustomsRules = ({ menuRefs }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center flex-col gap-10 pb-10">
      <Banner bannerText={t("customs_regulations")} />
      <div className="px-12 md:px-6 w-full max-w-[80%] md:max-w-[100%] grid grid-cols-1 gap-5">
        <div className="rounded-[30px] overflow-hidden">
          <div className="bg-teal_600 p-5">
            <h2 className={`text-base sm:text-sm font-bold mt-2 text-white`}>
              {t("customs_rules_and_laws")}
            </h2>
          </div>
          <div className="p-5 bg-[#FAFAFA]">
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws_text")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws_text1")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws_text2")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws_text3")}
            </p>
          </div>
        </div>

        <div className="rounded-[30px] overflow-hidden">
          <div className="bg-teal_600 p-5">
            <h2 className={`text-base sm:text-sm font-bold mt-2 text-white`}>
              {t("customs_rules_and_laws1")}
            </h2>
          </div>
          <div className="p-5 bg-[#FAFAFA]">
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws1_text")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws1_text1")}
            </p>
          </div>
        </div>

        <div className="rounded-[30px] overflow-hidden">
          <div className="bg-teal_600 p-5">
            <h2 className={`text-base sm:text-sm font-bold mt-2 text-white`}>
              {t("customs_rules_and_laws2")}
            </h2>
          </div>
          <div className="p-5 bg-[#FAFAFA]">
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws2_text")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws2_text1")}
            </p>
          </div>
        </div>

        <div className="rounded-[30px] overflow-hidden">
          <div className="bg-teal_600 p-5">
            <h2 className={`text-base sm:text-sm font-bold mt-2 text-white`}>
              {t("customs_rules_and_laws3")}
            </h2>
          </div>
          <div className="p-5 bg-[#FAFAFA]">
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text1")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text2")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text3")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text4")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text5")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws3_text6")}
            </p>
          </div>
        </div>

        <div className="rounded-[30px] overflow-hidden">
          <div className="bg-teal_600 p-5">
            <h2 className={`text-base sm:text-sm font-bold mt-2 text-white`}>
              {t("customs_rules_and_laws4")}
            </h2>
          </div>
          <div className="p-5 bg-[#FAFAFA]">
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text1")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text2")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text3")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text4")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text5")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text6")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text7")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text8")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text9")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text10")}
            </p>
            <p className={`text-sm sm:text-xs mt-2 font-bold`}>
              {t("customs_rules_and_laws4_text11")}
            </p>
          </div>
        </div>
      </div>
      <FaqSection />
      <div ref={menuRefs.contact} className="w-full">
        <ContactSection />
      </div>
    </div>
  );
};

export default CustomsRules;
