import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import {
  MultyLangualTextInput,
  MultyLangualTextArea,
  SelectInputValueId,
  TextInput,
} from "../../inputComponents/inputcomp";

//images
import logo from "../../../img/phoneLogo.png";

const InnerFaqPage = () => {
  //get current user id
  const { faqId } = useParams();

  //new faq data
  const [faqData, setFaqData] = useState({});
  //get faq data error from api
  const [faqDataErr, setFaqDataErr] = useState(null);
  //get faq data error from api
  const [faqEditErr, setFaqEditErr] = useState(null);

  //handle new faq
  const handleNewFaqData = (value, inputName) => {
    setFaqData((prev) => ({ ...prev, [inputName]: value }));
  };

  //add new faq
  const handleEditFaq = async () => {
    try {
      const res = await axiosAdmin.put(`/faq/${faqId}`, faqData);
      if (res) {
        successToast("მონაცემები წარმატებით განახლდა!");
      }
    } catch (error) {
      setFaqEditErr(error);
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //faq data from api
  useEffect(() => {
    try {
      axiosAdmin.get(`/faq/${faqId}`).then(({ data }) => {
        setFaqData({ ...data, sort: 1 });
      });
    } catch (error) {
      setFaqDataErr(error);
    }
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="flex items-center gap-5 sm:gap-3">
              <div className="w-[100px] h-10">
                <img src={logo} className="w-full h-full" />
              </div>
              <div>
                <h2
                  className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                >
                  ხდკ-ს რედაქტირება
                </h2>
              </div>
            </div>
            <button
              onClick={handleEditFaq}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <SelectInputValueId
            name={"კატეგორიები"}
            options={[
              {
                name: "გადახდები",
                value: "payment",
              },
              {
                name: "ზოგადი",
                value: "general",
              },
              {
                name: "საბაჟო წესები",
                value: "customs_rules",
              },
            ]}
            inputName={"mother"}
            inputSetter={handleNewFaqData}
            value={faqData.mother}
          />
          <MultyLangualTextInput
            name={"ფილიალის სახელი"}
            geInputName={"title"}
            enInputName={"titleen"}
            inputSetter={handleNewFaqData}
            value={faqData.title}
            valueen={faqData.titleen}
          />
          <MultyLangualTextArea
            name={"პასუხი"}
            geInputName={"text"}
            enInputName={"texten"}
            ruInputName={"textru"}
            inputSetter={handleNewFaqData}
            value={faqData.text}
            valueen={faqData.texten}
          />
          <TextInput
            name={"youtube embed url"}
            inputName={"video"}
            inputSetter={handleNewFaqData}
            value={faqData.video}
          />
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default InnerFaqPage;
