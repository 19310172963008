import { useContext, useRef } from "react";
import ReactToPrint from "react-to-print";
import { BsDownload, BsX } from "react-icons/bs";

//icons
import { BiMap } from "react-icons/bi";
import { BsGlobe, BsTelephone, BsEnvelope } from "react-icons/bs";

//images
import logo from "../../images/mglLogo.png"
import shtamp from "../../images/shtamp.png";
import { PrimaryContext } from "../MainContext/MainContext";
import { useTranslation } from "react-i18next";

const Invoice = ({ handleInvoiceToggle, data }) => {
    const { t } = useTranslation();
    //reference to invoice
    const innerRef = useRef();
    //data from context
    const { user } = useContext(PrimaryContext);

    return (
        <div className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50">
            <div className="bg-white flex flex-col items-center justify-center gap-[9px] w-[60%] md:w-[80%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative">
                <div className="w-full flex items-center justify-end gap-1">

                    <ReactToPrint
                        trigger={() =>
                            <BsDownload
                                className="text-teal_600 w-4 h-4 cursor-pointer"
                            />
                        }
                        content={() => innerRef.current}
                    />
                    <BsX
                        className="text-gray-400 w-6 h-6 cursor-pointer"
                        onClick={() => { handleInvoiceToggle(false) }}
                    />
                </div>
                <div className="bg-white w-full h-[60vh] md:h-[80vh] sm:h-[50vh] overflow-auto scrollbar">
                    <div ref={innerRef} className="w-full p-5 md:w-[1000px]">
                        <div className=" flex items-center justify-between">
                            <img src={logo} alt="expressgroup" className="w-[150px]" />
                            <div>
                                <div className="flex gap-4 items-center justify-end">
                                    <div className="flex items-center gap-1">
                                        <p className="text-sm text-black font-bold">
                                            20 ST.Pridon Khalvashi 6000 Batumi, Georgia
                                        </p>
                                        <BiMap className="text-teal_600 w-4 h-4" />
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <p className="text-sm text-black font-bold">
                                            032 243 39 90
                                        </p>
                                        <BsTelephone className="text-teal_600 w-4 h-4" />
                                    </div>
                                </div>
                                <div className="flex gap-4 items-center justify-end">
                                    <div className="flex items-center gap-1">
                                        <p className="text-sm text-black font-bold">
                                            www.mgl.bg
                                        </p>
                                        <BsGlobe className="text-teal_600 w-4 h-4" />
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <p className="text-sm text-black font-bold">
                                            email@email.com
                                        </p>
                                        <BsEnvelope className="text-teal_600 w-4 h-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 mt-5">
                            <div>
                                <p className="font-bold text-sm text-teal_600">
                                    ინვოისისი დეტალები
                                </p>
                                <div className="w-full border border-teal_600 mt-2">
                                    <div className="grid grid-cols-2">
                                        <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                                            {t("date")}
                                        </p>
                                        <p className="text-sm text-teal_600 p-1">
                                            {data.pay_time}
                                        </p>
                                    </div>
                                    <div className="grid grid-cols-2  border-t border-teal_600">
                                        <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                                            {t("tracking_number")}
                                        </p>
                                        <p className="text-sm text-teal_600 p-1">
                                            {data.tracking_number}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="font-bold text-sm text-teal_600">
                                    {t("user_information")}
                                </p>
                                <div className="w-full border border-teal_600 mt-2">
                                    <div className="grid grid-cols-2">
                                        <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                                            {user.company_id === null ?
                                                `${t("name")} ${t("surname")}` :
                                                t("company_name")
                                            }
                                        </p>
                                        <p className="text-sm text-teal_600 p-1">
                                            {user.company_id === null ?
                                                `${user.fname} ${user.lname}` :
                                                `${user.cname}`
                                            }
                                        </p>
                                    </div>
                                    <div className="grid grid-cols-2  border-t border-teal_600">
                                        <p className="text-sm text-teal_600 border-r border-teal_600 p-1 bg-teal-100">
                                            {user.company_id === null ?
                                                t("personal_id") :
                                                t("company_id")
                                            }
                                        </p>
                                        <p className="text-sm text-teal_600 p-1">
                                            {user.company_id === null ?
                                                user.person_id :
                                                user.company_id
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="flex items-center justify-between">
                                <p className="text-sm text-teal_600 p-1">
                                    {t("transportation_details")}
                                </p>
                                <p className="text-sm text-teal_600 p-1">
                                    {t("details")}
                                </p>
                            </div>
                            <div className="w-full border border-teal_600 mt-2 flex flex-col">
                                <div className="w-full flex">
                                    <p className="w-[10%] text-sm text-teal_600 p-1 bg-teal-100 border-r border-teal_600">
                                        #
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1 bg-teal-100 border-r border-teal_600">
                                        {t("services")}
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1 bg-teal-100 border-r border-teal_600">
                                        {t("weight")}
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1 bg-teal-100">
                                        {t("price")}
                                    </p>
                                </div>
                                <div className="w-full flex border-t border-teal_600">
                                    <p className="w-[10%] text-sm text-teal_600 p-1 border-r border-teal_600">
                                        1
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1 border-r border-teal_600">
                                        {t("transportation_price")}
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1 border-r border-teal_600">
                                        {data.total_weight} კგ
                                    </p>
                                    <p className="w-full text-sm text-teal_600 p-1">
                                        {data.transport_price}
                                    </p>
                                </div>
                                {(data.addon_secur !== 0 && data.addon_secur !== null) &&
                                    <div className="w-full flex border-t border-teal_600">
                                        <p className="w-[10%] text-sm text-teal_600 p-1 border-r border-teal_600">
                                            2
                                        </p>
                                        <p className="w-full text-sm text-teal_600 p-1 border-r border-teal_600">
                                            {t("insurance_price")}
                                        </p>
                                        <p className="w-full text-sm text-teal_600 p-1 border-r border-teal_600">
                                            {data.total_weight} KG
                                        </p>
                                        <p className="w-full text-sm text-teal_600 p-1">
                                            {(data.total_price / 10).toFixed(2)}
                                        </p>
                                    </div>
                                }
                                <div className="w-full flex border-t border-teal_600 justify-end">
                                    <div className="w-full flex border-t border-teal_600">
                                        <p className="w-[10%] text-sm text-teal_600 p-1 border-r border-teal_600">
                                        </p>
                                        <p className="w-full text-sm text-teal_600 p-1 border-r border-teal_600">
                                        </p>
                                        <p className="w-full text-sm text-red-500 p-1 border-r border-teal_600">
                                            {t("total_value")}
                                        </p>
                                        {(data.addon_secur !== 0 && data.addon_secur !== null) ?
                                            <p className="w-full text-sm text-red-500 p-1">
                                                {(parseFloat(data.transport_price) + parseFloat(data.total_price / 10)).toFixed(2)}
                                            </p> :
                                            <p className="w-full text-sm text-red-500 p-1">
                                                {parseFloat(data.transport_price)}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 flex gap-5 justify-end">
                            <img src={shtamp} className="w-32 h-32" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;
