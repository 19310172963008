import Line from "../../components/InputComponents/Line";
import PanelFlightsCard from "./PanelFlightsCard";
import SelecetCountry from "../../components/SelecetCountry/SelecetCountry";
import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import UserPagination from "../../components/UserPagination/UserPagination";
import { useTranslation } from "react-i18next";

const PanelFlights = () => {
  const { t } = useTranslation();
  //shipment data
  const [shipments, setShipments] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //current country id
  const [currentCountry, setCurrentCountry] = useState("");
  //type
  const [shipmentType, setShipmentType] = useState("0");
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetcha faqs data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(
        `/shipments?country_id=${currentCountry}&type=${
          shipmentType === "0" ? "" : shipmentType
        }` + `&page=${currentPage + 1}&per_page=${12}`
      )
      .then(({ data }) => {
        setTotalAmount(data.total);
        setShipments(data.data);
        setIsLoading(false);
      });
  }, [currentCountry, shipmentType, currentPage]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("flights")}</h2>
      </div>
      <div className="mt-5">
        <SelecetCountry retriveValue={setCurrentCountry} />
      </div>
      <div className="flex gap-2 items-center mt-5">
        <div className="flex gap-2 items-center sm:w-full sm:items-start sm:flex-col">
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="all"
              name="type"
              value={"0"}
              onChange={(e) => {
                setShipmentType(e.target.value);
              }}
              checked={shipmentType === "0"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"all"}
              className="cursor-pointer font-semibold text-left text-sm"
            >
              {t("all_fights")}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="land"
              name="type"
              value={"1"}
              onChange={(e) => {
                setShipmentType(e.target.value);
              }}
              checked={shipmentType === "1"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"land"}
              className="cursor-pointer font-semibold text-left text-sm"
            >
              {t("land")}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="air"
              name="type"
              value={"2"}
              onChange={(e) => {
                setShipmentType(e.target.value);
              }}
              checked={shipmentType === "2"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"air"}
              className="cursor-pointer font-semibold text-left text-sm"
            >
              {t("air")}
            </label>
          </div>
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              id="sea"
              name="type"
              value={"3"}
              onChange={(e) => {
                setShipmentType(e.target.value);
              }}
              checked={shipmentType === "3"}
              className="w-5 h-5 cursor-pointer"
            />
            <label
              htmlFor={"sea"}
              className="cursor-pointer font-semibold text-left text-sm"
            >
              {t("sea")}
            </label>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <UserPagination
          dataLength={totalAmount}
          itemsPerPage={12}
          pageNumberSetter={setCurrentPage}
        >
          {isLoading ? (
            <div className="grid grid-cols-1 gap-2">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className="animate-pulse bg-gray-200 w-full h-20 md:h-32 sm:h-32 rounded-3xl"
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : shipments?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center bg-white rounded-3xl mb-2">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <div className="flex flex-col gap-2 mt-5 mb-5">
              {shipments.map((item) => {
                return <PanelFlightsCard key={item.id} data={item} />;
              })}
            </div>
          )}
        </UserPagination>
      </div>
    </div>
  );
};

export default PanelFlights;
