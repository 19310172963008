import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const FlightsItem = ({
    id,
    item,
    handleDeleteFlights
}) => {
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="w-full flex-col flex py-2 bg-white dark:bg-slate-700 p-2 rounded-lg justify-between">
            <div className="w-full flex flex-col">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">რეისის ნომერი:</p>
                    <p className="text-gray-500 text-[12px]">{item.code}</p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">მიახლოებითი ჩამოსვლის დრო:</p>
                    <p className="text-gray-500 text-[12px]">{item.estemated_date}</p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ჩამოსვლის დრო:</p>
                    <p className="text-gray-500 text-[12px]">{item.arrival_date}</p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ქვეყანა:</p>
                    <p className="text-gray-500 text-[12px]">
                        {item.country_id === 4 && "თურქეთი"}
                        {item.country_id === 3 && "ჩინეთი"}
                    </p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ტიპი:</p>
                    <p className="text-gray-500 text-[12px]">
                        {item.type === 1 && "სატვირთო"}
                        {item.type === 2 && "თვითმფრინავი"}
                        {item.type === 3 && "გემი"}
                    </p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">სტატუსი:</p>
                    <p className="text-gray-500 text-[12px]">
                        {item.status === 1 && "არ დაწყებულა"}
                        {item.status === 2 && "გზაშია"}
                        {item.status === 3 && "ჩამოვიდა"}
                    </p>
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">აღწერა:</p>
                    <p className="text-gray-500 text-[12px]">{item.description}</p>
                </div>
            </div>

            <div className="w-full flex gap-1 mt-2">
                <Link to={`../flights/${id}`} className="w-full rounded-lg bg-violet-400
                 flex gap-2 items-center justify-center p-1 py-3">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">რედაქტირება</p>
                </Link>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-12 rounded-lg bg-red-400
                                flex gap-2 items-center justify-center p-3 cursor-pointer">
                    <BsTrash className="text-white font-bold w-full h-full" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteFlights}
                setShowContinue={setShowContinue}
                id={id}
            />
        </div>
    )
}

export default FlightsItem;
