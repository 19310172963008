import Line from "../../components/InputComponents/Line";
import SelecetCountry from "../../components/SelecetCountry/SelecetCountry";
import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import { BiLoaderAlt } from "react-icons/bi";
import UserPagination from "../../components/UserPagination/UserPagination";
import { useTranslation } from "react-i18next";
import PayFlightsCard from "./PayflightsCard";

const PayFlight = () => {
  const { t } = useTranslation();
  //shipment data
  const [shipments, setShipments] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //updater
  const [update, setUpdate] = useState(null);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetch  data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/declaration?&page=${currentPage + 1}&per_page=${12}`)
      .then(({ data }) => {
        setTotalAmount(data.total);
        setShipments(data.data);
        setIsLoading(false);
      });
  }, [currentPage, update]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">
          {t("declarationPrices")}
        </h2>
      </div>
      <div className="mt-5 flex flex-col gap-2">
        <UserPagination
          dataLength={totalAmount}
          itemsPerPage={12}
          pageNumberSetter={setCurrentPage}
        >
          {isLoading ? (
            <div className="flex flex-col gap-2 mt-5">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className="animate-pulse bg-gray-200 w-full h-16 rounded-lg"
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : shipments?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center bg-white shadow-bs1 rounded-3xl">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <div className="flex flex-col gap-2 mt-5 mb-5">
              {shipments.map((item) => {
                return (
                  <PayFlightsCard
                    key={item.id}
                    data={item}
                    update={setUpdate}
                  />
                );
              })}
            </div>
          )}
        </UserPagination>
      </div>
    </div>
  );
};

export default PayFlight;
