import { useTranslation } from "react-i18next";

import img1 from "../../../images/constants/about1.png";
import img2 from "../../../images/constants/about2.png";
import img3 from "../../../images/constants/about3.jpeg";

const AboutUsSection = () => {
  const { t } = useTranslation();

  return (
    <div
      id="aboutUs"
      className="px-12 md:px-6 md:pb-0 flex flex-col items-center justify-center"
    >
      <div className="flex items-center w-full max-w-[80%] md:max-w-[100%] md:flex-col">
        <div className="flex flex-col gap-3 afMd:w-6/12 md:order-2">
          <h6 className="text-black_900 text-2xl md:text-xl font-bold">
            {t("about_us")}
          </h6>
          <p className="text-sm sm:text-xs font-bold">{t("inner_about_us1")}</p>
          <p className="text-sm sm:text-xs font-bold">{t("inner_about_us2")}</p>
          <p className="text-sm sm:text-xs font-bold">{t("inner_about_us3")}</p>
          <p className="text-sm sm:text-xs font-bold">{t("inner_about_us4")}</p>
        </div>
        <div className="afMd:w-6/12 flex items-center justify-center afMd:pl-10 md:order-1 md:pb-10">
          <div className="grid grid-cols-2 gap-5 md:w-full">
            <div>
              <img src={img1} className="w-full h-full object-contain" />
            </div>
            <div>
              <img src={img3} className="w-full h-full object-fill rounded-[10px]" />
            </div>
            <div className="mt-5">
              <img src={img2} className="w-full h-full object-contain " />
            </div>
            <div
              className="mt-5 bg-teal_600 w-full rounded-[10px] h-full
                         flex items-center justify-center text-white"
            >
              <p className="text-[16px] text-center md:text-base">
                <span className="text-[42px] md:text-[32px]">12 +</span> <br />{" "}
                {t("yearsWithYou")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
