import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput, FileInput } from "../../inputComponents/inputcomp";
import defaultImg from "../../../img/profile.jpg";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { SelectInputValueId } from "../../inputComponents/inputcomp";

//images
import logo from "../../../img/phoneLogo.png";

const InnerAbOfficesPage = () => {
  //get current office id from params
  const { officeId } = useParams();
  //new ad office data
  const [adOfficeData, setAdOfficeData] = useState({});
  //update res
  const [updateRes, setUpdateRes] = useState(null);
  //data from context
  const { countries } = useContext(AdminPrimaryContext);
  //country options
  const [countryOptions, setCountryOptions] = useState([]);
  const [prices, setPrices] = useState({
    "0-5": "",
    "5-10": "",
    "10-45": "",
    "45-100": " ",
    "100-99999": "",
  });

  const handlePrices = (value, inputName) => {
    setPrices((prev) => ({ ...prev, [inputName]: value }));
  };

  //handle new admin
  const handleNewAdOfficeData = (value, inputName) => {
    setAdOfficeData((prev) => ({ ...prev, [inputName]: value }));
  };

  //ad office data from api
  useEffect(() => {
    axiosAdmin
      .get(`/abroadOffice/${officeId}`)
      .then(({ data }) => {
        setAdOfficeData(data);
        try {
          if (JSON.parse(data.prices)) {
            setPrices(JSON.parse(data.prices));
          } else {
            throw new Error();
          }
        } catch {
          setPrices({
            "0-5": "",
            "5-10": "",
            "10-45": "",
            "45-100": " ",
            "100-99999": "",
          });
        }
      })
      .catch((err) => {});
  }, [updateRes]);

  //change country data
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    formData.append("country_id", adOfficeData?.country_id);
    formData.append("type", adOfficeData?.type);
    formData.append("currency", adOfficeData?.currency);
    formData.append("prices", JSON.stringify(prices));

    axiosAdmin
      .post(`/abroadOffice/${officeId}`, formData)
      .then((res) => {
        setUpdateRes(res);
        successToast("მონაცემები წარმატებით განახლდა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  }

  //country
  useEffect(() => {
    const tmp = countries.map((item) => {
      return { name: item.name, value: item.id };
    });
    setCountryOptions(tmp);
  }, [countries]);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          encType="multipart/form-data"
        >
          <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
            <div className="flex items-center justify-between flex-wrap gap-5">
              <div className="flex items-center gap-5 sm:gap-3">
                <div className="w-[100px] h-10">
                  <img src={logo} className="w-full h-full" />
                </div>
                <div>
                  <h2
                    className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]"
                  >
                    საწყობის რედაქტირება
                  </h2>
                </div>
              </div>
              <button
                type="submit"
                className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
              >
                შენახვა
              </button>
            </div>

            <div className="flex gap-2">
              <img
                src={
                  adOfficeData?.img !== ""
                    ? `${process.env.REACT_APP_API_URL}/storage/${adOfficeData?.img}`
                    : defaultImg
                }
                className="w-28 h-28 rounded-lg mt-5"
              />
              <FileInput name={"ფოტოს ატვირთვა"} inputName={"img"} />
            </div>
            <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
              <div className="w-6/12 sm:w-full md:w-full">
                <TextInput
                  name={"საწყობი"}
                  inputName={"country"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.country}
                />
                <TextInput
                  name={"მისამართი1"}
                  inputName={"adress1"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.adress1}
                />
                <TextInput
                  name={"ტელეფონი"}
                  inputName={"phone"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.phone}
                />

                <SelectInputValueId
                  name={"ქვეყანა"}
                  inputName={"country_id"}
                  inputSetter={handleNewAdOfficeData}
                  options={countryOptions}
                  value={adOfficeData?.country_id}
                />
                <TextInput
                  name={
                    adOfficeData?.id === 9 ? "City / Province" : "რეგიონი მხარე"
                  }
                  inputName={"street"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.street}
                />
                <TextInput
                  name={adOfficeData?.id === 9 ? "Town / District" : "ქალაქი"}
                  inputName={"town"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.town}
                />
                <TextInput
                  name={adOfficeData?.id === 9 ? "Neighberhood" : "შტატი"}
                  inputName={"state"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.state}
                />
                <TextInput
                  name={"მინიმალური წონა"}
                  inputName={"min_weight"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.min_weight}
                />
              </div>
              <div className="w-6/12 sm:w-full md:w-full">
                <TextInput
                  name={"გადაფუთვის ფასი"}
                  inputName={"repack_price"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.repack_price}
                />
                <SelectInputValueId
                  name={"ტიპი"}
                  inputName={"type"}
                  inputSetter={handleNewAdOfficeData}
                  options={[
                    {
                      name: "სატვირთო",
                      value: 1,
                    },
                    {
                      name: "თვითმფრინავი",
                      value: 2,
                    },
                    {
                      name: "გემი",
                      value: 3,
                    },
                  ]}
                  value={adOfficeData?.type}
                />
                <TextInput
                  name={"ZIP"}
                  inputName={"zip_code"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.zip_code}
                />
                <TextInput
                  name={"Adress 1 English"}
                  inputName={"adress2"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.adress2}
                />

                <TextInput
                  name={"1 კგ. ფასი"}
                  inputName={"price"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.price}
                  type="number"
                />
                <SelectInputValueId
                  name={"ვალუტა"}
                  inputName={"currency"}
                  inputSetter={handleNewAdOfficeData}
                  options={[
                    {
                      name: "GEL",
                      value: "GEL",
                    },
                    {
                      name: "USD",
                      value: "USD",
                    },
                    {
                      name: "EUR",
                      value: "EUR",
                    },
                  ]}
                  value={adOfficeData?.currency}
                />
                <TextInput
                  name={"ტრანსპორტირების ფასი"}
                  inputName={"delivery_price"}
                  inputSetter={handleNewAdOfficeData}
                  value={adOfficeData?.delivery_price}
                />
              </div>
            </div>

            <div className="mt-5">
              <p>ფასები </p>
              <div className="grid grid-cols-2 gap-5">
                <TextInput
                  name={"0-5"}
                  inputName={"0-5"}
                  inputSetter={handlePrices}
                  value={prices["0-5"]}
                />
                <TextInput
                  name={"5-10"}
                  inputName={"5-10"}
                  inputSetter={handlePrices}
                  value={prices["5-10"]}
                />
                <TextInput
                  name={"10-45"}
                  inputName={"10-45"}
                  inputSetter={handlePrices}
                  value={prices["10-45"]}
                />
                <TextInput
                  name={"45-100"}
                  inputName={"45-100"}
                  inputSetter={handlePrices}
                  value={prices["45-100"]}
                />
                <TextInput
                  name={"100-99999"}
                  inputName={"100-99999"}
                  inputSetter={handlePrices}
                  value={prices["100-99999"]}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default InnerAbOfficesPage;
