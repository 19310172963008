
import { BsX, } from "react-icons/bs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import axiosClient from "../../../api/api";
import { successToast, errorToast } from "../../../adminPanel/components/toastAlerts/ToastAlerts";
import TextInput from "../../../components/InputComponents/TextInput";
import Button from "../../../components/InputComponents/Button";

const ForgetPassword = ({ closePopupSetter }) => {
    const { t } = useTranslation();
    const [payload, setPayload] = useState({});

    const handlePayLoad = (value, inputName) => {
        setPayload(prev => ({ ...prev, [inputName]: value }))
    }

    const handleResetPass = () => {
        axiosClient.post("/resetPass", payload)
            .then(() => {
                successToast(t("password_sent"))
            })
            .catch(() => {
                errorToast(t("email_invalid"))
            })
    }

    return (
        <div className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50">
            <div className="bg-white flex flex-col items-center justify-center gap-5 w-[39%] md:w-[60%] sm:w-[80%] p-7 sm:px-5
            border-2 border-[#F0F0F0] shadow-sm rounded-[30px] relative ">
                <BsX
                    className="absolute top-5 right-5 w-6 h-6 cursor-pointer"
                    onClick={() => { closePopupSetter(false) }}
                />
                <div className="flex flex-col gap-2 items-center justify-center w-full">
                    <h6 className="text-lg text-center font-bold w-auto">
                        {t("forgot_password")}
                    </h6>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <TextInput
                        name={t("email_address")}
                        inputName={"email"}
                        inputSetter={handlePayLoad}
                        style={2}
                    />
                </div>
                <Button
                    className="common-pointer cursor-pointer font-normal
                    text-md text-center text-white_A700 w-full"
                    shape="RoundedBorder11"
                    size="lg"
                    variant="OutlineTeal600"
                    onClick={handleResetPass}
                >
                    {t("send_new_password")}
                </Button>
            </div>
        </div>
    )
}

export default ForgetPassword;