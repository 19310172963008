import { useTranslation } from "react-i18next";
import img from "../../../images/img_image71.png";
import img2 from "../../../images/img_image72.png";

const BannerSection = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex">
      <div className="w-6/12 h-[40vh] relative md:h-[20vh]">
        <div className="w-full h-full">
          <img src={img} alt="#" className="w-full h-full object-cover" />
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(17,17,17,0.7)]">
          <h1 className="text-white text-center text-[32px] md:text-base">
            {t("bannerTextOne").split(" ")[0]} <br />
            {t("bannerTextOne").split(" ")[1]}
          </h1>
        </div>
      </div>
      <div className="w-6/12 h-[40vh] relative md:h-[20vh]">
        <div className="w-full h-full">
          <img src={img2} alt="#" className="w-full h-full object-cover" />
        </div>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,157,104,0.7)]">
          <h1 className="text-white text-center text-[32px] md:text-base">
            {t("bannerTextTwo").split(" ")[0]} <br />
            {t("bannerTextTwo").split(" ")[1]}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
