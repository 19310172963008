import Button from "../../components/InputComponents/Button";
import { BsBoxSeam } from "react-icons/bs";

const BonusPointsCard = ({ data }) => {
  return (
    <div className={`p-4 px-10 md:px-8 sm:px-4 flex gap-[0.5%]`}>
      <div className="flex flex-row gap-2.5 w-[49.5%] md:flex-col sm:flex-col">
        <div>
          <BsBoxSeam className="text-2xl text-teal_600" />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-teal_600 text-sm md:text-xs font-semibold">
            {data.pack_id}
          </p>
          <p className="text-gray-500 text-xs">
            {data.created_at.split("T")[0]}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-end w-[49.5%]">
        <Button
          className="cursor-pointer font-normal min-w-[96px]
                     text-sm text-center text-white_A700 w-auto"
          shape="RoundedBorder8"
          size="md"
          variant={`${
            data.description === "payed with points"
              ? "OutlineRed500"
              : "OutlineTeal600_4"
          }`}
        >
          {data.description === "payed with points" ? "-" : "+"}{" "}
          {parseFloat(data?.action?.split("=")[1]).toFixed(2)}
        </Button>
      </div>
    </div>
  );
};

export default BonusPointsCard;
