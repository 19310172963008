import React from 'react'
import { useEffect, useState } from 'react'
import axiosAdmin from '../../../../api/apiAdmin'
import PaginatedItems from '../../Pagination/Pagination'
import { successToast, errorToast } from '../../toastAlerts/ToastAlerts'
import { ToastContainer } from 'react-toastify'
import SupportCard from './SupportCard'

const Support = () => {
    //messeges 
    const [supportData, setSupportData] = useState([]);
    //data amount
    const [total, setTotal] = useState(0)
    //current page
    const [currentPage, setCurrentPage] = useState(0);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);

    useEffect(() => {
        axiosAdmin.get(`/message?type=3&per_page=12&page=${currentPage + 1}`)
            .then(({ data }) => {
                setSupportData(data.data);
                setTotal(data.total)
            })
    }, [difResponses])

    //delete massage
    const handleDelete = async (id) => {
        try {
            const res = await axiosAdmin.delete(`/message/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("მესიჯი წაიშალა!");
            }
        } catch (error) {
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
        min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div>
                <PaginatedItems
                    pageNumberSetter={setCurrentPage}
                    itemsPerPage={12}
                    dataLength={total}
                >
                    <div className="w-full flex flex-col gap-2 mb-5">
                        {supportData.map((item) => {
                            return (
                                <SupportCard
                                    key={item.id}
                                    item={item}
                                    handleDelete={handleDelete}
                                />
                            )
                        })}
                    </div>
                </PaginatedItems>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default Support