import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";

//images
import logo from '../../../img/phoneLogo.png';

const InnerFilialPage = () => {
    //get current user id 
    const { filialId } = useParams();
    //new filial data
    const [filialData, setFilialData] = useState({});
    //error
    const [error, setError] = useState(null);

    //handle new filial
    const handleNewFilialData = (value, inputName) => {
        setFilialData(prev => ({ ...prev, [inputName]: value }))
    }

    //update new filial 
    const handleEditFilial = async () => {
        try {
            const res = await axiosAdmin.put(`/offices/${filialId}`, filialData);
            if (res) {
                successToast("მონაცემები წარმატებით განახლდა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //filial data from api
    useEffect(() => {
        try {
            axiosAdmin.get(`/offices/${filialId}`)
                .then(({ data }) => {
                    setFilialData(data);
                });
        } catch (error) {
            setError(error);
        }
    }, []);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="sm:px-0">
                <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                    <div className="flex items-center justify-between flex-wrap gap-5">
                        <div className="flex items-center gap-5 sm:gap-3">
                            <div className="w-[100px] h-10">
                                <img src={logo} className="w-full h-full" />
                            </div>
                            <div>
                                <h2 className="text-xl text-slate-600 font-bold dark:text-gray-400
                                sm:text-[16px] smd:text-[16px]">ფილიალის რედაქტირება</h2>
                            </div>
                        </div>
                        <button
                            onClick={handleEditFilial}
                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                            შენახვა
                        </button>
                    </div>
                    <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"სახელი"}
                                inputName={"name"}
                                inputSetter={handleNewFilialData}
                                value={filialData.name}
                            />
                            <TextInput
                                name={"ქალაქი"}
                                inputName={"city"}
                                inputSetter={handleNewFilialData}
                                value={filialData.city}
                            />
                        </div>
                        <div className="w-6/12 sm:w-full md:w-full">
                            <TextInput
                                name={"მისამართი"}
                                inputName={"address"}
                                inputSetter={handleNewFilialData}
                                value={filialData.address}
                            />
                            <TextInput
                                name={"ტელეფონი"}
                                inputName={"phone"}
                                inputSetter={handleNewFilialData}
                                value={filialData.phone}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default InnerFilialPage;