import { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";

const SelectInput = ({
    name,
    options,
    inputName,
    inputSetter = null,
    value,
    style
}) => {
    //toggle variable
    const [isOpen, setIsOpen] = useState(false);
    //current selected value
    const [selectValue, setSelectValue] = useState("");
    //selected value title
    const [selectValueTitle, setSelectValueTitle] = useState("");

    //select handler
    const handleSelect = (index, title) => {
        setSelectValue(index);
        setSelectValueTitle(title);
        inputSetter(index, inputName);
    };

    //toggle handler
    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    //set default value
    useEffect(() => {
        if (value !== undefined) {
            inputSetter(value, inputName);
            options.forEach((element) => {
                if (element.id === value) {
                    setSelectValueTitle(element.title);
                }
            });
        }
    }, [value]);

    return (
        <div className={`flex items-center gap-4 w-full border-2 cursor-pointer
                border-solid h-12 md:max-w-full py-2 rounded-[12px]  text-left
                ${style === 2 ? "border-[#F0F0F0]" : "bg-gray_50 border-gray_300"}`}>
            <div
                onClick={() => {
                    handleOpen();
                }}
                className="w-full p-2 rounded-lg dark:bg-black dark:text-gray-300 relative px-4 dark:border-slate-600"
            >
                <div className="h-[22px] flex justify-between items-center w-full">
                    <p className={`text-sm ${style === 2 ? "font-bold" : "text-gray-500"}`}>
                        {selectValueTitle === "" ? name : selectValueTitle}
                    </p>
                    <BiChevronDown className={`transition-all w-8 h-8 text-teal-600 ${isOpen && "rotate-180"}`} />
                </div>
                {isOpen && (
                    <div
                        className={`w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border-2 z-40 dark:bg-black  dark:border-slate-600
                        ${style === 2 ? "#F0F0F0" : "border-slate-300"}`}
                    >
                        {options.map((value) => {
                            return (
                                <div
                                    onClick={() => {
                                        handleSelect(value.id, value.title);
                                    }}
                                    key={value.id}
                                    className="p-3 w-full hover:bg-slate-300 group"
                                >
                                    <p
                                        className={`text-sm font-bold dark:text-gray-300
                        group-hover:text-slate-800 ${style === 2 ? "font-bold" : "text-gray-500"}`}
                                    >
                                        {value.title}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div >
    );
};

export default SelectInput;
