import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axiosAdmin from "../../../../api/apiAdmin";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { TextInput } from "../../inputComponents/inputcomp";
import logo from "../../../img/phoneLogo.png";

const PopupsUpdate = () => {
  const { popupId } = useParams();
  const [popupData, setPopupData] = useState({});

  const handleNewPopup = (value, inputName) => {
    setPopupData((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleEdit = () => {
    axiosAdmin
      .put(`/popup/${popupId}`, popupData)
      .then(({ data }) => {
        successToast("მონაცემები წარმატებით განახლდა!");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
      });
  };

  useEffect(() => {
    axiosAdmin
      .get(`/popup/${popupId}`)
      .then(({ data }) => {
        setPopupData(data);
      })
      .catch((err) => {});
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="sm:px-0">
        <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <div className="w-[100px] h-10">
              <img src={logo} className="w-full h-full" />
            </div>
            <button
              onClick={handleEdit}
              className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full"
            >
              შენახვა
            </button>
          </div>
          <div className="pt-5 grid grid-cols-1 gap-5">
            <TextInput
              name={"ტექსტი ქართული"}
              inputName={"ge"}
              inputSetter={handleNewPopup}
              value={popupData.ge}
            />
            <TextInput
              name={"ტექსტი ინგლისური"}
              inputName={"en"}
              inputSetter={handleNewPopup}
              value={popupData.en}
            />
            <TextInput
              name={"ტექსტი რუსული"}
              inputName={"ru"}
              inputSetter={handleNewPopup}
              value={popupData.ru}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default PopupsUpdate;
