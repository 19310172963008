import { useTranslation } from "react-i18next";
import FlightsCard from "./FlightsCard";
import { useEffect, useState } from "react";
import axiosClient from "../../../api/api";

const FlightsSection = () => {
  const { t } = useTranslation();
  const [flights, setFlights] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dummyArray = Array.from({ length: 8 });

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`shipments?page=1&per_page=6`)
      .then(({ data }) => {
        setFlights(data.data);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="px-12 md:px-6 w-full flex items-center justify-center">
      <div className="w-full max-w-[80%] md:max-w-[100%]">
        <div className="flex flex-col font-helvetica gap-4 items-center justify-start w-full">
          <h6 className="text-black_900 text-2xl md:text-xl font-bold">
            {t("flights")}
          </h6>
        </div>

        <div className="flex ">
          <div className="px-5 shrink-0 relative afMd:hidden">
            <div className="w-[2px] h-full bg-[#F0F0F0]"></div>
            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full top-[9%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%]  bg-teal_600"
            ></div>
            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full top-[25%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0] "
            ></div>
            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full top-[42%] left-[50%]
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
            ></div>

            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full bottom-[36%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
            ></div>
            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full bottom-[20%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
            ></div>
            <div
              className="cursor-pointer absolute w-8 h-8 rounded-full bottom-[2%] left-[50%]
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
            ></div>
          </div>
          <div className="mt-12 md:mt-6 sm:mt-3 w-full flex md:flex-col md:gap-5">
            <div className="flex flex-col gap-5 w-full">
              {isLoading
                ? dummyArray.map((_, index) => {
                    return (
                      <div
                        className="animate-pulse bg-slate-100 w-full h-20 rounded-xl"
                        key={index}
                      ></div>
                    );
                  })
                : flights.slice(0, 3).map((item) => {
                    return <FlightsCard key={item.id} item={item} />;
                  })}
            </div>
            <div className="px-10 shrink-0 relative md:hidden">
              <div className="w-[2px] h-full bg-[#F0F0F0]"></div>
              <div
                className="cursor-pointer absolute w-8 h-8 rounded-full top-[15%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%] bg-teal_600"
              ></div>
              <div
                className="cursor-pointer absolute w-8 h-8 rounded-full top-[50%] left-[50%] 
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
              ></div>
              <div
                className="cursor-pointer absolute w-8 h-8 rounded-full bottom-[5%] left-[50%]
                    translate-x-[-50%] translate-y-[-50%] bg-[#F0F0F0]"
              ></div>
            </div>
            <div className="flex flex-col gap-5 w-full">
              {isLoading
                ? dummyArray.map((_, index) => {
                    return (
                      <div
                        className="animate-pulse bg-slate-100 w-full h-20 rounded-xl"
                        key={index}
                      ></div>
                    );
                  })
                : flights.slice(3, 6).map((item) => {
                    return <FlightsCard key={item.id} item={item} />;
                  })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightsSection;
