import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { PrimaryContext } from "./MainContext";
import { BiChevronDown } from "react-icons/bi";

const LanguageSwitcher = () => {
  const { setLanguage, language } = useContext(PrimaryContext);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(language);
  const languages = ["ge", "en"];

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    setLanguage(value);
  }, [value]);

  return (
    <div className="relative border-2 border-teal_600 rounded-[30px] h-[32px] flex items-center md:h-[28px]">
      {languages.map((item, id) => {
        return (
          <div
            key={id}
            onClick={() => {
              handleShow();
              setValue(item);
            }}
            className={`p-2 rounded-full ${value === item && "bg-teal_600"} md:p-[6px]`}
          >
            <p
              className={`font-bold text-center uppercase cursor-pointer text-[12px] 
              ${
                value === item && "text-white"
              }`}
            >
              {item}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default LanguageSwitcher;
