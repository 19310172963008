import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { BsDoorOpen, BsList } from "react-icons/bs";
import { BiChevronLeft } from "react-icons/bi";
import logo from "../../images/mglLogo.png";
import LanguageSwitcher from "../MainContext/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../MainContext/MainContext";

const HeaderPhone = ({ menuRefs }) => {
  //translation
  const { t } = useTranslation();
  //location
  const location = useLocation();
  // toggling menu
  const [toggleMenu, setToggleMenu] = useState(false);

  const navigate = useNavigate();

  const { setUser, setToken, setIsAuthorized, isAuthorized } =
    useContext(PrimaryContext);

  //currentPage Handler
  const handleCurrentPage = () => {
    setToggleMenu((prev) => !prev);
  };

  const handleScrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const handleLogout = () => {
    axiosClient.get("/logout").then((res) => {
      localStorage.removeItem("MGL_TOKEN");
      setToken("");
      setUser({});
      setIsAuthorized(false);
      navigate("/");
    });
  };

  return (
    <div className="afMd:hidden">
      <div className="w-full p-5 justify-between items-center flex bg-white border-b-2 border-[#F0F0F0]">
        <Link
          to={"/"}
          onClick={() => {
            setToggleMenu(false);
          }}
        >
          <img src={logo} alt="logo" className="w-[100px]" />
        </Link>
        <div className="flex items-center gap-2">
          <LanguageSwitcher />

          {isAuthorized && (
            <div
              className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
              onClick={handleLogout}
            >
              <BsDoorOpen className="w-full h-full text-white" />
            </div>
          )}
          <div
            className="w-[30px] h-[30px] bg-teal_600 rounded-full p-[6px] cursor-pointer"
            onClick={handleCurrentPage}
          >
            <BsList className="w-full h-full text-white" />
          </div>
        </div>
      </div>
      <div
        className={`p-5 fixed top-0 left-0 w-screen h-screen transition-all ease-linear duration-300
            overflow-auto z-50 
            ${toggleMenu ? "translate-x-[0%]" : "translate-x-[-100%]"}`}
      >
        <div className="rounded-[30px] w-full bg-white border-2 border-[#F0F0F0] border-solid shadow-bs1 md:w-6/12 sm:w-full">
          <div className="relative w-full flex flex-col">
            <div className="flex items-center justify-between w-full p-4 border-b-2 border-[#F0F0F0]">
              <img src={logo} alt="#" className="w-[80px]" />
              <div
                className="w-8 h-8 bg-teal_600 p-1 rounded-full cursor-pointer"
                onClick={handleCurrentPage}
              >
                <BiChevronLeft className="w-full h-full text-white" />
              </div>
            </div>
            <div className="p-4 flex flex-col justify-start w-full gap-5">
              <Link to={"/"} onClick={handleCurrentPage}>
                <h6 className="text-sm sm:text-xs">{t("home")}</h6>
              </Link>
              {location.pathname === "/" && (
                <h6
                  onClick={() => {
                    handleScrollToSection(menuRefs.aboutUs);
                    handleCurrentPage();
                  }}
                  className="text-sm sm:text-xs cursor-pointer"
                >
                  {t("about_us")}
                </h6>
              )}
              <Link to={"/customs-information"} onClick={handleCurrentPage}>
                <h6 className="text-sm sm:text-xs">
                  {t("customs_regulations")}
                </h6>
              </Link>
              <Link to={"/forbidden-parcels"} onClick={handleCurrentPage}>
                <h6 className="text-sm sm:text-xs"> {t("denied_items")}</h6>
              </Link>
              <h6
                onClick={() => {
                  handleScrollToSection(menuRefs.contact);
                  handleCurrentPage();
                }}
                className="text-sm sm:text-xs cursor-pointer"
              >
                {t("contact")}
              </h6>
              <Link to={"/blogs"} onClick={handleCurrentPage}>
                <h6 className="text-sm sm:text-xs">{t("blogs")}</h6>
              </Link>
              {/* <Link to={"/instructions"} onClick={handleCurrentPage}>
                <h6 className="text-sm sm:text-xs">{t("instructions")}</h6>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPhone;
