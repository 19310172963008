import PropTypes from "prop-types";

const shapes = {
    icbRoundedBorder8: "rounded-lg",
    icbCircleBorder17: "rounded-[17px]",
    icbRoundedBorder21: "rounded-[21px]",
    icbRoundedBorder11: "rounded-[11px]",
    icbCircleBorder14: "rounded-[14px]",
    RoundedBorder11: "rounded-[11px]",
    RoundedBorder12: "rounded-[12px]",
    RoundedBorder14: "rounded-[14px]",
    RoundedBorder27: "rounded-[27px]",
    CircleBorder37: "rounded-[37px]",
    CircleBorder42: "rounded-[42px]",
    RoundedBorder8: "rounded-lg",
    RoundedBorder30: "rounded-[30px]",
    roundedFull: "rounded-full",
    CustomBorderLR14:
        "rounded-bl-none rounded-br-[14px] rounded-tl-none rounded-tr-[14px]",
};
const variants = {
    icbOutlineTeal600_1: "bg-teal_600_b2 border-2 border-solid border-teal_600",
    icbOutlineTeal600: "bg-white_A700 border-2 border-solid border-teal_600",
    icbOutlineCyan900: "bg-cyan_900 border-2 border-cyan_900 border-solid",
    icbFillRed900: "bg-red_900",
    icbOutlineGray600: "outline outline-[0.5px] outline-gray_600",
    icbOutlineTeal600_2: "outline outline-[0.5px] outline-teal_600",
    icbFillWhiteA700: "bg-white_A700",
    OutlineTeal600:
        "bg-teal_600_b2 border-2 border-solid border-teal_600 text-white_A700",
    FillGray90071: "bg-gray_900_71 text-white_A700",
    OutlineLightgreen200:
        "border border-light_green_200 border-solid text-light_green_200",
    OutlineLightgreen200_1:
        "bg-light_green_200 border border-light_green_200 border-solid text-cyan_900",
    OutlineTeal600_1:
        "bg-white_A700 border border-solid border-teal_600 text-teal_600",
    OutlineWhiteA700: "border border-solid border-white_A700 text-white_A700",
    OutlineTeal600_2:
        "bg-teal_600_b2 border border-solid border-teal_600 text-white_A700",
    FillTeal600b2: "bg-teal_600_b2 text-white_A700",
    OutlineTeal600_3: "border border-solid border-teal_600 text-teal_600",
    OutlineWhiteA700_1:
        "bg-cyan_900 border border-solid border-white_A700 shadow-bs text-white_A700",
    OutlineCyan900:
        "bg-cyan_900 border border-cyan_900 border-solid shadow-bs text-white_A700",
    OutlineRed500:
        "bg-red_500_cc border-2 border-red_500 border-solid text-white_A700",
    OutlineTeal600_4:
        "bg-teal_600_cc border-2 border-solid border-teal_600 text-white_A700",
    OutlineCyan900_1: "border-2 border-cyan_900 border-solid text-cyan_900",
    OutlineTeal600_5:
        "bg-teal_600 border-2 border-solid border-teal_600 text-white_A700",
    OutlineGray600: "border-2 border-gray_600 border-solid text-gray_600",
    FillTeal600: "bg-teal_600 text-white_A700",
    FillGray300: "bg-gray_300 text-white_A700",
};
const sizes = {
    smIcn: "p-0.5",
    mdIcn: "p-[5px]",
    lgIcn: "p-[13px]",
    sm: "p-1",
    md: "p-[6px]",
    lg: "p-[10px]",
    xl: "p-4",
    "2xl": "p-[21px] sm:px-5",
    "3xl": "p-[27px] sm:px-5",
    unord: "px-4 py-1"
};

const Button = ({
    children,
    className = "",
    leftIcon,
    rightIcon,
    shape,
    variant,
    size,
    ...restProps
}) => {
    return (
        <button
            className={`${className} ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""
                } ${(variant && variants[variant]) || ""}`}
            {...restProps}
        >
            {!!leftIcon && leftIcon}
            {children}
            {!!rightIcon && rightIcon}
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    shape: PropTypes.oneOf([
        "icbRoundedBorder8",
        "icbCircleBorder17",
        "icbRoundedBorder21",
        "icbRoundedBorder11",
        "icbCircleBorder14",
        "RoundedBorder11",
        "RoundedBorder14",
        "RoundedBorder27",
        "CircleBorder37",
        "CircleBorder42",
        "RoundedBorder8",
        "RoundedBorder30",
        "CustomBorderLR14",
    ]),
    variant: PropTypes.oneOf([
        "icbOutlineTeal600_1",
        "icbOutlineTeal600",
        "icbOutlineCyan900",
        "icbFillRed900",
        "icbOutlineGray600",
        "icbOutlineTeal600_2",
        "icbFillWhiteA700",
        "OutlineTeal600",
        "FillGray90071",
        "OutlineLightgreen200",
        "OutlineLightgreen200_1",
        "OutlineTeal600_1",
        "OutlineWhiteA700",
        "OutlineTeal600_2",
        "FillTeal600b2",
        "OutlineTeal600_3",
        "OutlineWhiteA700_1",
        "OutlineCyan900",
        "OutlineRed500",
        "OutlineTeal600_4",
        "OutlineCyan900_1",
        "OutlineTeal600_5",
        "OutlineGray600",
        "FillTeal600",
        "FillGray300",
    ]),
    size: PropTypes.oneOf([
        "smIcn",
        "mdIcn",
        "lgIcn",
        "sm",
        "md",
        "lg",
        "xl",
        "2xl",
        "3xl",
        "unord"
    ]),
};

Button.defaultProps = { className: "", shape: "", variant: "", size: "" };

export default Button;
