import { forwardRef, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContinueComponent from "../../toastAlerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";
import { PrimaryContext } from "../../../../components/MainContext/MainContext";

const UsCard = forwardRef(({
    data,
    handleAccessUser,
    handleDeleteUser }, ref) => {
    //error
    const [error, setError] = useState(0);
    //user type
    const [userType, setUserType] = useState(0);
    //show contnue
    const [showContinue, setShowContinue] = useState(false);

    //set user type on load
    useEffect(() => {
        setUserType(data.user_type);
    }, [data]);

    const body = (
        <>
            <div className="w-full grid grid-cols-2 ">
                <div className="flex flex-col gap-2 w-full sm:gap-0 md:gap-0">
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">ტიპი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.company_id === null ? "ფიზიკური პირი" : "იურიდიულიი პირი"}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">სახელი & გვარი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.company_id === null ? `${data.fname} ${data.lname}` : `${data.cname} ${data.cname_eng}`}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">ტელეფონი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.phone}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">ელ.ფოსტა:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.email}
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-full sm:gap-0 md:gap-0">
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">ოთახის ნომერი:</p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.id}
                        </p>
                    </div>
                    <div className="flex flex-col">
                        <p className="text-[12px] text-[#989ba5]">
                            {data.company_id === null ? "პირადი ნომერი:" : "საიდენთიფიკაციო კოდი:"}
                        </p>
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {data.company_id === null ? data.person_id : data.company_id}
                        </p>
                    </div>
                    <div className="flex gap-1">
                        <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
                            {userType === 1 ? data.id_num : (userType === 2 && data.ceo_id_num)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex gap-2 w-full justify-between">
                <div className="flex gap-1 items-center">
                    <Link to={`../users/${data.id}`} className="rounded-lg bg-violet-400
                    flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-full mt-3">
                        <PencilSquare className="text-white font-bold" />
                        <p className="text-white text-sm font-bold">რედაქტირება</p>
                    </Link>
                    <div onClick={() => { handleAccessUser(data.id) }}
                        className="rounded-lg bg-green-400 cursor-pointer
                 flex gap-2 items-center justify-center p-1 py-3 md:w-[49%] sm:w-full mt-3">
                        <PencilSquare className="text-white font-bold" />
                        <p
                            className="text-white text-sm font-bold">შესვლა</p>
                    </div>
                </div>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-16 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer mt-3">
                    <BsTrash className="text-white" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteUser}
                setShowContinue={setShowContinue}
                id={data.id}
            />
        </>
    )

    const content = ref
        ? <div className="bg-white rounded-xl p-5 w-full relative
        flex items-center justify-center gap-3 dark:bg-slate-700 flex-col"
            ref={ref}>
            {body}
        </div>
        : <div className="bg-white rounded-xl p-5 w-full relative
    flex items-center justify-center gap-3 dark:bg-slate-700 flex-col">
            {body}
        </div>

    return content
})

export default UsCard;
