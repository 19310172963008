import { BsAirplane, BsTruck } from "react-icons/bs";
import { FaShip } from "react-icons/fa";

export default function TurkeyContent({
  item,
  user,
  currentCountryName,
  currentCountry,
}) {
  return (
    <>
      {item?.country !== null && item?.country !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center ">
          {item?.type === 1 && <BsTruck className="text-2xl text-teal_600" />}
          {item?.type === 2 && (
            <BsAirplane className="text-2xl text-teal_600" />
          )}
          {item?.type === 3 && <FaShip className="text-2xl text-teal_600" />}
          <div className="flex gap-2 items-center sm:flex-col sm:items-start">
            <p className="text-gray-500 text-sm md:text-xs font-semibold">
              Warehouse:
            </p>
            <p className="font-semibold text-sm md:text-xs">{item?.country}</p>
          </div>
        </div>
      )}
      <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
        <p className="text-gray-500 text-sm md:text-xs font-semibold">
          Name / Surname :
        </p>
        <p className="font-semibold text-sm md:text-xs">
          {user?.fname_eng} {user?.lname_eng}
        </p>
      </div>
      {item?.adress1 !== null && item?.adress1 !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            Address 1:
          </p>
          <p className="font-semibold text-sm md:text-xs">
            MGL{user?.id} {item?.adress1} MGL{user?.id}
          </p>
        </div>
      )}
      {item?.adress2 !== null && item?.adress2 !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            Adress 1 English
          </p>
          <p className="font-semibold text-sm md:text-xs">
            MGL{user?.id} {item?.adress2} MGL{user?.id}
          </p>
        </div>
      )}
      <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
        <p className="text-gray-500 text-sm md:text-xs font-semibold">
          Address 2:
        </p>
        <p className="font-semibold text-sm md:text-xs">MGL{user?.id}</p>
      </div>
      {currentCountryName !== null && currentCountryName !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            Country:
          </p>
          <p className="font-semibold text-sm md:text-xs">
            {currentCountryName === "თურქეთი" && "Turkey"}
            {currentCountryName === "ჩინეთი" && "China"}
          </p>
        </div>
      )}
      {item?.street !== null && item?.street !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            {currentCountry === 4 ? "Sity / Province:" : "Region / District:"}
          </p>
          <p className="font-semibold text-sm md:text-xs">{item?.street}</p>
        </div>
      )}
      {item?.town !== null && item?.town !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            {currentCountry === 4 ? "Town / District:" : "Town / City:"}
          </p>
          <p className="font-semibold text-sm md:text-xs">{item?.town}</p>
        </div>
      )}
      {item?.state !== null && item?.state !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            {currentCountry === 4 ? "Neighborhood:" : "State / Province:"}
          </p>
          <p className="font-semibold text-sm md:text-xs">{item?.state}</p>
        </div>
      )}
      {item?.zip_code !== null && item?.zip_code !== "" && (
        <div className="p-4 border-b border-gray-200 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            Postal Code:
          </p>
          <p className="font-semibold text-sm md:text-xs">{item?.zip_code}</p>
        </div>
      )}
      {item?.phone !== null && item?.phone !== "" && (
        <div className="p-4 flex gap-2 items-center sm:flex-col sm:items-start">
          <p className="text-gray-500 text-sm md:text-xs font-semibold">
            Phone:
          </p>
          <p className="font-semibold text-sm md:text-xs">{item?.phone}</p>
        </div>
      )}
    </>
  );
}
