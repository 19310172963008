import Line from "../../components/InputComponents/Line";
import { useContext, useState } from "react";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import axiosClient from "../../api/api";
import NameSurnameChange from "./NameSurnameChange/NameSurnameChange";
import NameSurnameChangeChanged from "./NameSurnameChange/NameSurnameChangeChanged";
import NewId from "./NewId/NewId";
import IdChanged from "./NewId/IdChanged";
import NewPassword from "./NewPassword/NewPassword";
import PasswordChanged from "./NewPassword/PasswordChanged";
import NewEmail from "./NewEmailPopups/NewEmail";

import { BsPersonCircle, BsPencil } from "react-icons/bs";
import EmailChanged from "./NewEmailPopups/EmailChanged";
import NewPhone from "./NewPhonePopup/NewPhone";
import PhoneChanged from "./NewPhonePopup/PhoneChanged";
import { useTranslation } from "react-i18next";

const Settings = () => {
  //translation
  const { t } = useTranslation();
  //data from context
  const { user, setUpdateUser } = useContext(PrimaryContext);
  //popups
  const [toggleNameSurname, setNameSurname] = useState(false);
  const [toggleNameSurnameChanged, setToggleNameSurnameChanged] =
    useState(false);
  const [toggleIdPopup, setIdPopup] = useState(false);
  const [toggleIdPopupChanged, setToggleIdPopupChanged] = useState(false);
  const [togglePasswordPopup, setPasswordPopup] = useState(false);
  const [togglePasswordPopupChanged, setTogglePasswordPopupChanged] =
    useState(false);
  const [toggleEmailPopup, setEmailPopup] = useState(false);
  const [toggleEmailPopupChanged, setToggleEmailPopupChanged] = useState(false);
  const [togglePhonePopup, setPhonePopup] = useState(false);
  const [togglePhonePopupChanged, setTogglePhonePopupChanged] = useState(false);

  //update data
  const handleUpdate = (payload) => {
    axiosClient.post("/updateUser", payload).then((res) => {
      setNameSurname(false);
      setUpdateUser(res);
      setToggleNameSurnameChanged(true);
    });
  };

  const handleUpdateId = (payload) => {
    axiosClient.post("/updateUser", payload).then((res) => {
      setIdPopup(false);
      setUpdateUser(res);
      setToggleIdPopupChanged(true);
    });
  };

  const handleUpdatePassword = (payload) => {
    axiosClient.post("/updateUser", payload).then((res) => {
      setPasswordPopup(false);
      setUpdateUser(res);
      setTogglePasswordPopupChanged(true);
    });
  };

  const handleUpdateEmail = (payload) => {
    axiosClient.post("/updateUser", payload).then((res) => {
      setEmailPopup(false);
      setUpdateUser(res);
      setToggleEmailPopupChanged(true);
    });
  };

  const handleUpdatePhone = (payload) => {
    axiosClient.post("/updateUser", payload).then((res) => {
      setPhonePopup(false);
      setUpdateUser(res);
      setTogglePhonePopupChanged(true);
    });
  };

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("settings")}</h2>
      </div>
      <div className="shadow-bs1 rounded-[30px] sm:rounded-lg mt-5 bg-white">
        <div className="flex flex-row gap-2.5 items-center justify-start w-full p-5 sm:justify-center">
          <BsPersonCircle className="w-10 h-10 text-teal_600" />
          <div className="flex flex-col items-start justify-start w-auto">
            <p className="font-semibold text-base md:text-sm">
              {user.company_id === null ? (
                <>
                  {user.fname_eng} {user.lname_eng}
                </>
              ) : (
                <>{user.cname_eng}</>
              )}
            </p>
            <p className="text-teal_600 text-sm md:text-xs font-semibold">
              ID: MGL{user.id}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2.5 justify-start w-full p-5 border-t border-gray-200">
          <div className="flex justify-between items-center w-6/12 sm:w-full">
            <div className="flex gap-1">
              <p className="text-gray-500 text-sm md:text-xs font-bold">
                {t("name")}/{t("surname")}
              </p>
              <p className="font-semibold text-sm md:text-xs ">
                {user.company_id === null ? (
                  <>
                    {user.fname_eng} {user.lname_eng}
                  </>
                ) : (
                  <>{user.cname_eng}</>
                )}
              </p>
            </div>
            <div
              className="w-8 h-8 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600 cursor-pointer"
              onClick={() => {
                setNameSurname(true);
              }}
            >
              <BsPencil className="w-full h-full text-white" />
            </div>
          </div>
          <div className="flex justify-between items-center w-6/12 sm:w-full">
            <div className="flex gap-1">
              <p className="text-gray-500 text-sm md:text-xs font-bold">
                {t("email_address")}:
              </p>
              <p className="font-semibold text-sm md:text-xs">{user.email}</p>
            </div>
            <div
              className="w-8 h-8 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600 cursor-pointer"
              onClick={() => {
                setEmailPopup(true);
              }}
            >
              <BsPencil className="w-full h-full text-white" />
            </div>
          </div>
          <div className="flex justify-between items-center w-6/12 sm:w-full">
            <div className="flex gap-1">
              <p className="text-gray-500 text-sm md:text-xs font-bold">
                {user.company_id === null ? t("personal_id") : t("company_id")}
              </p>
              <p className="font-semibold text-sm md:text-xs">
                {user.company_id === null ? user.person_id : user.company_id}
              </p>
            </div>
            <div
              className="w-8 h-8 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600 cursor-pointer"
              onClick={() => {
                setIdPopup(true);
              }}
            >
              <BsPencil className="w-full h-full text-white" />
            </div>
          </div>
          <div className="flex justify-between items-center w-6/12 sm:w-full">
            <div className="flex gap-1">
              <p className="text-gray-500 text-sm md:text-xs font-bold">
                {t("phone")}
              </p>
              <p className="font-semibold text-sm md:text-xs">{user.phone}</p>
            </div>
            <div
              className="w-8 h-8 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600 cursor-pointer"
              onClick={() => {
                setPhonePopup(true);
              }}
            >
              <BsPencil className="w-full h-full text-white" />
            </div>
          </div>
          <div className="flex justify-between items-center w-6/12 sm:w-full">
            <div className="flex gap-1">
              <p className="text-gray-500 text-sm md:text-xs font-bold">
                {t("password")}:
              </p>
              <p className="font-semibold text-sm md:text-xs">............</p>
            </div>
            <div
              className="w-8 h-8 bg-teal_600_b2 rounded-lg p-1 border-2 border-teal_600 cursor-pointer"
              onClick={() => {
                setPasswordPopup(true);
              }}
            >
              <BsPencil className="w-full h-full text-white" />
            </div>
          </div>
        </div>
      </div>
      {toggleNameSurname && (
        <NameSurnameChange
          closePopupSetter={setNameSurname}
          handleUpdate={handleUpdate}
        />
      )}
      {toggleNameSurnameChanged && (
        <NameSurnameChangeChanged
          closePopupSetter={setToggleNameSurnameChanged}
        />
      )}
      {toggleIdPopup && (
        <NewId closePopupSetter={setIdPopup} handleUpdate={handleUpdateId} />
      )}
      {toggleIdPopupChanged && (
        <IdChanged closePopupSetter={setToggleIdPopupChanged} />
      )}
      {togglePasswordPopup && (
        <NewPassword
          closePopupSetter={setPasswordPopup}
          handleUpdate={handleUpdatePassword}
        />
      )}
      {togglePasswordPopupChanged && (
        <PasswordChanged closePopupSetter={setTogglePasswordPopupChanged} />
      )}
      {toggleEmailPopup && (
        <NewEmail
          closePopupSetter={setEmailPopup}
          handleUpdate={handleUpdateEmail}
        />
      )}
      {toggleEmailPopupChanged && (
        <EmailChanged closePopupSetter={setToggleEmailPopupChanged} />
      )}
      {togglePhonePopup && (
        <NewPhone
          closePopupSetter={setPhonePopup}
          handleUpdate={handleUpdatePhone}
        />
      )}
      {togglePhonePopupChanged && (
        <PhoneChanged closePopupSetter={setTogglePhonePopupChanged} />
      )}
    </div>
  );
};

export default Settings;
