import { useState, useEffect } from "react";

const TextArea = ({
  name,
  placeholder = null,
  inputName,
  inputSetter = null,
  mFill,
  value,
}) => {
  //current value variable
  const [currentValue, setCurrentValue] = useState("");
  //we save value of focus state i this variable
  const [isFocused, setIsFocused] = useState(false);
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");

  //value change handler for input
  const handleValueChange = (e) => {
    setCurrentValue(e.target.value);
  };

  //when current value changes passed function
  //will change specific object based value
  useEffect(() => {
    if (inputSetter) {
      inputSetter(currentValue, inputName);
    }
  }, [currentValue]);

  //whenever filed is reuired and user does not
  //filled it
  useEffect(() => {
    if (currentValue !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, currentValue]);

  //whenever filed is reuired and user does not
  //filled it
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      setCurrentValue("");
    }
  }, [value]);

  return (
    <div className="w-full">
      <p className={`text-left w-auto text-xs mb-1 font-semibold`}>{name}</p>
      <div
        className="flex items-center gap-4 w-full bg-gray_50 border-2 border-gray_300 
                border-solid font-normal md:max-w-full px-4 py-2 rounded-[12px] text-gray_600 text-left"
      >
        <div className="w-full relative">
          <textarea
            className="bg-gray_50 w-full outline-none h-52"
            placeholder={placeholder}
            onChange={(e) => {
              handleValueChange(e);
            }}
            name={inputName}
          />
        </div>
      </div>
    </div>
  );
};

export default TextArea;
