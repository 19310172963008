import { useEffect, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import axiosAdmin from "../../../../api/apiAdmin";
import { PrimaryContext } from "../../../../components/MainContext/MainContext";
import UsCard from "./usCard";
import PaginatedItems from "../../Pagination/Pagination";

import { BsSearch } from "react-icons/bs";
import { SelectInputValueId } from "../../inputComponents/inputcomp";

const UsLayout = () => {
    //get users data from api
    const [usersData, setUsersData] = useState([]);
    //search value
    const [searchValue, setSearchValue] = useState("");
    //errors
    const [error, setError] = useState(null);
    //update data
    const [updateDataRes, setUpdateDataRes] = useState(null);
    //data from context 
    const { setIsAuthorized, setUser, setToken } = useContext(PrimaryContext);
    //current page
    const [currentPage, setCurrentPage] = useState(0);
    //total amount 
    const [total, setTotal] = useState(0);
    //filter
    const [filterStatus, setFilterStatus] = useState(4);

    //handle search value change
    const handleSearchValue = (e) => {
        setSearchValue(e.target.value)
    }

    //data fetch asynchronous function
    const fetchData = async () => {
        try {
            const res = await axiosAdmin.get(`/users?page=${currentPage + 1}&per_page=30&user_type=${filterStatus}`);
            if (res) {
                setUsersData(res.data.data);
                setTotal(res.data.total);
            }
        } catch (error) {
            setError(error);
        }
    }

    // searching user
    const handleSearch = async () => {
        try {
            const res = await axiosAdmin.get(`/users/search?searchValue=${searchValue}`);
            if (res) {
                setUsersData([res.data]);
            }
        } catch (error) {
            setError(error);
        }
    }

    // access user
    const handleAccessUser = async (id) => {
        try {
            const res = await axiosAdmin.get(`/user/${id}`);
            if (res) {
                setUser(res.data.user);
                setToken(res.data.token);
                window.open('https://mglparcel.com', '_blank');
            }
        } catch (error) { }
    }

    // delete user
    const handleDeleteUser = async (id) => {
        try {
            const res = await axiosAdmin.delete(`/users/${id}`);
            setUpdateDataRes(res);
            successToast("მონაცემი წარმტებით წაიშალა!");
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა!");
        }
    }

    //users data from api
    useEffect(() => {
        if (searchValue === "") {
            fetchData();
        }
    }, [searchValue, currentPage, updateDataRes, filterStatus]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
            min-h-screen p-10 sm:p-5 pb-24 sm:pb-24">
            <div className="w-full">
                <div className="w-full flex justify-between sm:flex-wrap sm:gap-3">
                    <div className="w-full flex justify-between gap-1 sm:w-full">
                        <div className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full bg-white">
                            <input
                                type="search"
                                placeholder="მომხმარებლის ID, ელ-ფოსტა, ტელეფონი"
                                className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
                                onChange={(e) => { handleSearchValue(e) }}
                                value={searchValue}
                            />
                        </div>
                        <div
                            onClick={handleSearch}
                            className="w-20 rounded-lg bg-violet-400
                 flex items-center justify-center p-1 py-3 cursor-pointer">
                            <BsSearch className="text-white font-bold" />
                        </div>
                    </div>
                </div>
                <div className="mt-2 flex">
                    <div className="w-52">
                        <SelectInputValueId
                            options={[
                                {
                                    name: "ფიზიკური",
                                    value: 1
                                },
                                {
                                    name: "იურიდიული",
                                    value: 2
                                },
                                {
                                    name: "ონლაინ მაღაზია",
                                    value: 3
                                },
                                {
                                    name: "ყველა",
                                    value: 4
                                },
                            ]}
                            inputSetter={(value, _) => { setFilterStatus(value) }}
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <PaginatedItems
                        pageNumberSetter={setCurrentPage}
                        dataLength={total}
                    >
                        <div className="my-5 grid grid-cols-2 sm:grid-cols-1 gap-5">
                            {usersData.map((obj) => {
                                return (
                                    <UsCard
                                        key={obj.id}
                                        data={obj}
                                        handleAccessUser={handleAccessUser}
                                        handleDeleteUser={handleDeleteUser}
                                    />
                                )
                            })}
                        </div>
                    </PaginatedItems>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default UsLayout;