import { useState, useRef, useEffect, useContext } from "react";
import { PrimaryContext } from "../../MainContext/MainContext";
import { FaCaretDown } from "react-icons/fa";

const FaqCard = ({ item }) => {
  //toggler
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const { language } = useContext(PrimaryContext);

  //toggler handler
  const handleIOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0";
    }
  }, [isOpen]);

  return (
    <div className="relative w-[100%] transition-all">
      <div
        className={`w-[100%] bg-[#F0F0F0] rounded-[30px] p-5 sm:px-5 sm:py-3`}
      >
        <div
          onClick={handleIOpen}
          className="flex flex-row gap-[24px] items-center justify-between w-[100%] cursor-pointer"
        >
          <div className="flex w-full">
            <h6 className="text-sm font-bold">
              {language === "ge" && item.title}
              {language === "en" && item.titleen}
            </h6>
          </div>
          <FaCaretDown
            className={`font-semibold text-3xl transition-all ${
              isOpen && "rotate-180"
            }`}
          />
        </div>
        <div
          ref={contentRef}
          className={`transition-all overflow-hidden max-h-0`}
        >
          <div
            className="flex md:flex-col flex-row gap-6 items-center justify-start w-full mt-5 
                 h-full"
          >
            <p className="text-gray-500 text-sm md:text-xs border-l-4 border-[#009D68] pl-5">
              {language === "ge" &&
                item.text.split("&").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && (
                        <>
                          <br /> <br />
                        </>
                      )}
                      {item}
                    </span>
                  );
                })}
              {language === "en" &&
                item.texten.split("&").map((item, index) => {
                  return (
                    <span key={index}>
                      {index !== 0 && (
                        <>
                          <br /> <br />
                        </>
                      )}
                      {item}
                    </span>
                  );
                })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqCard;
