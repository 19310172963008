import { useState } from "react";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import DeclarationInv from "../../../../components/Invoice/DeclarationInv";

export default function DeclarationServiceCard({ item, deleteService }) {
  const [toggleInvoice, setToggleInvoice] = useState(false);
  return (
    <div
      className="w-full flex items-center gap-10 bg-white rounded-lg
                        p-5 my-2 dark:bg-slate-800"
    >
      <p className="text-sm text-gray-500 dark:text-gray-300 w-full">
        {item.shipment.code}
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 w-full">
        {item.price} ₾
      </p>
      {item.user.cname ? (
        <p className="text-sm text-gray-500 dark:text-gray-300 w-full">
          {item.user.cname}
        </p>
      ) : (
        <p className="text-sm text-gray-500 dark:text-gray-300 w-full">
          {item.user.fname_eng} {item.user.lname_eng}
        </p>
      )}
      <p
        className={`text-sm w-full ${
          item.status === 0 ? "text-red-400" : "text-green-400"
        }`}
      >
        {item.status === 0 ? "გადასახდელია" : "გადახდილია"}
      </p>

      <div className="flex items-center gap-2">
        <BsFillFileEarmarkPdfFill
          className="text-red-400 w-6 h-6 cursor-pointer shrink-0"
          onClick={() => {
            setToggleInvoice(true);
          }}
        />
        <div
          className="p-2 text-white rounded-lg bg-red-400 cursor-pointer shrink-0"
          onClick={() => {
            deleteService(item.id);
          }}
        >
          <p>წაშლა</p>
        </div>
      </div>
      {toggleInvoice && (
        <DeclarationInv handleInvoiceToggle={setToggleInvoice} data={item} />
      )}
    </div>
  );
}
