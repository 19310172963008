import Line from "../../../components/InputComponents/Line";
import TextInput from "../../../components/InputComponents/TextInput";
import Button from "../../../components/InputComponents/Button";
import { useState } from "react";
import { BsX } from "react-icons/bs";
import { BsEye } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const NewPassword = ({ closePopupSetter, handleUpdate }) => {
    const { t } = useTranslation();
    const [payload, setPayload] = useState({});

    const handlePayLoad = (value, inputName) => {
        setPayload(prev => ({ ...prev, [inputName]: value }))
    }

    const [passIncorrect, setPasIncorrect] = useState(false);

    return (
        <div className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50">
            <div className="bg-white flex flex-col items-center justify-center gap-[9px] w-[39%] md:w-[60%] sm:w-[80%] p-7 sm:px-5
            border border-gray-200 shadow-bs1 rounded-[30px] relative ">
                <BsX
                    className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
                    onClick={() => { closePopupSetter(false) }}
                />
                <div className="flex flex-col gap-2 items-center justify-center w-full">
                    <Line className="bg-teal_600 h-0.5 w-20" />
                    <h2 className="text-lg text-center font-semibold w-auto">
                        {t("change")} {t("password")}
                    </h2>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <TextInput
                        placeholder={t("new_password")}
                        SuffixIcon={BsEye}
                        type="password"
                        inputName={"password"}
                        inputSetter={handlePayLoad}
                    />
                    <TextInput
                        placeholder={t("repeat_new_password")}
                        SuffixIcon={BsEye}
                        type="password"
                        inputName={"password_conf"}
                        inputSetter={handlePayLoad}
                    />
                </div>
                <p className={`font-bold mt-2 text-xs text-center text-red-500 w-auto transition-all
                    ${passIncorrect ? "opacity-100" : "opacity-0"}`}>
                    {t("passwords_dont_match")}
                </p>
                <Button
                    className="common-pointer cursor-pointer font-normal
                   text-md text-center text-white_A700"
                    shape="RoundedBorder11"
                    size="lg"
                    variant="OutlineTeal600"
                    onClick={() => {
                        if (payload.password !== payload.password_conf || payload.password === null || payload.password_conf === null ||
                            payload.password === "" || payload.password_conf === "") {
                            setPasIncorrect(true)
                        } else {
                            setPasIncorrect(false)
                            handleUpdate(payload)
                        }
                    }}
                >
                    {t("save_changes")}
                </Button>
            </div>
        </div>
    )
}

export default NewPassword;