import { useState } from "react";
import Button from "../../components/InputComponents/Button";
import Invoice from "../../components/Invoice/Invoice";
import AddTrustedPerson from "./AddTrustedPerson";
//icons
import { BsFillFileEarmarkPdfFill, BsFillFileTextFill } from "react-icons/bs";
import DeclarationDetails from "./DeclarationDetails";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import Transportation from "./Transportation";
import { BsAirplane, BsTruck, BsExclamationCircle } from "react-icons/bs";
import { FaShip } from "react-icons/fa";

const ArrivedCard = ({ data, payPack, payPackBonusPoints, setUpdatePacks }) => {
  const { t } = useTranslation();
  //show invoice
  const [popupInvoice, setPopupInvoice] = useState(false);
  //show invoice
  const [popupDetails, setPopupDetails] = useState(false);
  //action toggle
  const [toggleActions, setToggleActions] = useState(false);
  //action toggle
  const [toggleTransactions, setToggleTransactions] = useState(false);
  //show trusted person popup
  const [trustedShow, setTrustedShow] = useState(false);
  //update date
  const [updateDate, setUpdateDate] = useState(() => {
    if (data.shipment !== null) {
      const date = new Date(data.shipment.updated_at).toLocaleDateString();
      return date;
    } else {
      return "...";
    }
  });

  return (
    <div
      className="p-4 flex items-center md:items-start
        md:shadow-bs1 md:rounded-lg md:bg-white"
    >
      <div className="w-full grid grid-cols-6 md:grid-cols-2 gap-2">
        <div className="w-full flex flex-row gap-2.5 ">
          <div>
            {data.shipment_type === 1 && (
              <BsTruck className="text-2xl text-teal_600" />
            )}
            {data.shipment_type === 2 && (
              <BsAirplane className="text-2xl text-teal_600" />
            )}
            {data.shipment_type === 3 && (
              <FaShip className="text-2xl text-teal_600" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm md:text-xs text-teal_600 font-semibold">
              {data.tracking_number}
            </p>
            <div className="flex items-center gap-2">
              <p className="text-xs text-gray-500 font-normal">
                {data.country.country}
              </p>
              <div className="flex justify-between items-center">
                <div className="group relative">
                  <BsExclamationCircle className="text-gray_900 w-4 h-4 hover:text-teal-600 cursor-pointer" />
                  <div className="hidden group-hover:block absolute bottom-[100%] left-0 w-[300px] z-[2]">
                    <div
                      className="flex flex-col w-full bg-white border border-gray-200 shadow-bs1
                            rounded-[30px]"
                    >
                      <div className="flex flex-col gap-1 p-4">
                        <p className="text-xs font-semibold">
                          {data.shipment_code}
                        </p>
                        <p className="text-xs font-semibold">
                          {t("last_updated")}:
                        </p>
                        <p className="text-xs font-semibold">{updateDate}</p>
                      </div>
                      {data.shipment && (
                        <div className="flex flex-col gap-2 p-4 border-t border-gray-200">
                          {data.shipment.sent_at !== "" &&
                            data.shipment.sent_at && (
                              <div div className="flex flex-col gap-1">
                                <p className="text-gray-500 text-xs">
                                  {t("sent_at")}
                                </p>
                                <p className="text-teal_600 text-xs font-semibold">
                                  {data.shipment.sent_at}
                                </p>
                              </div>
                            )}
                          {data.shipment.terminals_at && (
                            <div className="flex flex-col gap-1">
                              <p className="text-gray-500 text-xs">
                                {t("terminals_at")}
                              </p>
                              <p className="text-teal_600 text-xs font-semibold">
                                {data.shipment.terminals_at}
                              </p>
                            </div>
                          )}
                          {data.shipment.customs_at && (
                            <div className="flex flex-col gap-1">
                              <p className="text-gray-500 text-xs">
                                {t("customs_at")}
                              </p>
                              <p className="text-teal_600 text-xs font-semibold">
                                {data.shipment.customs_at}
                              </p>
                            </div>
                          )}
                          {data.shipment.redistribution_at && (
                            <div className="flex flex-col gap-1">
                              <p className="text-gray-500 text-xs">
                                {t("redistribution_at")}
                              </p>
                              <p className="text-teal_600 text-xs font-semibold">
                                {data.shipment.redistribution_at}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center sm:justify-end">
          <div className="flex flex-col gap-1">
            <p className="text-sm md:text-xs text-teal_600 font-semibold">
              {data?.cal_price_by === 2
                ? `${data?.volume_weight} ${t("kg")} (CBM)`
                : `${data?.total_weight} ${t("kg")}`}
            </p>
            <p className="text-xs text-gray-500 font-normal">{t("weight")}</p>
          </div>
        </div>
        <div className="w-full flex items-center">
          <div className="flex flex-col gap-1">
            <p
              className={`text-sm md:text-xs font-semibold ${
                data.transport_price === data.transport_paid
                  ? "text-teal_600"
                  : "text-red-400"
              }`}
            >
              {data.transport_price.toFixed(2)} GEL
            </p>
            <p className="text-xs text-gray-500 font-normal">{t("price")}</p>
          </div>
        </div>
        <div className="w-full flex items-center sm:justify-end">
          <div className="flex flex-col gap-1">
            <p className="text-sm md:text-xs text-teal_600 font-semibold">
              {data.shipment_code}
            </p>
            <p className="text-xs text-gray-500 font-normal">Shipment</p>
          </div>
        </div>
        <div className="w-full flex items-center gap-2">
          <BsFillFileTextFill
            className="text-teal_600 w-6 h-6 cursor-pointer"
            onClick={() => {
              setPopupDetails(true);
            }}
          />
          <BsFillFileEarmarkPdfFill
            className="text-red-400 w-6 h-6 cursor-pointer"
            onClick={() => {
              setPopupInvoice(true);
            }}
          />
        </div>

        <div className="w-full flex items-center">
          <div className="relative w-full">
            <div
              className="flex gap-2 items-center border-gray_300 border
                            bg-white rounded-lg p-2 cursor-pointer justify-between"
              onClick={() => {
                setToggleActions((prev) => !prev);
              }}
            >
              <p className="text-xs text-gray-500 font-normal">{t("action")}</p>
              <BiChevronDown
                className={`transition-all text-teal_600 text-xl ${
                  toggleActions && "rotate-180"
                }`}
              />
            </div>
            {toggleActions && (
              <div
                className="flex gap-2 items-center absolute top-[110%] flex-col border-gray_300 border
                            bg-white rounded-lg p-2 z-30 w-full"
              >
                {data.transport_price === data.transport_paid ? (
                  <Button
                    className="cursor-pointer font-normal
text-sm text-center text-cyan_900 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineCyan900_1"
                  >
                    {t("paid")}
                  </Button>
                ) : (
                  <Button
                    className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineTeal600_4"
                    onClick={() => {
                      payPack(data.id);
                    }}
                  >
                    {t("pay")}
                  </Button>
                )}
                <Button
                  className="cursor-pointer font-normal
                       text-sm text-center text-white_A700 w-full"
                  shape="RoundedBorder8"
                  size="unord"
                  variant="OutlineTeal600_4"
                  onClick={() => {
                    setTrustedShow(true);
                  }}
                >
                  {t("trusted_person")}
                </Button>
                {data.transport_price !== data.transport_paid && (
                  <Button
                    className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                    shape="RoundedBorder8"
                    size="unord"
                    variant="OutlineTeal600_4"
                    onClick={() => {
                      payPackBonusPoints(data.id);
                    }}
                  >
                    {t("pay_with_points")}
                  </Button>
                )}
                <Button
                  className="h-full cursor-pointer font-normal
                      text-sm text-center text-white_A700 w-full"
                  shape="RoundedBorder8"
                  size="unord"
                  variant="OutlineTeal600_4"
                  onClick={() => {
                    setToggleTransactions(true);
                  }}
                >
                  {t("courier")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupDetails && (
        <DeclarationDetails setDecShow={setPopupDetails} data={data} />
      )}
      {popupInvoice && (
        <Invoice handleInvoiceToggle={setPopupInvoice} data={data} />
      )}
      {toggleTransactions && (
        <Transportation
          setDecShow={setToggleTransactions}
          data={data}
          setUpdatePacks={setUpdatePacks}
        />
      )}
      {trustedShow && (
        <AddTrustedPerson
          setShow={setTrustedShow}
          data={data}
          setUpdatePacks={setUpdatePacks}
        />
      )}
    </div>
  );
};

export default ArrivedCard;
