import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../../components/MainContext/MainContext";

const InnerBlog = () => {
  const { blogId } = useParams();
  const [data, setData] = useState({});
  const { language } = useContext(PrimaryContext);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    axiosClient
      .get(`/blog/${blogId}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {});
  }, [blogId]);

  return (
    <div className="flex items-center justify-center flex-col gap-10 pb-10">
      <div className="px-12 md:px-6 w-full max-w-[80%] md:max-w-[100%] grid grid-cols-1 gap-5">
        <div className="min-h-screen">
          <div className="md:my-12 my-20 mx-auto md:w-full w-[80%] items-center">
            <img
              src={`${process.env.REACT_APP_API_URL}/storage/${data?.img}`}
              className="w-full object-cover"
              alt={`${process.env.REACT_APP_API_URL}/storage/${data?.img}`}
            />
          </div>
          <div className="mx-auto md:w-full w-[80%] items-center">
            <h2 className="rb-5 mb-5 md:text-2xl font-bold text-7xl">
              {data[`title${capitalize(language)}`]}
            </h2>
            <div>
              <p>{data[`text${capitalize(language)}`]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerBlog;
