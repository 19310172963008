import Line from "../../components/InputComponents/Line";
import Button from "../../components/InputComponents/Button";
import BallanceCard from "./BalanceCard";
import axiosClient from "../../api/api";
import { useState, useEffect } from "react";
import UserPagination from "../../components/UserPagination/UserPagination";
import { BiWallet } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";
import { BsPiggyBank, BsStar } from "react-icons/bs";

const MyBallance = () => {
  const { t } = useTranslation();
  //data from context
  const { user } = useContext(PrimaryContext);

  //shipment data
  const [transactions, setTransactions] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetch faqs data
  useEffect(() => {
    axiosClient
      .get(
        `/packHistory?page=${currentPage + 1}&per_page=${12}&description=payed`
      )
      .then(({ data }) => {
        setTotalAmount(data.total);
        setTransactions(data.data);
        setIsLoading(false);
      });
  }, [currentPage]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="text-black_900 text-2xl md:text-xl h">
          {t("my_balance")}
        </h2>
      </div>
      <div className="flex gap-2 items-center mt-5">
        <div
          className="flex flex-col items-center justify-start border-2 border-teal_600 
                    rounded-lg bg-white p-3 sm:self-stretch"
        >
          <BiWallet className="h-6 w-6 text-teal_600" />
          <p className="text-teal_600 text-base md:text-sm mt-1 font-bold">
            {t("my_balance")}
          </p>
          <p className="text-teal_600 text-sm md:text-xs mt-1">
            {user.balance !== null &&
              user.balance !== undefined &&
              user.balance.toFixed(2)}{" "}
            GEL
          </p>
        </div>
        <div className="flex flex-col justify-between gap-2 self-stretch">
          <Link to={"../ballance-top-up"}>
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[267px] md:min-w-[200px] sm:min-w-[180px] w-auto"
              leftIcon={<BsPiggyBank className="h-6 w-6 text-white mr-1.5" />}
              shape="RoundedBorder14"
              size="md"
              variant="OutlineTeal600"
            >
              <div className="font-normal text-lg md:text-md sm:text-[10px] text-center text-white_A700">
                {t("top_up_my_ballance")}
              </div>
            </Button>
          </Link>
          <Link to={"../bonus-points"}>
            <Button
              className="cursor-pointer flex items-center justify-center min-w-[267px] md:min-w-[200px] sm:min-w-[180px] w-auto"
              leftIcon={<BsStar className="h-6 w-6 text-white mr-1.5" />}
              shape="RoundedBorder14"
              size="md"
              variant="OutlineTeal600"
            >
              <div className="font-normal text-lg md:text-md sm:text-[10px] text-center text-white_A700">
                {t("view_bonus_points")}
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <UserPagination
        dataLength={totalAmount}
        itemsPerPage={12}
        pageNumberSetter={setCurrentPage}
      >
        <div className="shadow-bs1 rounded-[30px] mt-5 bg-white mb-5 overflow-hidden">
          {!isLoading && transactions?.length !== 0 && (
            <div className="p-4 px-10 flex gap-[0.5%] md:hidden sm:hidden">
              <div className="flex flex-row gap-2.5 w-[33%]">
                <p className="text-sm font-semibold">
                  {t("date")}/{t("tracking_number")}
                </p>
              </div>
              <div className="flex items-center justify-center w-[33%]">
                <p className="text-sm font-semibold">{t("action")}</p>
              </div>
              <div className="flex items-center justify-end w-[33%]">
                <p className="text-sm text-center font-semibold">
                  {t("amount")}
                </p>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="grid grid-cols-1">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className={`animate-pulse bg-gray-200 w-full h-16 border-t border-white ${
                      index === 0 && "border-t-0"
                    } `}
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : transactions?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <div className="flex flex-col md:gap-2 ">
              {transactions.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`border-t border-gray-200 ${
                      index === 0 && "md:border-t-0"
                    } `}
                  >
                    <BallanceCard data={item} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </UserPagination>
    </div>
  );
};

export default MyBallance;
