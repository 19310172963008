import Img from "../InputComponents/Img";
import { useState, useEffect } from "react";
import axiosClient from "../../api/api";

import { useTranslation } from "react-i18next";

const SelecetCountry = ({ retriveValue = null, retriveName = null }) => {
  const { t } = useTranslation();
  //countries
  const [countries, setCountries] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //active country
  const [active, setActive] = useState(0);
  //dummy text for loader
  const dummyArray = Array.from({ length: 4 });

  //fetcha countries data
  useEffect(() => {
    setIsLoading(true);
    axiosClient.get("/countries").then(({ data }) => {
      setCountries(data);
      setActive(data[0].id);
      if (retriveName) {
        retriveName(data[0].name);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (retriveValue) {
      retriveValue(active);
    }
  }, [active]);

  return (
    <div className="flex flex-col items-start justify-start w-full sm:items-center sm:justify-center">
      {isLoading ? (
        <div className="flex items-center gap-2">
          {dummyArray.map((_, index) => {
            return (
              <div
                className={`animate-pulse bg-gray-200 w-32 h-10 rounded-[8px]`}
                key={index}
              ></div>
            );
          })}
        </div>
      ) : (
        <div className="flex items-center flex-wrap gap-6 w-full sm:gap-3">
          {countries.map((item) => {
            return (
              <div
                className={`flex items-center justify-center gap-2 cursor-pointer sm:w-full sm:justify-start pb-2
                                    ${
                                      active === item.id
                                        ? "border-b-2 border-teal_600_b2"
                                        : ""
                                    }`}
                key={item.id}
                onClick={() => {
                  setActive(item.id);
                  retriveName !== null && retriveName(item.name);
                }}
              >
                <div className="h-4">
                  <Img
                    src={`${process.env.REACT_APP_API_URL}/storage/${item.img}`}
                    className="h-full w-full object-contain"
                    alt="settings"
                  />
                </div>
                <p className="font-semibold text-base">{t(`${item.name}`)}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelecetCountry;
