import img from "../../images/constants/road.png";

const Banner = ({ bannerText }) => {
  return (
    <div className="w-full h-[20vh] bg-teal_600 flex items-center justify-center relative">
      <h1 className="text-white text-3xl sm:text-xl">{bannerText}</h1>
      <img src={img} alt="#" className="absolute bottom-0 right-[10%] w-96 object-cover sm:left-0" />
    </div>
  );
};

export default Banner;
