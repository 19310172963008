import Line from "../../components/InputComponents/Line";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import axiosClient from "../../api/api";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { BiChevronLeft } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const MessegeDetails = () => {
    const { t } = useTranslation();
    //message id
    const { messegeId } = useParams();
    //messages
    const [messages, setMessages] = useState([])
    //context data
    const { user } = useContext(PrimaryContext);

    useEffect(() => {
        axiosClient.get(`/message/${messegeId}`)
            .then(({ data }) => {
                setMessages(data)
            })
    }, [])

    return (
        <div>
            <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
                <Line className="bg-teal_600 h-1 w-10" />
                <h2 className="font-semibold text-2xl md:text-xl">
                    {t("message_details")}
                </h2>
            </div>
            <div className="shadow-bs1 rounded-[30px] mt-5 bg-white
            flex flex-col gap-2">
                <div className="p-4 border-b border-gray-200">
                    <Link to={"../messeges"} className="flex items-center">
                        <div className="flex gap-2 items-center justify-start ">
                            <BiChevronLeft className="h-6 w-6 text-teal_600" />
                            <p className="font-semibold text-base md:text-sm">
                                {t("go_back")}
                            </p>
                        </div>
                    </Link>
                </div>
                <div className="p-4 flex flex-col gap-5">
                    <div className="flex gap-1 items-center">
                        <p className="text-gray-500 text-sm md:text-xs font-bold">
                            From:
                        </p>
                        <p className="font-semibold text-sm md:text-xs">
                            {messages.type === "2" ?
                                messages.admin !== null && messages.admin.name
                                :
                                user.fname_eng
                            }
                        </p>
                    </div>
                    <div className="flex gap-1 items-center">
                        <p className="text-gray-500 text-sm md:text-xs font-bold">
                            {t("subject")}:
                        </p>
                        <p className="font-semibold text-sm md:text-xs">
                            {messages.subject}
                        </p>
                    </div>
                    <div className="flex gap-1 items-center">
                        <p className="font-semibold text-sm md:text-xs">
                            {messages.message}
                        </p>
                    </div>
                    {messages.img &&
                        <div className="flex gap-1 items-center">
                            <Link to={`${process.env.REACT_APP_API_URL}/storage/${messages.img}`} target="_blank">
                                <p className=" text-sm md:text-xs text-teal_600 underline cursor-pointer font-semibold">
                                    {t("attached_file")}
                                </p>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}

export default MessegeDetails;