import { useEffect } from "react";
const Messenger = () => {
    useEffect(() => {
        // Load the Messenger Customer Chat plugin
        window.fbAsyncInit = function () {
            window.FB.init({
                xfbml: true,
                version: 'v16.0',
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    return (
        <div
            className="fb-customerchat"
            attribution="setup_tool"
            page_id="105064499118139"
        // logged_in_greeting="Hi! How can we help you?"
        // logged_out_greeting="Hi! How can we help you?"
        ></div>
    );
}

export default Messenger;