import Line from "../../components/InputComponents/Line";
import TextInput from "../../components/InputComponents/TextInput";
import {
  successToast,
  errorToast,
} from "../../adminPanel/components/toastAlerts/ToastAlerts";
import axiosClient from "../../api/api";
//icons
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Transportation = ({ setDecShow, data, setUpdatePacks }) => {
  const { t } = useTranslation();

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const form = event.target;
      const formData = new FormData(form);
      const city = formData.get("city");

      if (!city) {
        errorToast("Error: City is required.");
      } else {
        const res = await axiosClient.post(
          `/updateWithItems/${data.id}`,
          formData
        );

        if (res) {
          setUpdatePacks(res);
          successToast("successful");
        }
      }
    } catch (err) {
      errorToast("error");
    }
  }

  return (
    <div
      className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50"
    >
      <div
        className="bg-white flex flex-col w-[50%] md:w-[90%] p-7 sm:px-5
            border border-gray-300 shadow-sm rounded-[30px] relative"
      >
        <BsX
          onClick={() => {
            setDecShow(false);
          }}
          className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
        />
        <div className="flex flex-col gap-2 items-center justify-center w-full">
          <Line className="bg-teal-600 h-0.5 w-20" />
          <h2 className="text-center font-bold w-auto text-xl">
            {t("transportation_details")}
          </h2>
        </div>
        <Line className=" bg-gray_300 h-0.5 w-full my-5" />
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-full overflow-auto scrollbar"
        >
          <div className="flex flex-col gap-2 w-full md:h-[80vh] sm:h-[50vh]">
            <p className="font-bold text-left text-teal_600 w-auto text-sm sm:text-xs">
              {t("tracking_number")} #: {data.tracking_number}
            </p>
            <TextInput
              name={t("deliver_address")}
              inputName={"addon_transp"}
              value={data.addon_transp}
            />
            <TextInput
              name={t("town_city")}
              inputName={"city"}
              //   value={}
            />
            <TextInput
              name={t("trustee")}
              inputName={"another_person"}
              value={data.another_person}
            />
            <p className="font-bold text-sm text-red-500">{t("trcaution")}</p>
            <div className="grid grid-cols-3 gap-2 md:grid-cols-1">
              <button
                className="bg-teal_600_b2 border-2 border-solid border-teal_600 flex items-center justify-center px-[7px]
                            rounded-[12px] h-10 cursor-pointer w-full"
                type="submit"
              >
                <p className="font-bold text-left text-white text-sm sm:text-xs">
                  {t("save_changes")}
                </p>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Transportation;
