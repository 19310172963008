import TextInput from "../../components/InputComponents/TextInput";
import Line from "../../components/InputComponents/Line";
import Img from "../../components/InputComponents/Img";
import Button from "../../components/InputComponents/Button";
import axiosClient from "../../api/api";
import bog from "../../images/bogtext.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";

const BallanceTopUp = () => {
  const { t } = useTranslation();
  //money amount
  const [amount, setAmount] = useState({});
  //chosen payment bank
  const [chosedPayment, setChosedPayment] = useState("");

  //handle amount
  const handleAmount = (value, inputName) => {
    setAmount((prev) => ({ ...prev, [inputName]: value }));
  };

  //handlePay
  const handlePay = () => {
    if (
      amount.amount === null ||
      amount.amount === 0 ||
      amount.amount === "0"
    ) {
    } else {
      axiosClient.get(`/pay?amount=${amount.amount}`).then(({ data }) => {
        window.location.href = data;
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="text-black_900 text-2xl md:text-xl font-semibold">
          {t("balance_top_up")}
        </h2>
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <p className="text-sm md:text-xs font-semibold">
          {t("type_amount_here")}
        </p>
        <div className="max-w-[300px]">
          <TextInput
            placeholder={t("amount")}
            inputName={"amount"}
            inputSetter={handleAmount}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <p className="text-sm md:text-xs font-semibold">
          {t("choose_method")}:
        </p>
        <div className="mt-2 flex gap-2 sm:flex-col">
          <div
            className={`sm:w-6/12 bg-gray_51 border-2 border-solid flex items-center cursor-pointer
                    justify-center p-4 rounded-[14px] w-auto ${
                      chosedPayment === "BOG"
                        ? "border-teal_600 bg-white"
                        : "border-gray_300 "
                    }`}
            onClick={() => {
              setChosedPayment("BOG");
            }}
          >
            <Img
              src={bog}
              className="w-[150px] h-[40px] object-contain"
              alt="screenshot20230"
            />
          </div>
          <div
            className={`w-20 h-20 bg-gray_51 border-2 border-solid flex items-center cursor-pointer
                    justify-center p-4 rounded-[14px] ${
                      chosedPayment === ""
                        ? "border-teal_600 bg-white"
                        : "border-gray_300 "
                    }`}
            onClick={() => {
              setChosedPayment("");
            }}
          >
            <BsX className="text-teal_600 text-4xl" />
          </div>
        </div>
        <div className="max-w-[200px]">
          <Button
            className="cursor-pointer font-normal leading-[normal] min-w-[143px] 
                        text-lg md:text-md sm:text-md text-center text-white_A700 w-auto mt-2"
            shape="RoundedBorder11"
            size="lg"
            variant="OutlineTeal600"
            onClick={handlePay}
          >
            {t("payment")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BallanceTopUp;
