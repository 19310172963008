import React, { useContext } from "react";
import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import { Link } from "react-router-dom";
import { AdminPrimaryContext } from "../../adminContext/AdminContext";
import { CheckBox, SelectInputValueId } from "../../inputComponents/inputcomp";
import { BsX } from "react-icons/bs";

const ExportsPage = () => {
  const { foreignAdresses, geBranches } = useContext(AdminPrimaryContext);
  const [filterData, setFilterData] = useState({
    abOffices: [],
    shipments: [],
  });

  const [selectedFilters, setSelectedFilterValues] = useState({
    abOfficeId: null,
    shipmentId: null,
    transport: null,
    status: null,
    office_id: null,
  });

  const handleSelectedFilters = (value, key) => {
    setSelectedFilterValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleNullifySelectId = (key) => {
    setSelectedFilterValues((prev) => ({
      ...prev,
      [key]: null,
    }));
  };

  useEffect(() => {
    setFilterData((prev) => ({
      ...prev,
      abOffices: foreignAdresses.map((item) => {
        return {
          value: item.id,
          name: item.country,
        };
      }),
    }));
  }, [foreignAdresses]);

  useEffect(() => {
    axiosAdmin
      .get(`/shipments?per_page=500`)
      .then(({ data }) => {
        setFilterData((prev) => ({
          ...prev,
          shipments: data.data.map((item) => {
            return {
              value: item.code,
              name: item.code,
            };
          }),
        }));
      })
      .catch((err) => {});
  }, []);

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
        min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5">
        <SelectInputValueId
          name={"უცხოეთის ოფისი"}
          options={filterData.abOffices}
          inputName={"abOfficeId"}
          inputSetter={handleSelectedFilters}
          value={selectedFilters.abOfficeId}
          needClear={true}
        />
        <SelectInputValueId
          name={"რეისები"}
          options={filterData.shipments}
          inputName={"shipmentId"}
          inputSetter={handleSelectedFilters}
          value={selectedFilters.shipmentId}
          needClear={true}
        />
        <SelectInputValueId
          name={"სტატუსი"}
          options={[
            {
              name: "საწყობში",
              value: 1,
            },
            {
              name: "გამოგზავნილი",
              value: 2,
            },
            {
              name: "ჩამოსული",
              value: 3,
            },
            {
              name: "ჩაბარებული",
              value: 4,
            },
          ]}
          inputName={"status"}
          inputSetter={handleSelectedFilters}
          value={selectedFilters.status}
          needClear={true}
        />
        <SelectInputValueId
          name={"საქართველოს ოფისები"}
          options={geBranches}
          inputName={"office_id"}
          inputSetter={handleSelectedFilters}
          value={selectedFilters.office_id}
          needClear={true}
        />
      </div>
      <div className="flex items-center w-full mt-5">
        <CheckBox
          name={"ტრანსპორტირება"}
          inputName={"transport"}
          handleCarData={handleSelectedFilters}
          value={selectedFilters.transport}
        />
        <BsX
          className="text-red-500 text-2xl shrink-0 cursor-pointer"
          onClick={() => {
            handleNullifySelectId("transport");
          }}
        />
      </div>
      <div className="flex gap-2">
        <Link
          to={
            `${process.env.REACT_APP_API_URL}/api/exportUsers?status=${
              selectedFilters.status !== null ? selectedFilters.status : ""
            }` +
            `${
              selectedFilters.abOfficeId !== null
                ? `&abroad_office_id=${selectedFilters.abOfficeId}`
                : ""
            }` +
            `${
              selectedFilters.shipmentId !== null
                ? `&shipment_code=${selectedFilters.shipmentId}`
                : ""
            }` +
            `${
              selectedFilters.transport !== null
                ? `&repack=${
                    selectedFilters.transport === "false"
                      ? ""
                      : selectedFilters.transport
                  }`
                : ""
            }` +
            `${
              selectedFilters.office_id !== null
                ? `&office_id=${selectedFilters.office_id}`
                : ""
            }`
          }
          className="w-3/12 rounded-lg bg-green-400 sm:w-full flex items-center justify-center p-1 py-3 text-white mt-5"
          // target={"_blank"}
        >
          რეისებით ექსპორტი CSV
        </Link>
        <Link
          to={
            `${process.env.REACT_APP_API_URL}/api/declarantExport?status=${
              selectedFilters.status !== null ? selectedFilters.status : ""
            }` +
            `${
              selectedFilters.abOfficeId !== null
                ? `&abroad_office_id=${selectedFilters.abOfficeId}`
                : ""
            }` +
            `${
              selectedFilters.shipmentId !== null
                ? `&shipment_code=${selectedFilters.shipmentId}`
                : ""
            }` +
            `${
              selectedFilters.transport !== null
                ? `&repack=${
                    selectedFilters.transport === "false"
                      ? ""
                      : selectedFilters.transport
                  }`
                : ""
            }` +
            `${
              selectedFilters.office_id !== null
                ? `&office_id=${selectedFilters.office_id}`
                : ""
            }`
          }
          className="w-3/12 rounded-lg bg-green-400 sm:w-full flex items-center justify-center p-1 py-3 text-white mt-5"
          // target={"_blank"}
        >
          დეკლარანტის ექსპორტი CSV
        </Link>
      </div>
    </div>
  );
};

export default ExportsPage;
