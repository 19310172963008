import { useEffect, useState } from "react";
import axios from "axios";

export default function useCurrencyChanger({ curr }) {
  const [result, setResult] = useState(null);

  const changeCurrency = (currency) => {
    axios
      .get(
        `https://nbg.gov.ge/gw/api/ct/monetarypolicy/currencies/ka/json/?currencies=${currency}`
      )
      .then((response) => {
        if (response?.data?.length > 0) {
          if (response?.data[0]?.currencies?.length > 0) {
            setResult(
              response?.data[0]?.currencies[0]?.rate /
                response?.data[0]?.currencies[0]?.quantity
            );
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    changeCurrency(curr);
  }, [curr]);

  return result;
}
