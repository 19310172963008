import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

function UserPagination({
    dataLength,
    itemsPerPage,
    children,
    pageNumberSetter
}) {
    //current Page
    const [currentPage, setCurrentPage] = useState(0);
    //calculate how many pages will be there
    const pageCount = Math.ceil(dataLength / itemsPerPage);

    // get current page number
    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
    };

    useEffect(() => {
        if (pageNumberSetter) {
            pageNumberSetter(currentPage);
        }
    }, [currentPage])

    return (
        <>
            {children}
            <ReactPaginate
                breakLabel="..."
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                breakLinkClassName={"font-bold text-gray_900"}
                breakClassName={"h-10 w-10 flex items-center justify-center"}
                //main container
                containerClassName={`flex items-center gap-1`}
                //single page number
                pageLinkClassName={`h-10 w-10 text-md font-bold shadow-bs1
                text-gray_900 flex items-center justify-center rounded-lg bg-white` }
                //previous page number
                previousLinkClassName={`hidden`}
                //next page number
                nextLinkClassName={`hidden`}
                //active page
                activeLinkClassName={"bg-teal1 text-white_A700"}
            />
        </>
    );
}

export default UserPagination;