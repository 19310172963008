import { useTranslation } from "react-i18next";
import { FaRegClock } from "react-icons/fa6";
import axiosClient from "../../api/api";
import { useContext, useEffect, useState } from "react";
import { PrimaryContext } from "../MainContext/MainContext";

const WorkingHoursCard = () => {
  const { t } = useTranslation();
  const { language } = useContext(PrimaryContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dummyArray = Array.from({ length: 2 });

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get("/blog?type=branchData")
      .then(({ data }) => {
        setData(data);
      })
      .then((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function capitalize(s) {
    return s && String(s[0]).toUpperCase() + String(s).slice(1);
  }

  return (
    <div className="px-12 md:px-6 w-full flex flex-col items-center justify-center relative">
      <div className="flex flex-col font-helvetica gap-4 items-center justify-start w-full">
        <h6 className="text-black_900 text-2xl md:text-xl font-bold">
          {t("working_hours")}
        </h6>
      </div>
      <div className="grid grid-cols-2 gap-5 w-full mt-12 md:mt-6 sm:grid-cols-1 max-w-[80%] md:max-w-[100%]">
        {isLoading
          ? dummyArray.map((_, index) => {
              return (
                <div
                  className="animate-pulse bg-slate-100 w-full h-20 rounded-xl"
                  key={index}
                ></div>
              );
            })
          : data.map((item) => {
              return (
                <div
                  key={item.id}
                  className="border-2 border-[#F0F0F0] rounded-2xl p-5 w-full flex flex-col gap-3"
                >
                  <div className="flex items-center justify-center w-full">
                    <FaRegClock className="text-teal_600 text-3xl" />
                  </div>
                  <p className="text-base text-center font-bold">
                    {item["title" + capitalize(language)]}
                  </p>

                  <p className="text-xs text-center font-bold">
                    {item["smalText" + capitalize(language)]}
                  </p>
                  {item["text" + capitalize(language)] &&
                  item["text" + capitalize(language)] !== "undefined" ? (
                    <p className="text-xs text-center font-bold">
                      {item["text" + capitalize(language)]}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default WorkingHoursCard;
