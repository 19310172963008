import { Power3, gsap } from "gsap/all";

const slideBottomToTopShow = (element) => {
    gsap.to(
        element,
        .5,
        {
            y: "0",
            ease: Power3.easeOut
        }
    )
}

const slideTopToBottomHide = (element) => {
    gsap.to(
        element,
        .5,
        {
            y: "100%",
            ease: Power3.easeOut
        }
    )
}

const slideEffect = (element,time,effectObj) => {
    gsap.to(
        element,
        time,
        effectObj
    )
}


export {
    slideBottomToTopShow,
    slideTopToBottomHide,
    slideEffect
};