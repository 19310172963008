const TransactionsItem = ({
    itemData
}) => {

    return (
        <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-2 bg-white rounded-lg p-2 dark:bg-slate-700">
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">მომხმარებლის Room ID:</p>
                    <p className="text-gray-500 text-[14px]">
                        {itemData.user_id}
                    </p>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">ტრანზაქციის კოდი:</p>
                    <p className="text-gray-500 text-[14px]">{itemData.trs_code}</p>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">თანხა:</p>
                    <p className={`text-[14px] ${itemData.type === 2 && "text-red-500"} ${itemData.type === 1 && "text-green-500"}`}>
                        {itemData.amount}
                    </p>
                </div>
            </div>
            <div className="w-full">

                <div className="flex flex-col gap-1">
                    <p className="text-gray-400 text-[12px]">სტატუსი:</p>
                    <p className={`text-[14px] ${itemData.type === 2 && "text-red-500"} ${itemData.type === 1 && "text-green-500"}`}>
                        {itemData.type === 1 && "წარმატებით დასრულდა"}
                        {itemData.type === 2 && "წარუმატებლად დასრულდა"}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TransactionsItem;
