import Line from "../../components/InputComponents/Line";
import { useState, useEffect } from "react";
import axiosClient from "../../api/api";
import InsuranceCard from "./InsuranceCard";

import { useTranslation } from "react-i18next";
import UserPagination from "../../components/UserPagination/UserPagination";

const Insurance = () => {
  const { t } = useTranslation();

  //parcels
  const [parcels, setParcels] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/packs?page=${currentPage + 1}&per_page=${12}&addon_secur=1`)
      .then(({ data }) => {
        setParcels(data.data);
        setTotalAmount(data.meta.total);
        setIsLoading(false);
      });
  }, [currentPage]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">{t("insurance")}</h2>
      </div>

      <div className="bg-gradient6 flex h-max items-center justify-center m-auto p-12 md:p-6 sm:p-3 rounded-[30px] w-full mt-5">
        <div className="flex flex-col items-center justify-center w-[80%] sm:w-full gap-5">
          <Line className="bg-white_A700 h-0.5 w-[31%]" />
          <p className="text-white text-base md:text-sm text-center">
            {t("panel_insurance_text")}
          </p>
          <p className="text-white text-base md:text-sm text-center">
            {t("panel_insurance_text1")}
          </p>
          <Line className="bg-white_A700 h-0.5 w-[31%]" />
        </div>
      </div>

      <UserPagination
        dataLength={totalAmount}
        itemsPerPage={12}
        pageNumberSetter={setCurrentPage}
      >
        <div className="shadow-bs1 rounded-[30px] my-5 bg-white grid grid-cols1 overflow-hidden">
          {isLoading ? (
            <div className="grid grid-cols-1">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className={`animate-pulse bg-gray-200 w-full h-16 border-t border-white ${
                      index === 0 && "border-t-0"
                    } `}
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : parcels?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <>
              {parcels.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${
                      index !== 0 &&
                      parcels.length !== 0 &&
                      "border-t-2 border-gray_300"
                    }`}
                  >
                    <InsuranceCard data={item} />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </UserPagination>
    </div>
  );
};

export default Insurance;
