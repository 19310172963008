const InstructionsCard = ({ item }) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="rounded-[30px] w-full min-h-[300px] overflow-hidden">
        <div className="w-full">
          <iframe
            width={"100%"}
            height={"360"}
            src={item.video}
            allowFullScreen
            title={item.titleen}
          ></iframe>
        </div>
      </div>
      <h2 className="font-bold">{item.title}</h2>
      <p className="text-sm font-bold">{item.text}</p>
    </div>
  );
};

export default InstructionsCard;
