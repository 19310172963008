import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";

import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const PopupCard = ({ id, item, handleDelete }) => {
  const [showContinue, setShowContinue] = useState(false);

  return (
    <div className="w-full flex py-2 bg-white dark:bg-slate-700 p-2 rounded-lg items-center justify-between">
      <p className="text-gray-500 text-sm">{item.ge}</p>

      <div className=" flex gap-1 mt-2">
        <Link
          to={`../popup/${id}`}
          className="w-10 h-10 p-3 rounded-lg bg-violet-400 cursor-pointer"
        >
          <PencilSquare className="text-white font-bold w-full h-full" />
        </Link>
        <div
          onClick={() => {
            setShowContinue(true);
          }}
          className="w-10 h-10 p-3 rounded-lg bg-red-400 cursor-pointer"
        >
          <BsTrash className="text-white font-bold w-full h-full" />
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={id}
      />
    </div>
  );
};

export default PopupCard;
