import { useContext } from "react";
import { PrimaryContext } from "../../components/MainContext/MainContext";
import { Link } from "react-router-dom";

const BlogsCard = ({ item }) => {
  const { language } = useContext(PrimaryContext);

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Link  to={`../blogs/${item?.id}`} className="inline-block h-full">
      <div className="flex flex-col gap-3 cursor-pointer border border-gray-200 p-4 rounded-lg h-full">
        <div className="w-full">
          <div className="w-full relative " style={{ paddingTop: "100%" }}>
            <img
              src={`${process.env.REACT_APP_API_URL}/storage/${item?.img}`}
              alt=""
              className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
        <h1 className="font-bold">{item[`title${capitalize(language)}`]}</h1>
        <p className="text-xs text-teal_600">
          {item[`smalText${capitalize(language)}`]}
        </p>
      </div>
    </Link>
  );
};

export default BlogsCard;
