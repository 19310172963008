import { useState, useEffect, useRef } from "react";
import { X, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BsX } from "react-icons/bs";

const CheckBox = ({
  name,
  value,
  toString,
  inputName,
  handleCarData = null,
  makeEmpty,
  handleMakeEmpty,
}) => {
  const [currentValue, setCurrentValue] = useState("false");
  const [inputValueBolean, setInputValueBolean] = useState(false);

  const handleCurrentValue = (e) => {
    if (toString) {
      setCurrentValue(e.target.checked.toString());
      handleCarData(e.target.checked.toString(), inputName);
    } else {
      setCurrentValue(e.target.checked);
      handleCarData(e.target.checked, inputName);
    }
    setInputValueBolean(e.target.checked);
  };

  useEffect(() => {
    if (makeEmpty) {
      setCurrentValue("false");
      setInputValueBolean(false);
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  useEffect(() => {
    if (value !== undefined) {
      if (value === "true") {
        setCurrentValue("true");
        setInputValueBolean(true);
      } else if (value === "false" || value === null) {
        setCurrentValue("false");
        setInputValueBolean(false);
      }
    }
  }, [value]);

  return (
    <div className="flex items-center gap-3">
      <input
        name={inputName}
        checked={inputValueBolean}
        onChange={(e) => {
          handleCurrentValue(e);
        }}
        type="checkbox"
      />
      <p className="text-sm text-gray-500">{name}</p>
    </div>
  );
};

const TextInput = ({
  name,
  inputName,
  inputSetter = null,
  value,
  makeEmpty,
  handleMakeEmpty,
  Icon = null,
  placeholder = null,
  type = "text",
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (inputSetter) inputSetter(event.target.value, inputName);
  };

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    } else {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (makeEmpty) {
      setInputValue("");
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div className="bg-yellow mt-2">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 bg-white"
      >
        {Icon && <Icon className="text-gray-500 dark:text-white text-[18px]" />}
        <input
          name={inputName}
          value={inputValue}
          placeholder={placeholder && placeholder}
          onChange={(event) => handleInputChange(event)}
          type={type}
          className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                    dark:bg-slate-800 border-0"
        />
      </div>
    </div>
  );
};

const MultyLangualTextInput = ({
  name = "დასახელება",
  value,
  valueen,
  geInputName,
  enInputName,
  inputSetter,
}) => {
  // lang state controller
  const [lang, setLang] = useState("ge");

  //value variable
  const [currentValue, setCurrentValue] = useState("");

  //value handler
  const handleCurrentValue = (e) => {
    setCurrentValue(e.target.value);
  };

  //lang handler
  const handleLang = (lang) => {
    if (value === null && valueen === null) {
      setCurrentValue("");
    } else {
      if (lang === "ge") {
        setCurrentValue(value);
      } else if (lang === "en") {
        setCurrentValue(valueen);
      }
    }
    setLang(lang);
  };

  //set passed value
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  //set value based on language in outer object
  useEffect(() => {
    if (inputSetter) {
      if (lang === "ge") {
        inputSetter(currentValue, geInputName);
      } else if (lang === "en") {
        inputSetter(currentValue, enInputName);
      }
    }
  }, [currentValue]);

  return (
    <div className="pt-5 flex gap-1 flex-col">
      <div className="flex flex-col gap-1 w-full">
        <div className="flex items-end justify-between">
          <p className="text-sm text-gray-500 dark:text-gray-300">{name}</p>
          <div className="flex gap-1">
            <div
              onClick={() => {
                handleLang("ge");
                setCurrentValue(value);
              }}
              className={`w-10 h-10 flex items-center justify-center border dark:border-slate-600 
                    border-gray-300 rounded-lg cursor-pointer ${
                      lang === "ge" && "bg-violet-400"
                    }`}
            >
              <p className="text-sm text-gray-500 dark:text-gray-300">ge</p>
            </div>
            <div
              onClick={() => {
                handleLang("en");
                setCurrentValue(valueen);
              }}
              className={`w-10 h-10 flex items-center justify-center border dark:border-slate-600 
                    border-gray-300 rounded-lg cursor-pointer ${
                      lang === "en" && "bg-violet-400"
                    }`}
            >
              <p className="text-sm text-gray-500 dark:text-gray-300">en</p>
            </div>
          </div>
        </div>
        <div
          className="w-full pv-2 flex items-center hover:outline-slate-400 hover:outline-2
hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
border dark:border-slate-600"
        >
          <input
            onChange={(e) => {
              handleCurrentValue(e);
            }}
            value={currentValue}
            type="text"
            className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
    dark:bg-slate-800 border-0 "
          />
        </div>
      </div>
    </div>
  );
};

const MultyLangualTextArea = ({
  name = "დასახელება",
  value,
  valueen,
  geInputName,
  enInputName,
  inputSetter,
}) => {
  // lang state controller
  const [lang, setLang] = useState("ge");

  //value variable
  const [currentValue, setCurrentValue] = useState("");

  //value handler
  const handleCurrentValue = (e) => {
    setCurrentValue(e.target.value);
  };

  //lang handler
  const handleLang = (lang) => {
    if (value === null && valueen === null) {
      setCurrentValue("");
    } else {
      if (lang === "ge") {
        setCurrentValue(value);
      } else if (lang === "en") {
        setCurrentValue(valueen);
      }
    }
    setLang(lang);
  };

  //set passed value
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  //set value based on language in outer object
  useEffect(() => {
    if (inputSetter) {
      if (lang === "ge") {
        inputSetter(currentValue, geInputName);
      } else if (lang === "en") {
        inputSetter(currentValue, enInputName);
      }
    }
  }, [currentValue]);

  return (
    <div className="pt-5 flex gap-1 flex-col">
      <div className="flex flex-col gap-1 w-full">
        <div className="flex items-end justify-between">
          <p className="text-sm text-gray-500 dark:text-gray-300">{name}</p>
          <div className="flex gap-1">
            <div
              onClick={() => {
                handleLang("ge");
                setCurrentValue(value);
              }}
              className={`w-10 h-10 flex items-center justify-center border dark:border-slate-600 
                    border-gray-300 rounded-lg cursor-pointer ${
                      lang === "ge" && "bg-violet-400"
                    }`}
            >
              <p className="text-sm text-gray-500 dark:text-gray-300">ge</p>
            </div>
            <div
              onClick={() => {
                handleLang("en");
                setCurrentValue(valueen);
              }}
              className={`w-10 h-10 flex items-center justify-center border dark:border-slate-600 
                    border-gray-300 rounded-lg cursor-pointer ${
                      lang === "en" && "bg-violet-400"
                    }`}
            >
              <p className="text-sm text-gray-500 dark:text-gray-300">en</p>
            </div>
          </div>
        </div>
        <div
          className="w-full pv-2 flex items-center hover:outline-slate-400 hover:outline-2
hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
border dark:border-slate-600"
        >
          <textarea
            onChange={(e) => {
              handleCurrentValue(e);
            }}
            value={currentValue}
            className="w-full h-8 pl-1.5 focus:outline-none
                    dark:bg-slate-800 border-0 min-h-[200px] text-gray-500"
          />
        </div>
      </div>
    </div>
  );
};

const SelectInput = ({
  name,
  options,
  inputName,
  handleCarData = null,
  value = undefined,
  makeEmpty,
  handleMakeEmpty,
}) => {
  //translations
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectValue, setSelectValue] = useState(t("choose"));
  const handleSelect = (index) => {
    setSelectValue(options[index]);
  };
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (value !== undefined) {
      setSelectValue(value);
    } else {
      setSelectValue(t("choose"));
    }
  }, [value]);

  useEffect(() => {
    if (selectValue != t("choose") && handleCarData !== null) {
      handleCarData(selectValue, inputName);
    }
  }, [selectValue]);

  useEffect(() => {
    if (makeEmpty) {
      setSelectValue("");
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        onClick={() => {
          handleOpen();
        }}
        className="border border-slate-300 w-full p-2 rounded-lg focus:outline-slate-300
                 dark:bg-slate-900 dark:text-gray-300 relative
                 dark:border-slate-600 dark:focus:outline-slate-600 dark:hover:border-slate-600"
      >
        <div className="h-[22px] flex justify-between items-center">
          <p className="text-sm text-gray-500">
            {selectValue === "" ? t("choose") : selectValue}
          </p>
          {isOpen ? (
            <ChevronUp className="text-sm text-gray-500" />
          ) : (
            <ChevronDown className="text-sm text-gray-500" />
          )}
        </div>
        {isOpen ? (
          <div
            className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                        dark:bg-slate-800  dark:border-slate-600"
          >
            {options.map((value, index) => {
              return (
                <div
                  onClick={() => {
                    handleSelect(index);
                  }}
                  key={value}
                  className="p-3 w-full hover:bg-slate-300 group"
                >
                  <p
                    className="text-sm text-gray-500 font-bold dark:text-gray-300
                                            group-hover:text-slate-800"
                  >
                    {value}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const SelectInputValueId = ({
  name,
  options,
  inputName,
  inputSetter = null,
  value = null,
  makeEmpty,
  handleMakeEmpty,
  defaultText,
  needClear,
}) => {
  //toggle select
  const [isOpen, setIsOpen] = useState(false);
  //current name of current selected value
  const [selectValue, setSelectValue] = useState(
    defaultText ? defaultText : "აირჩიეთ"
  );

  //select option handler
  const handleSelect = (index = "not_passed") => {
    if (index !== "not_passed") {
      setSelectValue(options[index].name);
      if (inputSetter) {
        inputSetter(options[index].value, inputName);
      }
    } else {
      setSelectValue(null);
      if (inputSetter) {
        inputSetter(null, inputName);
      }
    }
  };

  //toggle select handler
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (value) {
      const defaultVal = options.find((element) => {
        return element.value == value;
      });
      if (defaultVal) {
        setSelectValue(defaultVal?.name);
      }
    } else {
      setSelectValue(defaultText ? defaultText : "აირჩიეთ");
    }
  }, [value]);

  useEffect(() => {
    if (makeEmpty) {
      setSelectValue(defaultText ? defaultText : "აირჩიეთ");
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  useEffect(() => {
    if (defaultText) {
      setSelectValue(defaultText);
    }
  }, [defaultText]);

  return (
    <div className="w-full">
      {name && (
        <p className="text-sm text-gray-500 mb-1 dark:text-gray-300 mt-2 ">
          {name}
        </p>
      )}
      <div className="flex items-center gap-2">
        <div
          onClick={() => {
            handleOpen();
          }}
          className="border border-slate-300 w-full p-2 rounded-lg focus:outline-slate-300
                 dark:bg-slate-900 dark:text-gray-300 relative bg-white
                 dark:border-slate-600 dark:focus:outline-slate-600 dark:hover:border-slate-600"
        >
          <div className="h-[22px] flex justify-between items-center">
            <p className="text-sm text-gray-500">{selectValue}</p>
            {isOpen ? (
              <ChevronUp className="text-sm text-gray-500" />
            ) : (
              <ChevronDown className="text-sm text-gray-500" />
            )}
          </div>
          {isOpen ? (
            <div
              className="w-full max-h-80  overflow-y-auto scrollbar bg-white rounded-lg
                        absolute bottom-0 left-0 translate-y-[105%] border border-slate-300 z-40
                        dark:bg-slate-800  dark:border-slate-600"
            >
              {options.map((obj, index) => {
                return (
                  <div
                    onClick={() => {
                      handleSelect(index);
                    }}
                    key={obj.name}
                    className="p-3 w-full hover:bg-slate-300 group cursor-pointer"
                  >
                    <p
                      className="text-sm text-gray-500 font-bold dark:text-gray-300
                                            group-hover:text-slate-800"
                    >
                      {obj.name}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        {needClear && (
          <div
            onClick={() => {
              handleSelect("not_passed");
            }}
            className="border border-slate-300 p-2 rounded-lg focus:outline-slate-300
         dark:bg-slate-900 dark:text-gray-300 bg-white
         dark:border-slate-600 dark:focus:outline-slate-600 dark:hover:border-slate-600 w-10 h-10"
          >
            <BsX className="text-red-500 text-2xl shrink-0 cursor-pointer" />
          </div>
        )}
      </div>
    </div>
  );
};

const MultipleFileInput = ({
  name,
  handleNewPhotos = null,
  makeEmpty,
  handleMakeEmpty,
  setSelectedPhotoIndex = null,
  inputName = "images[]",
}) => {
  const [multipleFiles, setMultipleFiles] = useState([]);
  const inputElement = useRef(null);
  const [clickedPhotoIndex, setClickedPhotoIndex] = useState(0);

  const handleMultipleFilesChange = (event) => {
    let tmpImageList = [];
    for (let i = 0; i < event.target.files.length; i++) {
      tmpImageList.push(event.target.files[i]);
    }
    setMultipleFiles((prev) => tmpImageList);
  };

  const HandleRemovePhoto = (index) => {
    let tmp = [...multipleFiles];
    tmp.splice(index, 1);
    setMultipleFiles(tmp);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  const handleSelectedPhotoId = (index) => {
    setClickedPhotoIndex(index);
    if (setSelectedPhotoIndex !== null) {
      setSelectedPhotoIndex(index);
    }
  };

  useEffect(() => {
    if (multipleFiles != null && handleNewPhotos !== null) {
      handleNewPhotos(multipleFiles);
    }
  }, [multipleFiles]);

  useEffect(() => {
    if (makeEmpty) {
      setMultipleFiles([]);
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <>
      <div className="my-1">
        <div
          onClick={handleOpenInput}
          className="rounded border-dashed border-4 h-24 flex-col gap-2
                     border-gray-300 flex items-center justify-center"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            version="1"
            viewBox="0 0 48 48"
            enableBackground="new 0 0 48 48"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon
              fill="#90CAF9"
              points="40,45 8,45 8,3 30,3 40,13"
            ></polygon>
            <polygon fill="#E1F5FE" points="38.5,14 29,14 29,4.5"></polygon>
            <polygon fill="#1565C0" points="21,23 14,33 28,33"></polygon>
            <polygon fill="#1976D2" points="28,26.4 23,33 33,33"></polygon>
            <circle fill="#1976D2" cx="31.5" cy="24.5" r="1.5"></circle>
          </svg>
          <p className="text-sm text-gray-500">{name}</p>
        </div>
        <input
          name={`${inputName}[]`}
          ref={inputElement}
          multiple
          className="hidden"
          type="file"
          onChange={handleMultipleFilesChange}
          accept={"image/png, image/jpg, image/bmp, image/webp, image/gif"}
        />
      </div>
      <div
        className="flex flex-wrap gap-2 p-2
            max-h-[150px] overflow-auto scrollbar-two"
      >
        {multipleFiles ? (
          multipleFiles.map((single, index) => {
            let fileUrl = URL.createObjectURL(single);
            let fileName = single.name;
            return (
              <div
                onClick={() => {
                  handleSelectedPhotoId(index);
                }}
                key={index}
                className={`border border-slate-300 rounded-xl p-2 w-full flex justify-between items-center
                                    max-sm:h-auto ${
                                      clickedPhotoIndex === index &&
                                      "bg-green-100"
                                    }`}
              >
                <div className="flex items-center gap-2 max-sm:flex-col">
                  <img
                    src={fileUrl}
                    className="w-10 h-10 cover rounded-xl"
                    alt="#"
                  />
                  <p className="text-slate-600 text-md font-bold ">
                    {fileName}
                  </p>
                </div>
                <div
                  className="rounded-full w-8 h-8 bg-red-400 flex items-center justify-center"
                  onClick={() => {
                    HandleRemovePhoto(index);
                  }}
                >
                  <X className="text-gray-100 text-2xl" />
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const DateInput = ({
  name,
  inputName,
  inputSetter = null,
  value = undefined,
  makeEmpty,
  handleMakeEmpty,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (inputSetter) {
      inputSetter(event.target.value, inputName);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    } else {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    if (makeEmpty) {
      setInputValue("");
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div className="w-full mt-2">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 bg-white"
      >
        <input
          name={inputName}
          value={inputValue}
          onChange={(e) => {
            handleInputChange(e);
          }}
          type="date"
          className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                    dark:bg-slate-800 border-0"
        />
      </div>
    </div>
  );
};

const TextAreaInput = ({ name, value, inputName, inputSetter = null }) => {
  //value variable
  const [currentValue, setCurrentValue] = useState("");

  //value handler
  const handleCurrentValue = (e) => {
    setCurrentValue(e.target.value);
  };

  //set passed value
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, []);

  useEffect(() => {
    if (inputSetter) {
      inputSetter(currentValue, inputName);
    }
  }, [currentValue]);

  return (
    <div className="mt-2">
      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">{name}</p>
      <div
        className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 bg-white "
      >
        <textarea
          onChange={(e) => {
            handleCurrentValue(e);
          }}
          value={currentValue}
          name={inputName}
          className="w-full h-8 pl-1.5 focus:outline-none
                    dark:bg-slate-800 border-0 min-h-[200px] text-gray-500"
        />
      </div>
    </div>
  );
};

const FileInput = ({ name, inputName }) => {
  //working with single file input
  const [fileState, setFileState] = useState(null);
  //file input element
  const inputElement = useRef(null);
  //file name
  const [fileName, setFileName] = useState("");

  const handleChange = (event) => {
    setFileState(URL.createObjectURL(event.target.files[0]));
    setFileName(event.target.files[0].name);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  return (
    <div className="mt-3 w-full">
      <>
        <div className="my-3 ">
          <div
            onClick={handleOpenInput}
            className="rounded border-dashed border-4 h-28 flex-col gap-2
                     border-gray-300 flex items-center justify-center"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              version="1"
              viewBox="0 0 48 48"
              enableBackground="new 0 0 48 48"
              height="4em"
              width="4em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon
                fill="#90CAF9"
                points="40,45 8,45 8,3 30,3 40,13"
              ></polygon>
              <polygon fill="#E1F5FE" points="38.5,14 29,14 29,4.5"></polygon>
              <polygon fill="#1565C0" points="21,23 14,33 28,33"></polygon>
              <polygon fill="#1976D2" points="28,26.4 23,33 33,33"></polygon>
              <circle fill="#1976D2" cx="31.5" cy="24.5" r="1.5"></circle>
            </svg>
            <p className="text-sm text-gray-500">{name}</p>
          </div>
          <input
            name={inputName}
            ref={inputElement}
            className="hidden"
            type="file"
            onChange={handleChange}
          />
        </div>
        <>
          {fileState ? (
            <div
              className="border border-slate-300 rounded-xl p-4
                            w-full flex justify-between items-center max-sm:h-auto"
            >
              <div className="flex items-center gap-2 max-sm:flex-col">
                <img
                  src={fileState}
                  className="w-10 h-10 cover rounded-xl"
                  alt="#"
                />
                <p className="text-slate-600 text-md font-bold ">{fileName}</p>
              </div>
              <div
                className="rounded-full w-8 h-8 bg-red-400 flex items-center justify-center"
                onClick={() => {
                  setFileState("");
                }}
              >
                <X className="text-gray-100 text-2xl" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      </>
    </div>
  );
};

export {
  CheckBox,
  TextInput,
  SelectInput,
  MultipleFileInput,
  DateInput,
  MultyLangualTextInput,
  TextAreaInput,
  FileInput,
  SelectInputValueId,
  MultyLangualTextArea,
};
