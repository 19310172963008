import React from "react";
import { useEffect, useState } from "react";
import axiosAdmin from "../../../../api/apiAdmin";
import PaginatedItems from "../../Pagination/Pagination";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";
import { ToastContainer } from "react-toastify";
import PanelMessageCard from "./PanelMessageCard";
import { TextAreaInput, TextInput } from "../../inputComponents/inputcomp";

const PanelMessages = () => {
  //messeges
  const [messageData, setMessageData] = useState([]);
  //data amount
  const [total, setTotal] = useState(0);
  //current page
  const [currentPage, setCurrentPage] = useState(0);
  //affect data change depended on delete and add responses
  const [difResponses, setDifResponses] = useState(null);
  //message type 1 = income 2 = replay from me
  const [type, setType] = useState(1);
  //global message
  const [globalMsg, setGlobalMsg] = useState({});
  const [privateMsg, setPrivateMsg] = useState({});

  const handlePrivateMsg = (value, key) => {
    setPrivateMsg((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleGlobalMsg = (value, key) => {
    setGlobalMsg({
      [key]: value,
    });
  };

  useEffect(() => {
    axiosAdmin
      .get(`/message?type=${type}&per_page=12&page=${currentPage + 1}`)
      .then(({ data }) => {
        setMessageData(data.data);
        setTotal(data.total);
      });
  }, [difResponses, type]);

  //delete massage
  const handleDelete = async (id) => {
    try {
      const res = await axiosAdmin.delete(`/message/${id}`);
      setDifResponses(res);
      if (res) {
        successToast("მესიჯი წაიშალა!");
      }
    } catch (error) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  //handle send msg
  const handleSendGlobalMsg = () => {
    axiosAdmin
      .post(`/sendMails`, {
        text: globalMsg.msg,
      })
      .then((res) => {
        successToast("შეტყობინება წარმატებით გაიიგზავნა");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა");
      });
  };

  const handleSendPrivateMsg = () => {
    axiosAdmin
      .post(`/snendSMS`, {
        text: privateMsg.text,
        phone: privateMsg.phone,
      })
      .then((res) => {
        successToast("შეტყობინება წარმატებით გაიიგზავნა");
      })
      .catch((err) => {
        errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა");
      });
  };

  return (
    <div
      className="xl:ml-[280px] mt-[55px] bg-slate-100 dark:bg-slate-900 
        min-h-screen p-10 sm:p-5 pb-24 sm:pb-24"
    >
      <div className="flex items-center gap-2 mb-5">
        <div
          className={`px-2 py-3 rounded-lg shadow-md border border-gray-300
                            ${
                              type === 1 ? "bg-green-300" : "bg-white"
                            } cursor-pointer`}
          onClick={() => {
            setType(1);
          }}
        >
          <p
            className={`text-sm font-bold text-slate-500 ${
              type === 1 && "text-white"
            }`}
          >
            შემოსული
          </p>
        </div>
        <div
          className={`px-2 py-3 rounded-lg shadow-md border border-gray-300
                            ${
                              type === 2 ? "bg-green-300" : "bg-white"
                            } cursor-pointer`}
          onClick={() => {
            setType(2);
          }}
        >
          <p
            className={`text-sm font-bold text-slate-500 ${
              type === 2 && "text-white"
            }`}
          >
            გაგზავნილი
          </p>
        </div>
      </div>

      <h1>გლობალური მესჯის გაგზავნა</h1>

      <div className="mb-3">
        <TextAreaInput
          name={"მესიჯი"}
          inputName={"msg"}
          inputSetter={handleGlobalMsg}
        />
        <div className="mt-3">
          <button
            onClick={handleSendGlobalMsg}
            className="bg-green-500 rounded-lg px-4 py-2 text-white"
          >
            გაგზავნა
          </button>
        </div>
      </div>
      <h1>პირადი მესჯის გაგზავნა</h1>
      <div className="mb-3">
        <TextInput
          name={"ნომერი"}
          inputName={"phone"}
          inputSetter={handlePrivateMsg}
        />
        <TextAreaInput
          name={"მესიჯი"}
          inputName={"text"}
          inputSetter={handlePrivateMsg}
        />
        <div className="mt-3">
          <button
            onClick={handleSendPrivateMsg}
            className="bg-green-500 rounded-lg px-4 py-2 text-white"
          >
            გაგზავნა
          </button>
        </div>
      </div>
      <div>
        <PaginatedItems
          pageNumberSetter={setCurrentPage}
          itemsPerPage={12}
          dataLength={total}
        >
          <div className="w-full flex flex-col gap-2 my-5">
            {messageData.map((item) => {
              return (
                <PanelMessageCard
                  key={item.id}
                  item={item}
                  handleDelete={handleDelete}
                  type={type}
                  setDifResponses={setDifResponses}
                />
              );
            })}
          </div>
        </PaginatedItems>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default PanelMessages;
