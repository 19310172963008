import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosAdmin from "../../../../../api/apiAdmin";
import { successToast, errorToast } from "../../../toastAlerts/ToastAlerts";
import ContinueComponent from "../../../toastAlerts/ContinueComponent";

//icons
import {
  BsBoxArrowInRight,
  BsTrash,
  BsFillPersonFill,
  BsFillEnvelopeFill,
  BsTelephoneFill,
  BsFillHandbagFill,
  BsFillBuildingFill,
  BsArrowReturnLeft,
  BsCalendar,
} from "react-icons/bs";
import { BiPackage, BiSave } from "react-icons/bi";
import {
  FaBarcode,
  FaMoneyBillAlt,
  FaPlaneDeparture,
  FaScroll,
  FaTruckMoving,
} from "react-icons/fa";
import { GiWeight } from "react-icons/gi";
import { useTranslation } from "react-i18next";

const SentItem = ({
  data,
  handleDeletePackage,
  setCheckedAll,
  handleSelectedPackages,
  handleDeleteSelectedPackages,
  setDifResponses,
  handleSendBack,
  handleGiveUserToParcel,
  handleUndeclared,
}) => {
  const { t } = useTranslation();
  //package check
  const [check, setCheck] = useState(false);
  //show contnue
  const [showContinue, setShowContinue] = useState(false);
  //updated pack values
  const [updatedData, setUpdatedData] = useState({});

  const handleUpdateData = (key, value) => {
    setUpdatedData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //handle checking
  const handleCheck = (e) => {
    setCheck(e.target.checked);
    if (e.target.checked) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
    }
  };

  const handleRemoveRepack = (id) => {
    axiosAdmin
      .put(`packs/${id}`, { repack: 0 })
      .then((res) => {
        successToast(t("success"));
      })
      .catch((err) => {
        errorToast(t("error"));
      });
  };

  //check all controller
  useEffect(() => {
    setCheck(setCheckedAll);
    if (setCheckedAll) {
      handleSelectedPackages(data.id);
    } else {
      handleDeleteSelectedPackages(data.id);
    }
  }, [setCheckedAll]);

  //const handle send package
  const handleSend = async () => {
    try {
      const res = await axiosAdmin.put(`/packs/${data.id}`, {
        status: "2.5",
      });
      if (res) {
        successToast("ამანათი წარმატებით მიიღე!");
        setDifResponses(res);
      }
    } catch (err) {
      errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
    }
  };

  useEffect(() => {
    handleUpdateData("addon_secur", data.addon_secur);
  }, [data]);

  return (
    <div className="bg-white p-4 rounded-lg relative dark:bg-slate-700">
      <div className="absolute top-5 right-5">
        <input
          onChange={(e) => {
            handleCheck(e);
          }}
          checked={check}
          type="checkbox"
          className="w-4 h-4"
        />
      </div>
      <div
        className="w-full flex flex-wrap justify-between pb-2
            gap-y-2 border-b border-gray-200 mb-2"
      >
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">
              მომხმარებელის ინფორმაცია
            </p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.user !== undefined &&
                data.user !== null &&
                (data.user.company_id === undefined ||
                data.user.company_id === null ? (
                  <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                    {`${data.user.fname} ${data.user.lname}`}
                  </p>
                ) : (
                  <p className="text-slate-500 dark:text-gray-400 text-[12px] font-bold">
                    {`${data.user.cname} ${data.user.cname_eng}`}
                  </p>
                ))}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaBarcode className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">{`id: ${
                data.user !== undefined && data.user !== null && data.user.id
              }`}</p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillEnvelopeFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== undefined &&
                  data.user !== null &&
                  data.user.email}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400  rounded-lg p-2">
                <BsTelephoneFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                {data.user !== undefined &&
                  data.user !== null &&
                  data.user.phone}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] font-bold
                        ${
                          data.transport_price === data.transport_paid
                            ? "text-green-400"
                            : "text-red-400"
                        }`}
              >
                სტატუსი:{" "}
                {data.transport_price === data.transport_paid
                  ? "paid"
                  : "not paid"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsCalendar className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                შექმნის თარიღი: {data.created_at}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                თრექინგ ნომერი: {data.tracking_number}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaMoneyBillAlt className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                ჯამური თანხა: {data.transport_price.toFixed(2)}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                ჯამური წონა: {data.total_weight.toFixed(2)} კგ
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transportation ? "text-green-500" : "text-red-500"
                } `}
              >
                {data.addon_secur ? "დაზღვეული" : "არაა დაზღვეული"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillBuildingFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                ოფისი: {data.office !== null && data.office.name}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillPersonFill className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                მინდობილი პირი: {data.another_person} {data.another_person_id}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[32%] md:w-[49%] sm:w-[100%] flex flex-col gap-y-1">
          <div className="w-full">
            <p className="text-[#989ba5] text-[12px]">გზავნილის ინფორმაცია</p>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaTruckMoving className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] ${
                  data.addon_transp ? "text-green-500" : "text-red-500"
                } `}
              >
                ტრანსპორტირება: <br />{" "}
                {data.addon_transp ? data.addon_transp : "X"}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaScroll className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              {data.invoice !== "" && data.invoice !== null ? (
                <Link
                  to={`${process.env.REACT_APP_API_URL}/storage/${data.invoice}`}
                  target={"_blank"}
                >
                  <p
                    className={`text-[14px] text-violet-500 underline cursor-pointer`}
                  >
                    invoice
                  </p>
                </Link>
              ) : (
                <p
                  className={`text-[14px] text-red-500 underline cursor-pointer`}
                >
                  invoice
                </p>
              )}
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BsFillHandbagFill className="w-full h-full dark:text-gray-400 text-slate-500" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                ჩანთა: {data.bag}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <GiWeight className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p className="text-slate-500 dark:text-gray-400 text-[14px] font-bold">
                მოცულობითი წონა:{" "}
                {data.volume_weight !== null && data.volume_weight.toFixed(2)}{" "}
                კგ
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <FaPlaneDeparture className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] text-slate-500 dark:text-gray-400 cursor-pointer font-bold`}
              >
                რეისი: {data.shipment_code}
              </p>
            </div>
            <div className="flex items-center gap-1 my-1">
              <div className="w-8 h-8 border border-slate-500 dark:border-gray-400 rounded-lg p-2">
                <BiPackage className="w-full h-full text-slate-500 dark:text-gray-400" />
              </div>
              <p
                className={`text-[14px] font-bold ${
                  data.repack === 1 ? "text-green-500" : "text-red-500"
                }`}
              >
                გადაფუთვა საჭიროა: {data.repack === 1 ? "კი" : "არა"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-1 w-full justify-between flex-col sm:gap-y-2">
        <div className="flex flex-col gap-y-1">
          <p className="text-[#989ba5] text-[12px]">კომენტარი:</p>
          <p className="text-slate-500 dark:text-gray-400 text-[14px]">
            {data.comment}
          </p>
        </div>
        <div className="bg-white dark:bg-slate-800 w-full grid grid-cols-4 sm:grid-cols-1 gap-2">
          <input
            placeholder="მომხმარებლის მინიჭება"
            onChange={(e) => {
              handleUpdateData("userId", e.target.value);
            }}
            className="h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
          />
          <input
            placeholder="წონა"
            onChange={(e) => {
              handleUpdateData("weight", e.target.value);
            }}
            className="h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
          />
          <div className="flex gap-2 w-full items-center">
            <input
              placeholder="სიგრძე"
              onChange={(e) => {
                handleUpdateData("dementionX", e.target.value);
              }}
              className="w-full h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
            />
            <input
              placeholder="სიგანე"
              onChange={(e) => {
                handleUpdateData("dementionY", e.target.value);
              }}
              className="w-full h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
            />
            <input
              placeholder="სიმაღლე"
              onChange={(e) => {
                handleUpdateData("dementionZ", e.target.value);
              }}
              className="w-full h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
            />
          </div>
          <input
            placeholder="ტრანსპორტირების ფასი"
            onChange={(e) => {
              handleUpdateData("transport_price", e.target.value);
            }}
            className="h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
          />
          <input
            placeholder="რეისსის ნომერი"
            onChange={(e) => {
              handleUpdateData("flightNumber", e.target.value);
            }}
            className="h-10 pl-1.5 focus:outline-none dark:text-white border border-slate-200 rounded-lg dark:bg-slate-800"
          />
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              onChange={(e) => {
                handleUpdateData("addon_secur", e.target.checked ? 1 : 0);
              }}
              checked={updatedData.addon_secur ? true : false}
            />
            <p>დაზღვევა</p>
          </div>
          <div
            onClick={() => {
              handleGiveUserToParcel(updatedData, data.id);
            }}
            className="rounded-lg bg-purple-400 flex items-center justify-center gap-2 px-4 h-10
md:w-full cursor-pointer text-sm "
          >
            <BiSave className="text-white font-bold" />
            <p className="text-white font-bold">დამახსოვრება</p>
          </div>
          {data.repack !== 0 && (
            <div
              onClick={() => {
                handleRemoveRepack(data.id);
              }}
              className="rounded-lg bg-green-400 flex items-center justify-center gap-2 px-4 h-10
md:w-full cursor-pointer text-sm "
            >
              <p className="text-white font-bold">გადაფუთვის მოხსნა</p>
            </div>
          )}
        </div>
        <div className="w-full grid grid-cols-4 sm:grid-cols-1 gap-2">
          {data.packItems !== null && data.packItems.length !== 0 && (
            <div
              onClick={() => {
                handleUndeclared(data.id);
              }}
              className="rounded-lg bg-purple-400 flex items-center justify-center gap-2 px-4 h-10 md:w-full cursor-pointer"
            >
              <p className="text-white font-bold">დეკლარაციის წაშლა</p>
            </div>
          )}
          <div
            onClick={handleSend}
            className="rounded-lg bg-yellow-400 flex items-center justify-center gap-2 px-4 h-10 w-full cursor-pointer"
          >
            <BsBoxArrowInRight className="text-white font-bold" />
            <p className="text-white font-bold">მიღება</p>
          </div>
          <div
            onClick={() => {
              setShowContinue(true);
            }}
            className="w-full h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer"
          >
            <BsTrash className="text-white font-bold" />
            <p className="text-white font-bold">წაშლა</p>
          </div>
          <div
            onClick={() => {
              handleSendBack(data.id);
            }}
            className="h-10 w-full rounded-lg bg-green-400 flex items-center justify-center cursor-pointer px-2 text-white gap-2"
          >
            <BsArrowReturnLeft className="text-white font-bold" />
            <p className="text-white font-bold">უკან</p>
          </div>
        </div>
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDeletePackage}
        id={data.id}
        setShowContinue={setShowContinue}
      />
    </div>
  );
};
export default SentItem;
