import LoginRihtSide from "./loginRightSide";

const LoginLayout = ()=>{
    return(
        <div className="min-h-screen font-semibold text-slate-600 flex items-center justify-center">
            <LoginRihtSide/>
        </div>
    )
}

export default LoginLayout;