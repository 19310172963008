//main
import { Route, Routes } from "react-router-dom";
import UserMenu from "./components/UserMenu/UserMenu";
import UserMenuPhone from "./components/UserMenu/UserMenuPhone";
import MyBallance from "./pages/MyBallance/MyBallance";
import BallanceTopUp from "./pages/BallanceTopUp/BallanceTopUp";
import BonusPoints from "./pages/BonusPoints/BonusPoints";
import MyParcels from "./pages/MyParcels/MyParcels";
import Settings from "./pages/Settings/Settings";
import Support from "./pages/Support/Support";
import Messeges from "./pages/Messeges/Messages";
import MessegeDetails from "./pages/Messeges/MessageDetails";
import Transactions from "./pages/Transactions/Transactions";
import PanelFlights from "./pages/PanelFlights/PanelFlights";
import Addresses from "./pages/Addresses/Addresses";
import Insurance from "./pages/Insurance/Insurance";
import UnclearParcels from "./pages/UnclearParcels/UnclearParcels";
import Assisant from "./pages/Assistaance/Assistaance";
import DeclarationInv from "./components/Invoice/DeclarationInv";
import PayFlight from "./pages/PayFlight/PayFlight";
import TrendyolCodes from "./pages/TrendiolCodes/TrendyolCodes";

const UserPanelRoutes = () => {
  return (
    <div className="flex p-12 md:p-0 bg-gray_50 gap-12 md:gap-2 md:flex-col  md:min-h-screen">
      <div className="w-3/12 md:hidden sm:hidden">
        <UserMenu />
      </div>
      <div className="w-full afMd:hidden">
        <UserMenuPhone />
      </div>
      <div className="w-9/12 md:w-full sm:w-full md:p-6 sm:p-3">
        <Routes>
          <Route path="/my-ballance" element={<MyBallance />} />
          <Route path="/ballance-top-up" element={<BallanceTopUp />} />
          <Route path="/bonus-points" element={<BonusPoints />} />
          <Route path="/my-parcels" element={<MyParcels />} />
          <Route path="/unclear-parcels" element={<UnclearParcels />} />
          <Route path="/declaration-prices" element={<PayFlight />} />
          <Route path="/addresses" element={<Addresses />} />
          <Route path="/panel-flights" element={<PanelFlights />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/messeges" element={<Messeges />} />
          <Route path="/messeges/:messegeId" element={<MessegeDetails />} />
          <Route path="/assistance" element={<Assisant />} />
          <Route path="/support" element={<Support />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/trendyol-codes" element={<TrendyolCodes />} />
        </Routes>
      </div>
    </div>
  );
};

export default UserPanelRoutes;
