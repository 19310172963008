import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsBoxes } from "react-icons/bs";
import { PrimaryContext } from "../../components/MainContext/MainContext";

const ParcelsMenu = ({ setter = null }) => {
  const { t } = useTranslation();
  const [type, setType] = useState(1);
  const { packNumbers } = useContext(PrimaryContext);

  const [data, setData] = useState([
    {
      name: t("received"),
      id: 1,
      Icon: BsBoxes,
    },
    {
      name: t("sent"),
      id: 2,
      Icon: BsBoxes,
    },
    {
      name: t("arrived"),
      id: 3,
      Icon: BsBoxes,
    },
    {
      name: t("takeout"),
      id: 4,
      Icon: BsBoxes,
    },
  ]);

  useEffect(() => {
    if (setter) {
      setter(type);
    }
  }, [type]);

  useEffect(() => {
    if (packNumbers.length !== 0) {
      setData(
        data.map((item, index) => {
          return {
            ...item,
            count: packNumbers[index][0]?.count,
            debt: packNumbers[index][0]?.debt,
          };
        })
      );
    }
  }, [packNumbers]);

  return (
    <div className="overflow-auto pb-2 scrollbar">
      <div className="flex gap-5 mt-5 sm:w-[600px]">
        {data.map((item) => {
          return (
            <div
              className={`w-28 h-28 flex flex-col items-center justify-center sm:ml-[0] p-2 rounded-[14px] cursor-pointer
                        relative border-2 shadow-sm ${
                          item.id === type
                            ? "border-teal-600 bg-white"
                            : "border-gray-400"
                        }`}
              key={item.id}
              onClick={() => {
                setType(item.id);
              }}
            >
              <div className="flex flex-col gap-[5px] justify-center items-center w-full">
                <item.Icon
                  className={`h-10 w-10 md:h-6 md:w-6 ${
                    item.id === type ? "text-teal_600" : "text-gray_600"
                  }`}
                />
                <p
                  className={`text-sm font-bold text-center ${
                    item.id === type ? "text-teal_600" : "text-gray_600"
                  }`}
                >
                  {item.name}
                </p>
                <p
                  className={`text-sm font-bold text-center ${
                    item.id === type ? "text-red-500" : "text-gray_600"
                  }`}
                >
                  - {item.debt === null ? 0.00 : item?.debt?.toFixed(2)}
                </p>
              </div>
              <div
                className={`h-5 w-5 flex items-center justify-center rounded-[50%] absolute top-2 right-2
                            ${
                              item.id === type ? "bg-teal_600" : "bg-gray_600"
                            }`}
              >
                <p className="text-center text-white_A700 font-bold text-[8px]">
                  {item.count}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ParcelsMenu;
