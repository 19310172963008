import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import UserCard from "./userCard";
import axiosAdmin from "../../../../api/apiAdmin";
import { TextInput, SelectInputValueId } from "../../inputComponents/inputcomp";
import { successToast, errorToast } from "../../toastAlerts/ToastAlerts";

//icons
import { BiUser } from "react-icons/bi";
import { BsEnvelope, BsLock, BsSearch } from "react-icons/bs";

const UserPageLayout = () => {
    //add new user variable
    const [addNewToggler, setAddNewToggler] = useState(false);
    //get admin data from api
    const [adminData, setAdminData] = useState(null);
    //search value
    const [searchValue, setSearchValue] = useState("");
    //errors
    const [error, setError] = useState(null);
    //affect data change depended on delete and add responses
    const [difResponses, setDifResponses] = useState(null);
    //new admin data
    const [newAdmin, setNewAdmin] = useState({
        role: 1,
    });
    //clear fields after submit 
    const [makeEmpty, setMakeEmpty] = useState(false);

    //handle filed clear after submission
    const handleMakeEmpty = (value) => {
        setMakeEmpty(value);
    }

    //handle search value change
    const handleSearchValue = (e) => {
        setSearchValue(e.target.value)
    }

    //handle new admin
    const handleNewAdminData = (value, inputName) => {
        setNewAdmin(prev => ({ ...prev, [inputName]: value }))
    }

    //add new users handlers 
    const handleAddNewToggle = (value) => {
        setAddNewToggler(value);
    }

    //add new admin 
    const handleAddNewAdminToDb = async () => {
        try {
            const res = await axiosAdmin.post(`/admins`, newAdmin);
            setDifResponses(res);
            if (res) {
                successToast("ადმინი წარმატებით დაემატა!");
                handleMakeEmpty(true);
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    // searching user
    const handleSearch = async () => {
        try {
            const res = await axiosAdmin.get(`/admins/search?searchValue=${searchValue}`);
            if (res) {
                setAdminData(res.data);
            }
        } catch (error) {
            setError(error);
        }
    }

    //delete admin 
    const handleDeleteAdmin = async (id) => {
        try {
            const res = await axiosAdmin.delete(`/admins/${id}`);
            setDifResponses(res);
            if (res) {
                successToast("ადმინი წარმატებით წაიშალა!");
            }
        } catch (error) {
            setError(error);
            errorToast("დაფიქსირდა შეცდომა ცადეთ ხელახლა!");
        }
    }

    //admin data from api
    useEffect(() => {
        if (searchValue === "") {
            try {
                axiosAdmin.get(`/admins?page=1&per_page=30`)
                    .then(({ data }) => {
                        setAdminData(data.data);
                    });
            } catch (error) {
                setError(error);
            }
        }
    }, [difResponses, searchValue]);

    return (
        <div className="xl:ml-[280px] mt-[55px] bg-slate-100 max-md:ml-0 dark:bg-slate-900">
            <div className="w-full pt-10 p-10 sm:p-5 flex flex-col gap-5" >
                <div className="w-full flex items-center justify-between md:flex-col md:gap-2">
                    <div className="w-9/12 flex justify-between gap-1 md:w-full">
                        <div className="pv-2 flex items-center hover:outline-slate-400 hover:outline-2
            hover:outline gap-x-1  px-3.5 py-1 rounded-lg dark:bg-slate-800 border-gray-300
            border dark:border-slate-600 w-full bg-white">
                            <input
                                type="search"
                                placeholder="სახელი, ელ-ფოსტა"
                                className="w-full h-8 pl-1.5 focus:outline-none dark:text-white
                                dark:bg-slate-800 border-0"
                                onChange={(e) => { handleSearchValue(e) }}
                                value={searchValue}
                            />
                        </div>
                        <div
                            onClick={handleSearch}
                            className="w-20 rounded-lg bg-violet-400
                 flex items-center justify-center p-1 py-3 cursor-pointer">
                            <BsSearch className="text-white font-bold" />
                        </div>
                    </div>
                    <button
                        onClick={() => { handleAddNewToggle(true) }}
                        className="w-3/12 md:w-full rounded-lg p-3 text-white text-sm bg-violet-400 mx-5 sm:mx-0">
                        ახალი ადმინის დამატება
                    </button>
                </div>
                {addNewToggler &&
                    <div className="bg-slate-100 dark:bg-slate-900 mx-5 sm:mx-0 md:mx-0">
                        <div className="sm:px-0">
                            <div className="w-full rounded-xl bg-white dark:bg-slate-700 p-5">
                                <div className="flex items-center justify-between flex-wrap gap-5">
                                    <div className="flex items-center gap-5 sm:gap-3 ">
                                        <div>
                                            <h2 className="text-xl text-slate-400 font-bold dark:text-gray-400
                            sm:text-[16px] smd:text-[16px]">ახალი ადმინის დამატება</h2>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 sm:w-full">
                                        <button
                                            onClick={handleAddNewAdminToDb}
                                            className="rounded-lg p-3 text-white text-sm bg-violet-400 sm:w-full">
                                            შენახვა
                                        </button>
                                        <button
                                            onClick={() => { handleAddNewToggle(false) }}
                                            className="rounded-lg p-3 text-white text-sm bg-red-400 w-10">
                                            X
                                        </button>
                                    </div>
                                </div>
                                <div className="pt-5 flex gap-5 md:flex-col md:gap-0 sm:flex-col sm:gap-0">
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"სახელი"}
                                            Icon={BiUser}
                                            inputSetter={handleNewAdminData}
                                            inputName={"name"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <TextInput
                                            name={"ელ-ფოსტა"}
                                            Icon={BsEnvelope}
                                            inputSetter={handleNewAdminData}
                                            inputName={"email"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                    </div>
                                    <div className="w-6/12 sm:w-full md:w-full">
                                        <TextInput
                                            name={"პაროლი"}
                                            Icon={BsLock}
                                            inputSetter={handleNewAdminData}
                                            inputName={"password"}
                                            makeEmpty={makeEmpty}
                                            handleMakeEmpty={handleMakeEmpty}
                                        />
                                        <SelectInputValueId
                                            name={"ადმინის როლი"}
                                            inputName={"role"}
                                            inputSetter={handleNewAdminData}
                                            options={[
                                                {
                                                    name: "სუპერ ადმინი",
                                                    value: 100
                                                },
                                                {
                                                    name: "Turkey ოპერატორი",
                                                    value: 1
                                                },
                                                {
                                                    name: "Sea - China ოპერატორი",
                                                    value: 2
                                                },
                                                {
                                                    name: "Air - China ოპერატორი",
                                                    value: 3
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="w-full min-h-screen">
                <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5 p-10 pt-0 sm:p-5 sm:pt-0">
                    {adminData && adminData.map((obj) => {
                        return (
                            < UserCard
                                data={obj}
                                key={obj.id}
                                id={obj.id}
                                handleDeleteAdmin={handleDeleteAdmin}
                            />
                        )

                    })}
                </div>
            </div >
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default UserPageLayout;
