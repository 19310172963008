import { FaFacebook, FaInstagram } from "react-icons/fa";
import Img from "../InputComponents/Img";
import mglLogo from "../../images/mglLogo.png";
import { useTranslation } from "react-i18next";
import terms from "../../termss.pdf";
import Messenger from "../Messenger/Messnger";

import artspaceImg from "../../images/constants/artspace.png";

const Footer2 = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="bg-[#FAFAFA] p-5 flex items-center justify-between px-12 md:px-6">
        <div className="flex flex-col gap-2 sm:hidden">
          <Img
            src={mglLogo}
            className="w-[120px] md:flex-1 sm:h-auto md:mt-0 mt-1 object-cover"
            alt="#"
          />
        </div>
        <div className="flex items-center gap-2 md:hidden">
          <p>Developed By</p>
          <a
            href="https://artspace.dev/"
            target="_blank"
            className="inline-block"
          >
            <Img
              src={artspaceImg}
              className="w-[100px] mt-1 object-contain"
              alt="#"
            />
          </a>
        </div>
        <div className="flex gap-4 items-center sm:w-full sm:justify-between">
          <a target="_blank" href={terms}>
            <p className="text-sm sm:text-xs text-left cursor-pointer font-bold">
              {t("terms_and_conditions")}
            </p>
          </a>
          <div className="flex items-center gap-4">
            <FaInstagram className="w-6 h-6 text-[#009D68]" />
            <a
              href={"https://www.facebook.com/profile.php?id=100088632876816"}
              target="_blank"
              className="cursor-pointer"
            >
              <FaFacebook className="w-6 h-6 text-[#009D68]" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2 afMd:hidden bg-[#FAFAFA] p-2 border-t border-[#f0f0f0]">
        <p>Developed By</p>
        <a
          href="https://artspace.dev/"
          target="_blank"
          className="inline-block"
        >
          <Img src={artspaceImg} className="w-[100px] object-contain" alt="#" />
        </a>
      </div>
      <Messenger />
    </div>
  );
};

export default Footer2;
