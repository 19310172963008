import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TextInput = ({
  name,
  PrefixIcon = null,
  SuffixIcon = null,
  placeholder = null,
  inputName,
  inputSetter = null,
  pattern,
  type = "text",
  mFill,
  value,
  required = null,
  white = null,
  style,
}) => {
  const { t } = useTranslation();
  //current value variable
  const [currentValue, setCurrentValue] = useState("");
  //we save value of focus state i this variable
  const [isFocused, setIsFocused] = useState(false);
  //if filed is empty but required we must show
  const [mustFill, setMustFill] = useState("");
  //type Changer
  const [thisType, setThisType] = useState("text");

  //value change handler for input
  const handleValueChange = (e) => {
    const val = e.target.value;

    if (pattern === "en") {
      let reg = /^[A-Za-z]*$/;
      if (reg.test(val)) {
        setCurrentValue(val);
        if (inputSetter) {
          inputSetter(val, inputName);
        }
      }
    } else if (pattern === "ge") {
      let reg = /^[\u10A0-\u10FF\s]*$/i;
      if (reg.test(val)) {
        setCurrentValue(val);
        if (inputSetter) {
          inputSetter(val, inputName);
        }
      }
    } else if (pattern === "number") {
      const reg = /^\d{0,3}(\d{0,2}\d{0,2}\d{0,2})$/;
      if (reg.test(val)) {
        setCurrentValue(val);
        inputSetter(val, inputName);
        // if ([3, 6, 9].includes(val.length)) {
        //   if (val.length < value.length) {
        //     setCurrentValue(val);
        //     if (inputSetter) {
        //       inputSetter(val, inputName);
        //     }
        //   } else {
        //     setCurrentValue(val + " ");
        //     if (inputSetter) {
        //       inputSetter(val + " ", inputName);
        //     }
        //   }
        // } else {
        //   if (inputSetter) {
        //   }
        // }
      }
    } else if (pattern === "num-en") {
      let reg = /^[A-Za-z0-9./]*$/i;
      if (reg.test(val)) {
        setCurrentValue(val);
        if (inputSetter) {
          inputSetter(val, inputName);
        }
      }
    }else if(pattern === "noZero"){
      if(val !== '0'){
        setCurrentValue(val);
        if (inputSetter) {
          inputSetter(val, inputName);
        }
      }
    } else {
      setCurrentValue(val);
      if (inputSetter) {
        inputSetter(val, inputName);
      }
    }
  };

  //whenever filed is required and user does not filled it
  useEffect(() => {
    if (currentValue !== "") {
      setMustFill(false);
    } else {
      setMustFill(mFill);
    }
  }, [mFill, currentValue]);

  //set initial values
  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    } else {
      setCurrentValue("");
    }
  }, [value]);

  useEffect(() => {
    setThisType(type);
  }, [type]);

  return (
    <div className="w-full relative">
      {name && (
        <p
          className={`${
            style === 2
              ? "absolute top-0 left-5 translate-y-[-50%] font-bold bg-white"
              : `mb-1 ${white ? "text-white" : "font-semibold"}`
          } text-xs`}
        >
          {name}{" "}
          {required && (
            <span className="text-red-500 text-sm">* {t("required")}</span>
          )}
        </p>
      )}
      <div
        className={`flex items-center gap-4 w-full border-2 font-normal h-12 md:max-w-full px-4 py-2 rounded-[12px]
                ${
                  style === 2
                    ? "border-[#F0F0F0]"
                    : "bg-gray_50 border-gray_300 text-gray_600"
                }`}
      >
        {PrefixIcon !== null && (
          <PrefixIcon className="w-4 h-4 text-[#636366]" />
        )}
        <div className="w-full relative">
          <input
            name={inputName}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={currentValue}
            onChange={(e) => {
              handleValueChange(e);
            }}
            type={thisType}
            placeholder={placeholder && placeholder}
            className={`w-full text-base sm:text-sm ${
              style === 2 ? "" : "bg-gray_50"
            }`}
          />
        </div>
        {SuffixIcon !== null && (
          <SuffixIcon
            className={`w-6 h-6 text-teal-600 ${
              type === "password" && "cursor-pointer"
            }`}
            onClick={() => {
              if (type === "password") {
                if (thisType === "password") {
                  setThisType("text");
                } else if (thisType === "text") {
                  setThisType("password");
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TextInput;
