import { useTranslation } from "react-i18next";
import { BiCheckShield } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";

const InsuranceCard = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 flex items-center md:items-start">
      <div className="w-full grid grid-cols-4 md:grid-cols-2 gap-2">
        <div className="w-full flex flex-row gap-2.5 items-center">
          <div>
            <BsBoxSeam className="text-2xl text-teal_600" />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-teal_600 text-sm font-semibold">
              {data.tracking_number}
            </p>
            <p className="text-gray-500 text-xs font-semibold">
              {data.country.country}
            </p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center md:justify-end">
          <div className="flex flex-col gap-1">
            <p className="text-teal_600 text-sm font-semibold">
              {data.total_weight < data.volume_weight
                ? data.volume_weight
                : data.total_weight}{" "}
              {t("kg")}
            </p>
            <p className="text-gray-500 text-xs font-semibold">{t("weight")}</p>
          </div>
        </div>
        <div className="w-full flex items-center justify-center md:justify-start">
          <div className="flex flex-col gap-1">
            <p
              className={`w-auto text-sm font-semibold ${
                data.transport_price === data.transport_paid
                  ? "text-teal_600"
                  : "text-red-400"
              }`}
            >
              {data.transport_price.toFixed(2)} GEL
            </p>
            <p className="text-gray-500 text-xs font-semibold">{t("price")}</p>
          </div>
        </div>
        <div className="w-full flex items-center justify-end md:justify-end">
          <BiCheckShield className="text-teal_600 w-6 h-6 cursor-pointer" />
        </div>
      </div>
    </div>
  );
};

export default InsuranceCard;
