import Line from "../../../components/InputComponents/Line";
import TextInput from "../../../components/InputComponents/TextInput";
import Button from "../../../components/InputComponents/Button";
import { useContext, useState } from "react";
import { BsX } from "react-icons/bs";
import { PrimaryContext } from "../../../components/MainContext/MainContext";
import { useTranslation } from "react-i18next";

const NewId = ({ closePopupSetter, handleUpdate }) => {
    const { t } = useTranslation();
    const [payload, setPayload] = useState({});

    const handlePayLoad = (value, inputName) => {
        setPayload(prev => ({ ...prev, [inputName]: value }))
    }

    //data from context
    const { user } = useContext(PrimaryContext);

    return (
        <div className="w-screen h-screen bg-white bg-opacity-80 flex items-center justify-center 
        fixed top-0 left-0 z-50">
            <div className="bg-white flex flex-col items-center justify-center gap-[9px] w-[39%] md:w-[60%] sm:w-[80%] p-7 sm:px-5
            border border-gray-200 shadow-bs1 rounded-[30px] relative ">
                <BsX
                    className="absolute top-5 right-5 text-gray-400 w-6 h-6 cursor-pointer"
                    onClick={() => { closePopupSetter(false) }}
                />
                <div className="flex flex-col gap-2 items-center justify-center w-full">
                    <Line className="bg-teal-600 h-0.5 w-20" />
                    <h2 className="text-lg text-center font-semibold w-auto">
                    {t("change")} ID
                    </h2>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    {user.company_id === null ?
                        <TextInput
                            placeholder={"ID"}
                            inputName={"person_id"}
                            inputSetter={handlePayLoad}
                        /> :
                        <TextInput
                            placeholder={"ID"}
                            inputName={"company_id"}
                            inputSetter={handlePayLoad}
                        />
                    }
                </div>
                <Button
                    className="common-pointer cursor-pointer font-normal
                    text-md text-center text-white_A700"
                    shape="RoundedBorder11"
                    size="lg"
                    variant="OutlineTeal600"
                    onClick={() => { handleUpdate(payload) }}
                >
                    {t("save_changes")}
                </Button>
            </div>
        </div>
    )
}

export default NewId;