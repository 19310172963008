import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";
//icons
import { BsTrash } from "react-icons/bs";

const SupportCard = ({ item, handleDelete }) => {
  //show continue
  const [showContinue, setShowContinue] = useState(false);
  const date = new Date(item.created_at);
  const humanReadableDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className="bg-white rounded-xl p-5 w-full flex flex-col items-center justify-between gap-3 dark:bg-slate-700 relative">
      <div className="w-full flex flex-col gap-2">
        <div className="flex gap-1 flex-wrap">
          <p className="text-[12px] text-[#989ba5]">სახელი:</p>
          <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
            {item.name}
          </p>
        </div>
        <div className="flex gap-1 flex-wrap">
          <p className="text-[12px] text-[#989ba5]">ტელეფონი:</p>
          <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
            {item.mail}
          </p>
        </div>
        <div className="flex gap-1 flex-wrap">
          <p className="text-[12px] text-[#989ba5]">ელ ფოსტა:</p>
          <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
            {item.phone}
          </p>
        </div>
        {item.message !== "" && item.message !== null && (
          <div className="flex gap-1 flex-wrap">
            <p className="text-[12px] text-[#989ba5]">მესიჯები:</p>
            <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
              {item.message}
            </p>
          </div>
        )}

        <div className="flex gap-1 flex-wrap">
          <p className="text-[12px] text-[#989ba5]">თარიღი:</p>
          <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">
            {humanReadableDate}
          </p>
        </div>
      </div>
      <div
        onClick={() => {
          setShowContinue(true);
        }}
        className="w-10 h-10 rounded-lg bg-red-400 flex items-center justify-center cursor-pointer absolute top-5 right-5"
      >
        <BsTrash className="text-white" />
      </div>
      <ContinueComponent
        showFlag={showContinue}
        actionFunction={handleDelete}
        setShowContinue={setShowContinue}
        id={item.id}
      />
    </div>
  );
};

export default SupportCard;
