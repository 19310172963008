import Button from "../../components/InputComponents/Button";
import { useTranslation } from "react-i18next";
import { BsBoxSeam } from "react-icons/bs";

const BallanceCard = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={`p-4 px-10 md:px-8 sm:px-4 flex gap-[0.5%]`}>
            <div className="flex flex-row gap-2.5 w-[33%] md:flex-col sm:flex-col">
                <div>
                    <BsBoxSeam className="text-2xl text-teal_600" />
                </div>
                <div className="flex flex-col gap-1">
                    <p className="text-teal_600 text-sm md:text-xs font-semibold">
                        {data.pack_id}
                    </p>
                    <p className="text-gray-500 text-xs">
                        {data.created_at.split("T")[0]}
                    </p>
                </div>
            </div>
            <div className="w-[66%] flex gap-[0.5%] md:flex-col sm:flex-col sm:justify-between md:justify-between">
                <div className="flex items-center justify-center w-[49.5%] sm:w-auto md:w-auto sm:order-2 md:order-2
                sm:justify-end md:justify-end">
                    <p className="text-red_500 text-sm md:text-xs text-center md:text-right font-semibold">
                        {t("charging_amount_from_ballance")}
                    </p>
                </div>
                <div className="flex items-center justify-end w-[49.5%] sm:w-auto md:w-auto sm:order-1 md:order-1">
                    <Button
                        className="cursor-pointer font-normal min-w-[96px]
                     text-sm text-center text-white_A700 w-auto"
                        shape="RoundedBorder8"
                        size="md"
                        variant="OutlineRed500"
                    >
                        -{data.action} GEL
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BallanceCard;