// src/hooks/useFacebookPixel.js
import { useEffect } from "react";

const useFacebookPixel = (pixelId) => {
  useEffect(() => {
    if (window.fbq) return;

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://connect.facebook.net/en_US/fbevents.js";
    document.head.appendChild(script);

    window.fbq = function () {
      window.fbq.callMethod
        ? window.fbq.callMethod.apply(window.fbq, arguments)
        : window.fbq.queue.push(arguments);
    };
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = "2.0";
    window.fbq.queue = [];

    window.fbq("init", pixelId);
    window.fbq("track", "PageView");

    return () => {
      document.head.removeChild(script);
      delete window.fbq;
    };
  }, [pixelId]);
};

export default useFacebookPixel;
