import { useState } from "react";
import { TextInput } from "../../../inputComponents/inputcomp";
import axiosAdmin from "../../../../../api/apiAdmin";
import { errorToast, successToast } from "../../../toastAlerts/ToastAlerts";
import { useTranslation } from "react-i18next";

//icon
import { FaBarcode, FaRegHandPointRight } from "react-icons/fa";

const FilialsCardWar = ({
  item,
  currentPlaceId,
  handleCurrentPlaceId,
  setDifResponses,
}) => {
  //translations
  const { t } = useTranslation();
  //dropdown toggler
  const [toggler, setToggler] = useState(false);
  //searched user
  const [searchedUser, setSearchedUser] = useState(null);
  //selected user
  const [selectedUser, setSelectedUser] = useState("-Search user-");
  //search toggler
  const [searchToggler, setSearchToggler] = useState(false);

  //weight and bag
  const [payloadBarcode, setPayloadBarcode] = useState({
    total_weight: "",
    bag: "",
    user_id: "",
    abroad_office_id: item.id,
    tracking_number: "",
    shipment_type: "",
  });

  //handle Payload
  const handlePayloadBarcode = (value, inputName) => {
    setPayloadBarcode((prev) => ({ ...prev, [inputName]: value }));
  };

  //receive package functionality
  const handleRecive = async () => {
    try {
      const res = await axiosAdmin.post(`/packs`, payloadBarcode);
      if (res) {
        successToast(t("success"));
        setDifResponses(res);
      }
    } catch (err) {
      errorToast(t("trancking_number_is_alreay_added"));
    }
  };

  //receive package functionality
  const handleFindUser = async (e) => {
    try {
      const res = await axiosAdmin.get(
        `/users/search?searchValue=${e.target.value}`
      );
      if (res) {
        setSearchedUser([res.data]);
      }
    } catch (err) {}
  };

  //const choosing user
  const hanleChooseUser = (value, userId) => {
    setSelectedUser(value);
    handlePayloadBarcode(userId, "user_id");
  };

  //handle close
  const handleClose = () => {
    setSelectedUser("-Search user-");
    setToggler(false);
    setPayloadBarcode({
      total_weight: "",
      bag: "",
      user_id: "",
      abroad_office_id: item.id,
      tracking_number: "",
      shipment_type: "",
    });
  };

  return (
    <div
      className={`w-full flex items-center p-3 rounded-lg gap-5 cursor-pointer
shadow-[rgba(50,50,93,0.25)_0px_2px_5px_-1px,rgba(0,0,0,0.3)_0px_1px_3px_-1px] 
 ${
   currentPlaceId === item.id
     ? "border border-green-500 bg-green-100"
     : "bg-white dark:bg-slate-700"
 }`}
    >
      <div className="w-full flex flex-col gap-2">
        <p className="text-[14px] text-[#8e95a8]">
          {`${item.country} address`}
        </p>
        <div className="flex flex-col gap-2 relative">
          <div
            onClick={() => {
              handleCurrentPlaceId(item.id);
            }}
            className="w-full rounded-lg bg-green-400
flex items-center justify-center gap-1 p-1 py-3 text-white"
          >
            <FaRegHandPointRight />
            <p>{t("choose")}</p>
          </div>
          <div className="w-full relative ">
            <div
              onClick={() => {
                setToggler(true);
              }}
              className="w-full rounded-lg bg-yellow-400
flex items-center justify-center gap-1 p-1 py-3 text-white"
            >
              <FaBarcode />
              <p>Barcode Scan</p>
            </div>
            {toggler && (
              <div className="w-screen h-screen fixed top-0 left-0 p-10 bg-white z-[100] dark:bg-slate-700">
                <div className="w-full flex  items-center justify-end">
                  <div
                    onClick={handleClose}
                    className="w-6 h-6 rounded-full bg-red-400 flex items-center justify-center cursor-pointer"
                  >
                    <p className="text-white font-bold">X</p>
                  </div>
                </div>
                <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-2">
                  <TextInput
                    name={"Barcode"}
                    inputName={"tracking_number"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.tracking_number}
                  />
                  <TextInput
                    name={t("weight")}
                    inputName={"total_weight"}
                    inputSetter={handlePayloadBarcode}
                    type="number"
                    value={payloadBarcode.total_weight}
                  />
                  <TextInput
                    name={t("bag")}
                    inputName={"bag"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.bag}
                  />
                  <TextInput
                    name={"სიგრძე"}
                    inputName={"dementionX"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.dementionX}
                    type="number"
                  />
                  <TextInput
                    name={"სიგანე"}
                    inputName={"dementionY"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.dementionY}
                    type="number"
                  />
                  <TextInput
                    name={"სიმაღლე"}
                    inputName={"dementionZ"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.dementionZ}
                    type="number"
                  />
                  <TextInput
                    name={"ფასდაკლების პროცენტი"}
                    inputName={"discount_percent"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.discount_percent}
                    type="number"
                  />
                  <TextInput
                    name={"შიდა საკურიერო თანხა"}
                    inputName={"inner_price"}
                    inputSetter={handlePayloadBarcode}
                    value={payloadBarcode.inner_price}
                    type="number"
                  />
                  <div className="flex flex-col gap-2 relative">
                    <div
                      className="flex flex-col mt-2"
                      onClick={() => {
                        setSearchToggler((prev) => !prev);
                      }}
                    >
                      <p className="text-sm text-gray-500 mb-1 dark:text-gray-300">
                        {t("room_id")}
                      </p>
                      <div
                        className="h-10 w-full pl-1.5 dark:bg-slate-800 border border-gray-300 shadow rounded-lg
                                                flex items-center "
                      >
                        <p className="text-gray-500">{selectedUser}</p>
                      </div>
                    </div>
                    {searchToggler && (
                      <div
                        className="border border-gray-300 shadow-sm rounded-lg p-2
                                            flex flex-col gap-2 absolute top-[105%] left-0 w-full"
                      >
                        <input
                          type="text"
                          onChange={(e) => {
                            handleFindUser(e);
                          }}
                          className="w-full h-8 pl-1.5 focus:outline-none text-gray-500
                                                dark:bg-slate-800 border border-gray-300 shadow rounded-lg"
                        />
                        {searchedUser &&
                          searchedUser.map((item) => {
                            return (
                              <div key={item.id}>
                                {item.company_id === undefined ||
                                item.company_id === null ? (
                                  <p
                                    onClick={() => {
                                      hanleChooseUser(
                                        `EG${item.id} - ${item.fname} ${item.lname}`,
                                        item.id
                                      );
                                      setSearchToggler(false);
                                    }}
                                    className="text-gray-500 text-sm"
                                  >
                                    {`${item.id} - ${item.fname_eng} ${item.lname_eng}`}
                                  </p>
                                ) : (
                                  <p
                                    onClick={() => {
                                      hanleChooseUser(
                                        `EG${item.id} - ${item.cname}`,
                                        item.id
                                      );
                                      setSearchToggler(false);
                                    }}
                                    className="text-gray-500 text-sm"
                                  >
                                    {`${item.id} - ${item.cname}`}
                                  </p>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleRecive();
                    }}
                    className="w-full h-10 rounded-lg bg-green-400 flex items-center justify-center cursor-pointer mt-8"
                  >
                    <p className="text-white font-bold">{t("receive")}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilialsCardWar;
