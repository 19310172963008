import { Link } from "react-router-dom";
import { useState } from "react";
import ContinueComponent from "../../toastAlerts/ContinueComponent";

//icons
import { PencilSquare } from "react-bootstrap-icons";
import { BsTrash } from "react-icons/bs";

const UserCard = ({
    data,
    id,
    handleDeleteAdmin
}) => {
    //show continue
    const [showContinue, setShowContinue] = useState(false);

    return (
        <div className="bg-white rounded-xl p-5 w-full flex flex-col items-center justify-center gap-3 dark:bg-slate-700">

            <div className="w-full flex flex-col gap-2">
                <h2 className="text-xl font-bold text-[#777a83] dark:text-navy-100 dark:text-gray-200">ID:{data.id} - {data.name}</h2>
                <div className="flex gap-1 flex-wrap">
                    <p className="text-[12px] text-[#989ba5]" >ელ-ფოსტა:</p>
                    <p className="text-[#777a83] font-bold text-[14px] dark:text-gray-200">{data.email}</p>
                </div>
            </div>
            <div className="w-full flex gap-2">
                <Link to={`../admins/${id}`} className="w-full rounded-lg bg-violet-400
                flex gap-2 items-center justify-center p-1 py-3">
                    <PencilSquare className="text-white font-bold" />
                    <p className="text-white text-sm font-bold">შეცვლა</p>
                </Link>
                <div
                    onClick={() => { setShowContinue(true) }}
                    className="w-12 rounded-lg bg-red-400 p-3 flex items-center justify-center cursor-pointer">
                    <BsTrash className="text-white w-full h-full" />
                </div>
            </div>
            <ContinueComponent
                showFlag={showContinue}
                actionFunction={handleDeleteAdmin}
                setShowContinue={setShowContinue}
                id={id}
            />
        </div>
    )
}


export default UserCard;