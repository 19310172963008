import Line from "../../components/InputComponents/Line";
import TransactionsCard from "./TransactionsCard";
import { useEffect, useState } from "react";
import axiosClient from "../../api/api";
import UserPagination from "../../components/UserPagination/UserPagination";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  const { t } = useTranslation();
  //shipment data
  const [transactions, setTransactions] = useState([]);
  //loader
  const [isLoading, setIsLoading] = useState(true);
  //total amount
  const [totalAmount, setTotalAmount] = useState(0);
  //currentPage
  const [currentPage, setCurrentPage] = useState(0);
  //dummy text for loader
  const dummyArray = Array.from({ length: 8 });

  //fetch faqs data
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`/transactions?page=${currentPage + 1}&per_page=${12}`)
      .then(({ data }) => {
        setTotalAmount(data.total);
        setTransactions(data.data);
        setIsLoading(false);
      });
  }, [currentPage]);

  return (
    <div>
      <div className="flex flex-col gap-2 sm:w-full sm:justify-between md:items-center">
        <Line className="bg-teal_600 h-1 w-10" />
        <h2 className="font-semibold text-2xl md:text-xl">
          {t("transaction_history")}
        </h2>
      </div>
      <UserPagination
        dataLength={totalAmount}
        itemsPerPage={12}
        pageNumberSetter={setCurrentPage}
      >
        <div
          className="shadow-bs1 rounded-[30px] my-5
             bg-white md:grid md:grid-cols-1 md:gap-5 sm:grid-cols-1 overflow-hidden"
        >
          {!isLoading && transactions?.length !== 0 && (
            <div className="p-4 grid grid-cols-4 md:hidden">
              <div>
                <p className="font-semibold text-sm afMd:text-center w-full">
                  {t("date")}
                </p>
              </div>
              <div>
                <p className="font-semibold text-sm afMd:text-center w-full">
                  {t("amount")}
                </p>
              </div>
              <div>
                <p className="font-semibold text-sm afMd:text-center w-full">
                  {t("status")}
                </p>
              </div>
              <div>
                <p className="font-semibold text-sm afMd:text-center w-full">
                  {t("bank")}
                </p>
              </div>
            </div>
          )}
          {isLoading ? (
            <div className="grid grid-cols-1">
              {dummyArray.map((_, index) => {
                return (
                  <div
                    className={`animate-pulse bg-gray-200 w-full h-16 border-t border-white ${
                      index === 0 && "border-t-0"
                    } `}
                    key={index}
                  ></div>
                );
              })}
            </div>
          ) : transactions?.length === 0 ? (
            <div className="h-[200px] flex items-center justify-center">
              <h2 className="font-bold">{t("empty")}</h2>
            </div>
          ) : (
            <div className="flex flex-col">
              {transactions.map((item, index) => {
                return (
                  <TransactionsCard key={item.id} data={item} index={index} />
                );
              })}
            </div>
          )}
        </div>
      </UserPagination>
    </div>
  );
};

export default Transactions;
