import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { PrimaryContext } from "../MainContext/MainContext";
import { useTranslation } from "react-i18next";
import terms from "../../termss.pdf";
import menuData from "./menuData";

//icons
import { BsBox } from "react-icons/bs";

const UserMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { user, dept } = useContext(PrimaryContext);
  const [data, setData] = useState(menuData);

  useEffect(() => {
    setData((prev) =>
      prev.map((item) => {
        return {
          title: item.title,
          menuItem: item.menuItem.map((item2) => {
            let active = false;
            if (item2.link === location.pathname.split("/")[2]) {
              active = true;
            } else {
              active = false;
            }
            return {
              ...item2,
              active: active,
            };
          }),
        };
      })
    );
  }, [location]);

  return (
    <div className="rounded-[30px] w-full bg-white shadow-bs1">
      <div className="relative w-full flex flex-col">
        <div className="p-3 flex gap-1 items-center w-full ">
          <div
            className="flex h-10 w-10 items-center justify-center rounded-full  bg-white_A700 border-2 
                            border-solid border-teal_600 p-[5px]"
          >
            <BsBox className="w-full h-full text-teal_600" />
          </div>
          <div className="flex flex-col items-start justify-start w-auto">
            <p className="text-sm font-semibold ">
              {user.company_id === null ? (
                <>
                  {user.fname_eng} {user.lname_eng} ID: MGL{user.id}
                </>
              ) : (
                <>
                  {user.cname_eng} ID: MGL{user.id}
                </>
              )}
            </p>
            <div className="flex gap-2 items-center">
              <p className="text-sm text-teal_600 font-semibold">
                {user.balance !== null &&
                  user.balance !== undefined &&
                  user.balance.toFixed(2)}{" "}
                GEL
              </p>
              <p className="text-sm text-red_600 font-semibold">- {dept} GEL</p>
            </div>
          </div>
        </div>

        {data.map((item, index) => {
          return (
            <div
              key={index}
              className="p-3 flex flex-col justify-start w-full border-t border-gray-200"
            >
              <h2 className="text-sm font-semibold">
                {item.title.includes("/")
                  ? t(item.title.split("/")[0]) +
                    " / " +
                    t(item.title.split("/")[1])
                  : t(item.title)}
              </h2>
              {item.menuItem.map((singleLink) => {
                return (
                  <Link key={singleLink.link} to={singleLink.link}>
                    <div
                      className={`py-2 hover:bg-teal_600 hover:px-2 flex flex-row gap-2 items-center justify-start left-[0] mt-1  
                            rounded-xl group w-full ${
                              singleLink.active && "bg-teal_600 px-2"
                            }`}
                    >
                      <singleLink.Icon
                        className={`w-4 h-4 group-hover:text-white
                                    ${
                                      singleLink.active
                                        ? "text-white"
                                        : "text-teal_600"
                                    }`}
                      />
                      <p
                        className={`text-xs group-hover:text-white font-semibold
                           ${
                             singleLink.active ? "text-white" : "text-teal_600"
                           }`}
                      >
                        {t(singleLink.title)}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          );
        })}
        <div className="p-4 border-t border-gray-200">
          <a target="_blank" href={terms}>
            <p className="text-sm font-bold underline">
              {t("terms_agreement")}
            </p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
