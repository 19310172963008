import Img from "../../components/InputComponents/Img";

//images
import tbc from "../../images/bank/tbc.png";
import bog from "../../images/bank/bog.png";

const TransactionsCard = ({ data, index }) => {
  return (
    <div
      className={`p-4 border-t border-gray-200 flex items-center ${
        index === 0 && "md:border-t-0"
      }`}
    >
      <div className="w-full flex items-center justify-center md:flex-col sm:flex-col md:gap-2 sm:gap-2">
        <div className="w-full flex gap-2.5 items-center">
          {data.type === "bog" && (
            <Img
              src={bog}
              className="h-10 w-10 sm:w-8 sm:h-8 rounded-full"
              alt="file"
            />
          )}
          {data.type === "tbc" && (
            <Img
              src={tbc}
              className="h-10 w-10 sm:w-8 sm:h-8 rounded-full"
              alt="file"
            />
          )}
          <p className="font-semibold text-sm afMd:text-center w-full">
            {data.created_at !== null && data.created_at.split("T")[0]}
          </p>
        </div>
        <div className="w-full flex items-center">
          <p className="font-semibold text-teal_600 text-sm afMd:text-center w-full">
            {data.amount} GEL
          </p>
        </div>
      </div>
      <div className="w-full flex items-center justify-center md:flex-col sm:flex-col md:gap-2 sm:gap-2">
        <div className="w-full flex items-center md:justify-end md:mt-2 ">
          <p className="font-semibold text-teal_600 text-sm afMd:text-center afMd:w-full">
            {data.status}
          </p>
        </div>
        <div className="w-full flex items-center md:justify-end">
          <p className="font-semibold text-sm afMd:text-center afMd:w-full">
            {data.type}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransactionsCard;
