import { createContext, useState, useEffect } from "react";
import axiosClient from "../../api/api";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./En";
import translationGe from "./Ge";
import axiosAdmin from "../../api/apiAdmin";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    ge: { translation: translationGe },
  },
  lng: "ge",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const PrimaryContext = createContext(null);

const MainContext = ({ children }) => {
  // disable scroll on certain moments
  const [scrollFlagOff, setScrollFlagOff] = useState(false);
  //userInfo
  const [user, setUser] = useState({});
  //update user
  const [updateUser, setUpdateUser] = useState(null);
  //token
  const [token, _setToken] = useState(localStorage.getItem("MGL_TOKEN"));
  //check if user is authorized
  const [isAuthorized, setIsAuthorized] = useState(false);
  //offices
  const [offices, setOffices] = useState([]);
  //currency
  const [currency, setCurrency] = useState([]);
  //language
  const [language, setLanguage] = useState("ge");
  //pack numbers
  const [packNumbers, setPackNumbers] = useState([]);
  //filials data
  const [filials, setFilials] = useState([]);
  //dept
  const [dept, setDept] = useState(0);

  //language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  //scrollbar hide show
  const handleScollOff = (value) => {
    setScrollFlagOff(value);
  };

  //setting token
  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("MGL_TOKEN", token);
    } else {
      localStorage.removeItem("MGL_TOKEN");
    }
  };

  //get current logged in user
  useEffect(() => {
    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
    });
  }, [token, updateUser]);

  //check if user is authoruized and save result in state
  useEffect(() => {
    if (user.email) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  //get some data on site load
  useEffect(() => {
    axiosClient.get("/offices").then(({ data }) => {
      setOffices(data);
    });
    axiosClient.get("/currency").then(({ data }) => {
      setCurrency(data);
    });
    axiosClient.get("/packNumbers?new=1").then(({ data }) => {
      setPackNumbers(Object.values(data));
    });
    axiosClient.get("/offices").then(({ data }) => {
      setFilials(
        data.map((item) => {
          return {
            id: item.id,
            title: item.name,
          };
        })
      );
    });
    axiosClient.get("/debt").then(({ data }) => {
      setDept(data.toFixed(2));
    });
  }, [user]);

  return (
    <PrimaryContext.Provider
      value={{
        scrollFlagOff,
        handleScollOff,
        user,
        setUser,
        token,
        setToken,
        setUpdateUser,
        isAuthorized,
        setIsAuthorized,
        offices,
        currency,
        language,
        setLanguage,
        packNumbers,
        filials,
        dept,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
};

export { PrimaryContext, MainContext };
